<script setup lang="ts">
import type { SlideMenuItem } from "@lxc/app-device-common";
import type {
  DtwinI,
  FleetI,
  FleetUpdatePayloadI,
} from "@lxc/app-device-types";
import type { Ref } from "vue";
import { useFleet } from "~/composables/useFleet";
import { SearchMode } from "~/composables/useSearch";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import fleetService from "~/services/fleet.service";
import { Filters } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  dtwinList?: Array<DtwinI>;
  fleetFormShown: boolean;
}>();
const emit = defineEmits([
  "update:fleetFormShown",
  "clearDtwinList",
  "reloadFleetList",
]);

const { t } = useI18n();
const {
  isLoading,
  error,
  results,
  fetchData,
  filters,
  setFilter,
  search,
  onSearch,
  sort,
} = useFleet(SearchMode.FILTER_SEARCH);

const searchQuery = ref<string>(
  (filters.get(Filters.FLEET_NAME) ?? "") as string,
);

async function loadData(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  sort.value = "-modifiedAt";
  await fetchData(page, pageSize);
}

const selectedFleet: Ref<FleetI | undefined> = ref();
const isValidateDisabled = ref(false);
const disabledValidate = (newValue: boolean) => {
  isValidateDisabled.value = newValue;
};
const isAddDevicesDisabled = computed(() => !selectedFleet.value);
const isSaving = ref(false);

const slideMenuRef = ref();
const displayForm = ref(0);
const onDiscardSideCanvas = () => {
  displayForm.value++;
};
const fleetCreateFormRef = ref();

const sidePanels: SlideMenuItem[] = [
  {
    id: "fleetCreateFormId",
    header: t("fleet.form.createFleet.header"),
    footerId: "fleetCreateFormFooterId",
    footerEnabled: true,
  },
];
const [createFleetFormPanel] = sidePanels;

const slideToFleetCreationForm = (targetId: string | undefined) => {
  if (slideMenuRef.value && targetId) {
    slideMenuRef.value.slideToPanel(targetId);
  }
};

const dtwinsUid = computed(() => {
  return props.dtwinList?.map((dtwin) => dtwin.uid);
});

const onSelectFleet = (fleet: FleetI) => {
  if (selectedFleet.value && selectedFleet.value === fleet) {
    selectedFleet.value = undefined;
  } else {
    selectedFleet.value = fleet;
  }
};

const getSelectFleetButtonClass = (fleetUid: string) => {
  const buttonClass = ["text-base", "!justify-start", "mb-2", "fleet-button"];
  if (selectedFleet.value && selectedFleet.value.uid === fleetUid) {
    buttonClass.push(
      "!text-primary",
      "!border-primary",
      "!border",
      "!bg-primary-50",
      "!rounded",
      "!box-border",
    );
  }
  return buttonClass;
};

const showFleetForm = computed({
  get: () => {
    return props.fleetFormShown;
  },
  set: (shown: boolean) => {
    emit("update:fleetFormShown", shown);
  },
});

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.FLEET_NAME, searchQuery.value);
};

const onClearSearch = () => {
  setFilter(Filters.FLEET_NAME, "");
  search();
};

async function onSubmit() {
  await fleetCreateFormRef.value.onSubmit();
}

async function onUpdate() {
  isSaving.value = true;
  if (selectedFleet.value && props.dtwinList) {
    const selectedDtwinsUids: Array<string> = props.dtwinList.map(
      (dtwins: DtwinI) => dtwins.uid,
    );
    const fleetUpdatePayload: FleetUpdatePayloadI = {
      deviceOperations: {
        add: selectedDtwinsUids,
      },
    };
    const response = await fleetService.updateFleet(
      selectedFleet.value.uid,
      fleetUpdatePayload,
    );
    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(
        t("fleet.attachDevices.updateSuccess", {
          count: selectedDtwinsUids.length,
        }),
      );
      onClose();
      emit("clearDtwinList");
    }
  }
  isSaving.value = false;
}

function onClose() {
  showFleetForm.value = false;
  selectedFleet.value = undefined;
  fleetCreateFormRef.value.onClose();
}

function onSave() {
  onClose();
  emit("clearDtwinList");
  emit("reloadFleetList", true);
}

onMounted(() => {
  onSearch(loadData);
  disabledValidate(true);
});
</script>
<template>
  <lxc-side-canvas
    v-model:show="showFleetForm"
    :header="
      dtwinList
        ? t('fleet.form.header.addDevices')
        : t('fleet.form.header.createFleet')
    "
    body-class="!py-0 !px-0"
    @hidden="onClose"
    @discard="onDiscardSideCanvas"
  >
    <lxc-slide-menu
      ref="slideMenuRef"
      v-model:display-menu="displayForm"
      :menu-items="sidePanels"
      no-default-slide-button
    >
      <template #customContent>
        <lxc-container
          v-if="dtwinList"
          :px="0"
          :py="0"
          :is-loading="isLoading"
          :error="error"
        >
          <div class="mb-[1.188rem]">
            <lxc-search-bar
              :search-placeholder="t('filters.searchByName')"
              :search-query="searchQuery"
              @clear="onClearSearch"
              @input-change="onSearchInputChanged"
              @search="search"
            />
          </div>
          <lxc-button
            :title="t('fleet.form.newFleet')"
            type="borderless"
            :icon="ILxcPlus"
            class="text-base mb-4 !justify-start text-primary"
            @click="slideToFleetCreationForm(createFleetFormPanel.htmlId)"
          >
            {{ t("fleet.form.newFleet") }}
          </lxc-button>
          <lxc-button
            v-for="fleet in results?.data"
            :key="fleet.uid"
            :title="fleet.friendlyName"
            type="borderless"
            :class="getSelectFleetButtonClass(fleet.uid)"
            @click.prevent="onSelectFleet(fleet)"
          >
            {{ fleet.friendlyName }}
          </lxc-button>
        </lxc-container>
        <lxc-fleet-create-form
          v-else
          ref="fleetCreateFormRef"
          @saved="onSave"
          @is-saving="(value: boolean) => (isSaving = value)"
          @is-validate-disabled="disabledValidate"
        />
      </template>
      <template #menu-footer>
        <lxc-fleet-form-footer
          :close-button-label="t('button.close')"
          :validate-button-label="
            dtwinList ? t('button.add') : t('fleet.form.createFleet.validate')
          "
          :disabled-validate-button="
            dtwinList ? isAddDevicesDisabled || isSaving : isValidateDisabled
          "
          :is-loading="isSaving"
          @close="onClose"
          @validate="dtwinList ? onUpdate() : onSubmit()"
        />
      </template>
      <template #[createFleetFormPanel.id]>
        <lxc-fleet-create-form
          v-if="dtwinList"
          ref="fleetCreateFormRef"
          :dtwins-uids="dtwinsUid"
          @is-saving="(value: boolean) => (isSaving = value)"
          @saved="onSave"
          @is-validate-disabled="disabledValidate"
        />
      </template>
      <template #[createFleetFormPanel.footerId]>
        <lxc-fleet-form-footer
          :close-button-label="t('button.close')"
          :validate-button-label="t(`fleet.form.createFleet.validate`)"
          :disabled-validate-button="isValidateDisabled"
          :is-loading="isSaving"
          @close="showFleetForm = false"
          @validate="onSubmit"
        />
      </template>
    </lxc-slide-menu>
  </lxc-side-canvas>
</template>
<style scoped lang="scss">
:deep(button) {
  svg {
    width: 20px;
    height: 20px;
  }
}
:deep(.fleet-button) {
  span {
    text-align: start;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }
}
:deep(.slide-menu) {
  padding-top: 1.5rem;
  :nth-child(1) {
    overflow-y: visible;
  }
}
:deep(.menu-item) {
  padding-top: 1.5rem;
  :nth-child(1) {
    :nth-child(2) {
      overflow-y: visible;
    }
  }
}
</style>
