import { storeToRefs } from "pinia";
import { useSectorStore } from "~/stores/useSectorStore";

export function useSector() {
  const { availableSectors } = storeToRefs(useSectorStore());

  function getSectorLabel(sectorCode?: string) {
    return (
      availableSectors.value?.find((sector) => sector.code === sectorCode)
        ?.label ?? ""
    );
  }

  return {
    getSectorLabel,
  };
}
