<script setup lang="ts">
import { LogEntityClass } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { computed, ref } from "vue";
import { useAcl } from "vue-simple-acl";
import type { LogEntityLinkI } from "~/types";
import { ACL_ROLES, displayableEntities, filterableEntities } from "~/types";
import ILxcCompass from "~icons/lxc/compass";
import ILxcFilter from "~icons/lxc/filter";
import ILxcTablet from "~icons/lxc/tablet";
import ILxcUser from "~icons/lxc/user";
import ILxcUsers from "~icons/lxc/users";

const { can } = useAcl();
const { t } = useI18n();
const emit = defineEmits(["apply-as-a-filter"]);

const props = defineProps<{
  element?: HTMLLinkElement | null;
}>();
const entityLink: Ref<LogEntityLinkI | undefined | null> = ref();

const labelSeeEntityProperties = computed((): string => {
  return entityLink.value?.entityClass
    ? t(`logs.filters.dropdown.properties.${entityLink.value?.entityClass}`)
    : "";
});
const labelApplyEntityAsAFilter = computed((): string => {
  return entityLink.value?.entityClass
    ? t(`logs.filters.dropdown.applyAsAFilter`)
    : "";
});

const displayProperties = () => {
  if (entityLink.value?.href) {
    document.location.href = entityLink.value?.href;
  }
};

const apply = () => {
  if (entityLink.value) {
    const { entityId, entityClass } = entityLink.value;
    emit("apply-as-a-filter", {
      entityId,
      entityClass,
    });
  }
};

const onCommand = (command: string) => {
  switch (command) {
    case "displayProperties":
      displayProperties();
      break;
    case "apply":
      apply();
      break;
  }
};

const isEntityDevice = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.DEVICE;
});

const isEntityDeviceDvtmEsoft = computed((): boolean => {
  return (
    props.element?.dataset?.entityclass === LogEntityClass.DEVICE_DVTM_ESOFT
  );
});

// For future use
const isEntityGateway = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.GATEWAY;
});

const isEntityGroup = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.GROUP;
});

const isEntityProfile = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.PROFILE;
});

// For future use
const isEntityPlatform = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.PLATFORM;
});

const isEntitySector = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.SECTOR;
});

const isEntityUser = computed((): boolean => {
  return props.element?.dataset?.entityclass === LogEntityClass.USER;
});

const displayPropertiesEnabled = computed((): boolean => {
  let enabled = false;

  // If the action is delete and the link is an asset, hide the link to the user properties because it will display a 404 error
  if (
    props.element?.dataset?.property === "assetId" &&
    props.element?.dataset?.action === "delete"
  ) {
    return enabled;
  }

  switch (props.element?.dataset?.entityclass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      enabled = can(ACL_ROLES.DISPLAY_DEVICES);
      break;
    case LogEntityClass.USER:
    case LogEntityClass.GROUP:
    case LogEntityClass.PROFILE:
      enabled = can(ACL_ROLES.DISPLAY_USERS);
      break;
    case LogEntityClass.SECTOR:
      enabled = can(ACL_ROLES.DISPLAY_SECTORS);
      break;
    default:
      enabled = props.element?.dataset?.entityclass
        ? (displayableEntities[props.element.dataset.entityclass] ?? false)
        : false;
      break;
  }

  return enabled;
});

const filterPropertiesEnabled = computed((): boolean => {
  return props.element?.dataset?.entityclass
    ? (filterableEntities[props.element.dataset.entityclass] ?? false)
    : false;
});

watch(
  () => props.element,
  (element?: HTMLLinkElement | null) => {
    if (element?.dataset?.entityclass && element?.dataset?.id) {
      entityLink.value = {
        entityClass: element.dataset.entityclass as LogEntityClass,
        href: element.href,
        entityId: element.dataset.id,
        property: element.dataset.property,
      };
    } else {
      entityLink.value = null;
    }
  },
);
</script>

<template>
  <lxc-dropdown-menu offset="8" :element="props.element" @command="onCommand">
    <template #default>
      <lxc-dropdown-item
        v-if="displayPropertiesEnabled"
        command="displayProperties"
      >
        <template v-if="isEntityDevice || isEntityDeviceDvtmEsoft">
          <span class="w-2 filter-icon"><ILxcTablet /></span>
        </template>
        <template v-else-if="isEntityGroup">
          <span class="w-2 filter-icon"><ILxcUsers /></span>
        </template>
        <template v-else-if="isEntityProfile">
          <span class="w-2 filter-icon"><ILxcUser /></span>
        </template>
        <template v-else-if="isEntitySector">
          <span class="w-2 filter-icon"><ILxcCompass /></span>
        </template>
        <template v-else-if="isEntityUser">
          <span class="w-2 filter-icon"><ILxcUser /></span>
        </template>
        {{ labelSeeEntityProperties }}
      </lxc-dropdown-item>
      <lxc-dropdown-item v-if="filterPropertiesEnabled" command="apply">
        <span class="w-2 filter-icon"><ILxcFilter /></span
        >{{ labelApplyEntityAsAFilter }}
      </lxc-dropdown-item>
    </template>
  </lxc-dropdown-menu>
</template>
<style scoped lang="scss">
.filter-icon {
  :deep(svg) {
    display: inline;
    vertical-align: baseline;
  }
}
</style>
