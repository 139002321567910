import { useFetchPage } from "~/composables/useFetchPage";
import { type SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import DtwinsService from "~/services/dtwins.service";
import {
  Filters,
  FiltersSelection,
  FiltersType,
  OPERATOR_RSQL_EQUAL,
} from "~/types";

export function useDtwinModels(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = DtwinsService.getModels.bind(DtwinsService);

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: OPERATOR_RSQL_EQUAL, value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  // TODO: Refactor needed to avoid using 999999999 for pageSize
  async function fetchAllModels() {
    await fetchData(1, 999999999);
  }

  return {
    filters,
    setFilter,
    search,
    onSearch,
    results,
    isLoading,
    error,
    fetchAllModels,
    onSortChange,
  };
}
