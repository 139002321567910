<script lang="ts" setup>
import type { SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import { isFeatureEnabled } from "~/composables/useFeature";
import { PATHS } from "~/constants/paths";
import SectorsService from "~/services/sectors.service";
import type { TabItem } from "~/types";
import { ACL_ROLES } from "~/types";
import { APP_FEATURES } from "~/types/appFeatures";
import LxcError from "~/utils/LxcError";

const props = defineProps<{
  code: string;
}>();
const route = useRoute();
const router = useRouter();
const sectorTabItems: TabItem[] = [
  {
    label: "sectors.details.tab.device.label",
    to: PATHS.DEVICES_DVTM_ESOFT_SUBPATH,
    can: ACL_ROLES.DISPLAY_DEVICES,
  },
];

if (isFeatureEnabled(APP_FEATURES.DTWINS)) {
  sectorTabItems.push({
    label: "sectors.details.tab.dtwins.label",
    to: PATHS.DTWINS_SUBPATH,
    can: ACL_ROLES.NO_CONTROL,
  });
}

sectorTabItems.push(
  {
    label: "sectors.details.tab.application.label",
    to: PATHS.APPLICATIONS_SUBPATH,
    can: ACL_ROLES.DISPLAY_APPLICATIONS,
  },
  {
    label: "sectors.details.tab.user.label",
    to: PATHS.USERS_SUBPATH,
    can: ACL_ROLES.DISPLAY_USERS,
  },
  {
    label: "sectors.details.tab.userGroup.label",
    to: PATHS.GROUPS_SUBPATH,
    can: ACL_ROLES.DISPLAY_USERS,
  },
);

const activePageIndex = computed(() =>
  sectorTabItems.indexOf(
    sectorTabItems.find((i) => i.to === route.path.split("/").at(-1))!,
  ),
);
const isLoading = ref(false);
const sector: Ref<SectorI | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);

onMounted(fetchSector);

async function fetchSector() {
  isLoading.value = true;
  const response = await SectorsService.getSectorByCode(props.code);

  if (LxcError.check(response)) {
    error.value = response;
  } else {
    sector.value = response;
    error.value = null;
  }

  isLoading.value = false;
}

async function navigateToSectorList() {
  await router.push(PATHS.SECTORS);
}
</script>

<template>
  <lxc-container v-if="sector" :px="0">
    <lxc-breadcrumb
      :name="sector.label"
      back
      :back-callback="navigateToSectorList"
      should-not-translate
      class="px-8 mb-8"
    />
    <div
      v-if="sector?.description"
      class="sector-description-container px-8 mb-8"
    >
      <p>{{ sector?.description }}</p>
    </div>

    <lxc-tab-bar
      :tabs-items="sectorTabItems"
      :active-page-index="activePageIndex"
      :sector="sector"
    />
  </lxc-container>
</template>

<style lang="scss" scoped>
.sector-description-container {
  p {
    font-size: 14px;
    margin: 0;
  }
}
</style>
