<script setup lang="ts">
defineProps<{
  isVisible: boolean;
}>();
defineEmits(["close"]);

const { t } = useI18n();
</script>

<template>
  <lxc-common-modal
    :show="isVisible"
    header-class="!justify-normal !px-0 sm:!pr-8 !px-4 sm:!pl-8"
    body-class="bg-white !py-0 sm:!pr-8 !pb-5"
    footer-class="mb-2 flex-row-reverse gap-2"
    borderless-close-button
    @close="$emit('close')"
  >
    <template #header>
      <h4 class="pt-5">
        <span> {{ t("sectors.modals.remove.titleAlt") }}</span>
      </h4>
    </template>
    <template #body>
      <span> {{ t("sectors.modals.remove.notAllowed") }}</span>
    </template>
    <template #footer>
      <lxc-button :title="t('button.close')" @click="$emit('close')">
        {{ t("button.close") }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>
