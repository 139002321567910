<script setup lang="ts">
const emit = defineEmits(["select", "update:modelValue"]);
const props = defineProps<{
  itemProp: string;
  data?: Array<any> | null | undefined;
  disabled?: boolean;
  disabledProp?: string;
  emptyText?: string;
  header?: string;
  headerClass?: string | Array<string>;
  isLoading?: boolean;
  itemClass?: string | Array<string>;
  modelValue?: Array<string>;
  prop: string;
  useRadioButtons?: boolean;
}>();

const selectedItems = computed({
  get() {
    return props.modelValue ?? [];
  },
  /* In the case of radio buttons, the returned value is not an array but a
   * string as only one element can be chosen from the list. We manipulate
   * arrays when working with eSoft declination (multiple selections
   * possible).
   */
  set(selected?: Array<string> | string) {
    if (typeof selected === "string") {
      if (selected["uid"] !== undefined) {
        emit("update:modelValue", [selected]);
        emit("select", [selected]);
      } else {
        const models =
          props.data !== null && props.data !== undefined
            ? toRaw(props.data)?.filter((item) => item.value === selected)
            : [];
        emit("update:modelValue", models);
        emit("select", models);
      }
    } else {
      emit("update:modelValue", selected);
      emit("select", selected);
    }
  },
});
</script>
<template>
  <LxcSelectableList
    v-model="selectedItems"
    :item-prop="itemProp"
    :data="data"
    :empty-text="emptyText"
    :header="header"
    header-class="underline"
    :is-loading="isLoading"
    :item-class="itemClass"
    :prop="prop"
    :disabled="disabled"
    :disabled-prop="disabledProp"
    :type="useRadioButtons ? 'radio' : 'checkbox'"
  />
</template>
