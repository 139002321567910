<script setup lang="ts">
import { CampaignOMStatus, CampaignOperationMgrI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import CampaignOperationMgrService from "~/services/campaignOperationMgr.service";
import LxcError from "~/utils/LxcError";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const route = useRoute();
const uid = route.params.uid.toString();
const campaign: Ref<CampaignOperationMgrI | undefined> = ref();
const error: Ref<LxcError | undefined> = ref();
const isLoading: Ref<boolean> = ref(false);

async function fetchCampaign() {
  isLoading.value = true;
  const response = await CampaignOperationMgrService.find(uid);
  if (LxcError.check(response)) {
    error.value = response;
  } else {
    campaign.value = response;
  }
  isLoading.value = false;
}

const canUpdateOrCancelCampaign = computed(
  () =>
    campaign.value?.status === CampaignOMStatus.SCHEDULED ||
    campaign.value?.status === CampaignOMStatus.RUNNING,
);

const showCampaignOperationMgrFunnel: Ref<boolean> = ref(false);

onMounted(fetchCampaign);
</script>

<template>
  <lxc-container :is-loading="isLoading" :error="error">
    <div class="flex justify-between mr-14">
      <lxc-breadcrumb :name="campaign?.name" back />
      <div class="flex gap-4 self-center">
        <lxc-button
          v-if="canUpdateOrCancelCampaign"
          :icon="ILxcPlus"
          size="large"
          type="primary"
          :title="t('campaign.details.buttons.edit.label')"
          :disabled="isLoading"
          @click="showCampaignOperationMgrFunnel = true"
        >
          {{ t("campaign.details.buttons.edit.label") }}
        </lxc-button>
      </div>
    </div>
    <div class="mt-8 flex gap-[1.875rem]">
      <lxc-campaign-operation-mgr-characteristics
        :campaign="campaign"
        class="flex-1"
      />
      <lxc-campaign-operation-mgr-statistics
        :campaign="campaign"
        class="flex-1"
      />
    </div>
    <div class="mt-12">
      <lxc-campaign-operation-mgr-operation-list :campaign="campaign" />
    </div>

    <lxc-campaign-operation-mgr-funnel
      v-model:show="showCampaignOperationMgrFunnel"
      :campaign="campaign"
    />
  </lxc-container>
</template>
