<script setup lang="ts">
import type { UserDataI } from "@lxc/app-device-types";
import ILxcHelpCircle from "~icons/lxc/help-circle";

const { t, availableLocales, locale } = useI18n();
const props = defineProps<{
  user: UserDataI | null;
  isModeAD: boolean;
}>();

const emit = defineEmits(["saveLanguage"]);

const language = ref(undefined);
const isAllowedToEdit = computed(
  () => !props.isModeAD && !props.user?.isProtected,
);

const onClick = () => {
  emit("saveLanguage", {
    language,
  });
};
</script>

<template>
  <lxc-container :px="0" :py="0">
    <div class="flex items-center gap-2">
      <h2>{{ t("user.userAccount.language.title") }}</h2>
      <div
        v-if="isModeAD"
        class="flex-shrink-0"
        style="margin-bottom: 1rem; margin-top: 0.75rem"
        :title="t('user.userAccount.language.activeDirectoryWarning')"
      >
        <ILxcHelpCircle />
      </div>
    </div>

    <span>
      {{ t("user.userAccount.instructions.language") }}
    </span>

    <lxc-form ref="userLanguageForm">
      <lxc-form-item class="max-w-64 pt-2">
        <lxc-select
          v-model="language"
          :placeholder="t('input.languages.' + locale)"
          :disabled="!isAllowedToEdit"
        >
          <lxc-option
            v-for="item in availableLocales"
            :key="item"
            :label="t('input.languages.' + item)"
            :value="item"
          />
        </lxc-select>
      </lxc-form-item>
      <lxc-form-item>
        <lxc-button
          type="primary"
          html-type="button"
          :title="t('user.userAccount.forms.validate')"
          :disabled="!isAllowedToEdit || !language || language === locale"
          @click="onClick"
        >
          {{ t("user.userAccount.forms.validate") }}
        </lxc-button>
      </lxc-form-item>
    </lxc-form>
  </lxc-container>
</template>
