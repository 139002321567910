<script setup lang="ts">
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { DtwinI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { ComputedRef, FunctionalComponent, Ref, SVGAttributes } from "vue";
import { useAcl } from "vue-simple-acl";
import { getConnectivityStatusParameters } from "~/components/dtwins/dtwinsList/connectivity/LxcDtwinsListConnectivityStatus.helper";
import {
  displayColumns,
  dtwinsListDisplayContext,
} from "~/components/dtwins/dtwinsList/dtwinsList.type";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwin, useDtwins } from "~/composables/useDtwins";
import type { SearchMode } from "~/composables/useSearch";
import { useSector } from "~/composables/useSector";
import { PATHS } from "~/constants/paths";
import { useSectorStore } from "~/stores/useSectorStore";
import { ACL_ROLES, Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";
import ILxcFolderPlus from "~icons/lxc/folder-plus";
import ILxcTrash2 from "~icons/lxc/trash-2";
import ILxcClose from "~icons/lxc/x";

const props = defineProps<{
  reload?: boolean;
  tableOnly?: boolean;
  fleetUid?: string;
  columns?: Array<displayColumns>;
  defaultFilters?: Map<Filters, any>;
  searchMode?: SearchMode;
  noQuickActionMenu?: boolean;
  noQuickFilters?: boolean;
  context?: dtwinsListDisplayContext;
  isRemovingFromFleet?: boolean;
  notInFleetUid?: string;
  selectedDtwins?: Array<DtwinI>;
  useQueryParametersForPagination?: boolean;
}>();

const emit = defineEmits([
  "update:reload",
  "update:selected-dtwins",
  "removeFromFleet",
]);

const {
  isLoading,
  results,
  error,
  setFilter,
  fetchData,
  onSearch,
  search,
  filters,
} = useDtwins(props.searchMode, props.useQueryParametersForPagination);

const {
  isLoading: isLoadingModels,
  error: errorModels,
  results: models,
  fetchAllModels,
} = useDtwinModels();

const acl = useAcl();
const { t } = useI18n();
const router = useRouter();
const { getSectorLabel } = useSector();
const { getDtwinTypeLabel } = useDtwin();
const { selectedSectors, availableSectors } = storeToRefs(useSectorStore());

const isAllowedToManageFleet = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM));

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

interface QuickActionButtonConfigI {
  icon: FunctionalComponent<SVGAttributes>;
  title: string;
  event: () => void;
}

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => executeSearch(true));

watch(
  () => props.reload,
  (reload) => {
    if (reload) {
      reloadData();
      emit("update:reload", false);
    }
  },
);

const tableRef = ref<InstanceType<typeof LxcTable>>();

const searchQuery = ref<string>(
  (filters.get(Filters.DTWIN_NAME_OR_SERIAL_NUMBER) ?? "") as string,
);

// This computed values contains the page loading state to avoid the display of
// multiple spinners.
const isLoadingPage = computed(() => {
  return (
    (isLoadingModels.value && isLoading.value) ||
    (results.value === null && errorModels.value === undefined)
  );
});

async function reloadData(
  page?: number,
  pageSize?: number,
  params?: Map<string, any>,
) {
  await fetchData(page, pageSize, params);
  setSelection();
}

/**
 * Select Dtwins
 */
const setSelection = () => {
  if (props.selectedDtwins && tableRef.value && tableRef.value.data) {
    tableRef.value.toggleTableSelectionMode(props.selectedDtwins.length > 0);
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedDtwins?.some(
          (selectedDevice) => selectedDevice.uid === row.uid,
        ),
      );
    }
  }
};

watch(() => props.selectedDtwins, setSelection);

const fleetFormShown: Ref<boolean> = ref(false);

const onUpdateFleetFormShown = (show: boolean) => {
  fleetFormShown.value = show;
};

async function executeSearch(replaceHistory?: boolean) {
  setFilter(
    Filters.DTWIN_IN_SECTORS,
    (selectedSectors.value.length > 0
      ? selectedSectors
      : availableSectors
    ).value.map((sector) => sector.code), // Filter by the available sectors if no sectors are selected
  );

  if (props.fleetUid) {
    setFilter(Filters.DTWINS_IN_FLEET, props.fleetUid);
  }
  if (props.notInFleetUid) {
    setFilter(Filters.DTWINS_NOT_IN_FLEET, props.notInFleetUid);
  }

  // call search in order to update the query parameters with filters
  search(replaceHistory);
}

const selectedDeviceModelFilter = computed(() => {
  return filters.get(Filters.DTWIN_MODEL_TYPE);
});

const getQuickFilterButtonType = (selectedModelTypeUid: string) => {
  return selectedDeviceModelFilter?.value?.value.includes(selectedModelTypeUid)
    ? "primary"
    : "secondary";
};

const quickFiltersWrapperClass = computed(() => {
  const wrapperClass = ["flex", "gap-2"];

  if (
    props.selectedDtwins &&
    props.selectedDtwins.length > 0 &&
    isAllowedToManageFleet.value
  ) {
    wrapperClass.push("mb-2");
  } else {
    wrapperClass.push("mb-6");
  }

  return wrapperClass;
});

async function getDevicesByModel(modelUid: string) {
  selectedDeviceModelFilter?.value?.value.includes(modelUid)
    ? setFilter(Filters.DTWIN_MODEL_TYPE, [])
    : setFilter(Filters.DTWIN_MODEL_TYPE, [modelUid]);
  await executeSearch();
}

const quickActionButtonConfig: ComputedRef<QuickActionButtonConfigI> = computed(
  () => {
    let quickActionButtonConfig: QuickActionButtonConfigI;
    switch (props.context) {
      case dtwinsListDisplayContext.FLEET:
        quickActionButtonConfig = {
          icon: ILxcTrash2,
          title: t("dtwins.fleet.removeFromFleet"),
          event: () => emit("removeFromFleet", props.selectedDtwins),
        };
        break;
      case dtwinsListDisplayContext.DTWINS:
      default:
        quickActionButtonConfig = {
          icon: ILxcFolderPlus,
          title: t("dtwins.fleet.addToFleet"),
          event: () => (fleetFormShown.value = true),
        };
        break;
    }
    return quickActionButtonConfig;
  },
);

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.DTWIN_NAME_OR_SERIAL_NUMBER, searchQuery.value);
};

const onClearSearch = () => {
  setFilter(Filters.DTWIN_NAME_OR_SERIAL_NUMBER, "");
  search();
};

const handleSelect = (selectedDevices: DtwinI[]) => {
  emit(
    "update:selected-dtwins",
    props.selectedDtwins
      ?.filter(
        (selectedDevice: DtwinI) =>
          !tableRef.value ||
          !tableRef.value.data?.find(
            (row: DtwinI) => row.uid === selectedDevice.uid,
          ),
      )
      .filter(
        (selectedDevice: DtwinI) =>
          !selectedDevices.find((row) => row.uid === selectedDevice.uid),
      )
      .concat(selectedDevices) ?? selectedDevices,
  );
};

const onCancelSelection = () => {
  emit("update:selected-dtwins", []);
  if (tableRef.value) {
    tableRef.value.selectedRows = [];
  }
};

onMounted(async () => {
  await fetchAllModels();
  onSearch(reloadData, false);
  await executeSearch(true);
});

defineExpose({
  onCancelSelection,
});
</script>

<template>
  <div v-if="!tableOnly" class="mb-6 mt-3.5">
    <lxc-search-bar
      :search-query="searchQuery"
      :search-placeholder="t('dtwins.filters.searchByNameOrSerialNumber')"
      @clear="onClearSearch"
      @input-change="onSearchInputChanged"
      @search="search"
    />
  </div>
  <lxc-container
    :error="errorModels"
    :is-loading="isLoadingPage"
    :px="0"
    :py="0"
    class="mb-4"
  >
    <div v-if="!tableOnly">
      <div v-if="!noQuickFilters" :class="quickFiltersWrapperClass">
        <lxc-button
          v-for="model in models?.results"
          :key="model.uid"
          :title="model.friendlyName"
          :type="getQuickFilterButtonType(model.uid)"
          @click="() => getDevicesByModel(model.uid)"
        >
          {{ model.friendlyName }}
        </lxc-button>
      </div>
    </div>
    <div
      v-if="
        selectedDtwins &&
        selectedDtwins.length > 0 &&
        !noQuickActionMenu &&
        isAllowedToManageFleet
      "
      class="mb-2 py-2 bg-gray-100 rounded text-gray-700 flex items-center"
    >
      <lxc-button
        :icon="ILxcClose"
        class="!px-0 !py-0 ml-2 mr-4"
        type="borderless"
        :title="t('dtwins.fleet.cancelSelection')"
        @click="onCancelSelection"
      />
      <span class="text-base">
        {{ t("dtwins.fleet.selectedDevices", selectedDtwins.length) }}
      </span>
      <lxc-button
        :icon="quickActionButtonConfig.icon"
        type="borderless"
        :title="quickActionButtonConfig.title"
        class="!px-0 !py-0 ml-8"
        @click="quickActionButtonConfig.event"
      />
    </div>
    <lxc-table
      ref="tableRef"
      :context="results?.context"
      :data="results?.data"
      :is-loading="isLoading"
      :error="error?.toError()"
      :empty-text="t('dtwins.empty')"
      clickable
      @change-page-and-page-size="reloadData"
      @row-click="router.push(`${PATHS.DTWINS}/${$event.uid}`)"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <lxc-table-column v-if="selectedDtwins" type="selection" />
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.FRIENDLY_NAME)"
        prop="attributes.friendlyName"
        :label="t('dtwins.list.attributes.friendlyName')"
        max-characters="25"
      />
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.TYPE)"
        prop="attributes.type"
        :label="t('dtwins.list.attributes.type')"
      >
        <template v-if="models" #default="scope">
          {{ getDtwinTypeLabel(models.results, scope.row) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.SERIAL_NUMBER)"
        prop="attributes.serialNumber"
        :label="t('dtwins.list.attributes.serialNumber')"
      />
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.SECTOR)"
        prop="attributes.sectorId"
        :label="t('dtwins.list.attributes.sector')"
      >
        <template #default="scope">
          {{ getSectorLabel(scope.row.attributes.sectorId) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.CONNECTIVITY)"
        :label="t('dtwins.list.attributes.connectivity.name')"
        class="!py-0"
      >
        <template #default="scope">
          <LxcDtwinsListConnectivityStatus
            v-if="scope.row.connectivityStatus"
            :connectivity-status="scope.row.connectivityStatus"
            :connectivity-status-parameters="
              getConnectivityStatusParameters(
                scope.row.deviceModelUid,
                models?.results,
              )
            "
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.METASTATUS)"
        :label="t('dtwins.list.attributes.metastatus.name')"
        class="!py-0"
      >
        <template #default="scope">
          <LxcDtwinsListMetadataStatus
            v-if="scope.row.operationStatuses"
            :operation-statuses="scope.row.operationStatuses"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.STATE)"
        :label="t('dtwins.list.attributes.state')"
      >
        <template #default="scope">
          {{ t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(displayColumns.CREATED_AT)"
        :label="t('dtwins.list.attributes.createdAt')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.createdAt, t("dateFormat.datetime")) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="columns && columns.includes(displayColumns.ACTION)"
        :width="50"
        class="!py-0"
      >
        <template #default="scope">
          <lxc-button
            html-type="button"
            type="borderless"
            :disabled="isRemovingFromFleet || !isAllowedToManageFleet"
            :icon="!isRemovingFromFleet ? ILxcLightTrash2 : undefined"
            :title="
              isAllowedToManageFleet
                ? t('dtwins.fleet.removeFromFleet')
                : t('fleet.actionForbidden')
            "
            class="invisible"
            @click.stop.prevent="$emit('removeFromFleet', [scope.row])"
          >
            <lxc-loader v-if="isRemovingFromFleet" />
          </lxc-button>
        </template>
      </lxc-table-column>
    </lxc-table>
  </lxc-container>
  <lxc-fleet-form
    :fleet-form-shown="fleetFormShown"
    :dtwin-list="selectedDtwins"
    @update:fleet-form-shown="onUpdateFleetFormShown"
    @clear-dtwin-list="onCancelSelection"
  />
</template>
<style lang="scss" scoped>
:deep(button) {
  span {
    margin-left: 0 !important;
  }
}

:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
