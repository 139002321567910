<script setup lang="ts">
import type { NewUserDataI, UserDataI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { PATHS } from "~/constants/paths";
import usersService from "~/services/users.service";
import { useConfigStore } from "~/stores/useConfigStore";
import type { UserIdentityI } from "~/types";
import LxcError from "~/utils/LxcError";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { isModeAD } = storeToRefs(useConfigStore());

const error: Ref<LxcError | null> = ref(null);
const isLoading = ref(false);
const routeUserId = route?.params?.id as string;
const isCreate = routeUserId === "new";
const userId = ref(isCreate ? null : routeUserId);

const user: Ref<UserDataI | NewUserDataI | null> = ref(null);
const step = ref(0);
const name = computed(() =>
  user?.value?.firstName
    ? `${user?.value?.firstName} ${user?.value?.lastName}`
    : "user.button.create",
);

onMounted(async () => {
  user.value = null;
  error.value = null;

  if (userId.value) {
    isLoading.value = true;
    const response = await usersService.getUserById(userId.value);

    if (LxcError.check(response)) {
      error.value = response;
    } else {
      user.value = response;
    }

    isLoading.value = false;
  }
});

function onSaveUserIdentity(user: UserIdentityI) {
  userId.value = user.id!;
  step.value = 1;
}

function onSaveUserGroups() {
  router.push(PATHS.USER_MANAGEMENT_USERS);
}
</script>

<template>
  <el-container
    data-cy="page-user-component"
    direction="vertical"
    class="container"
  >
    <lxc-breadcrumb
      :name="isLoading ? '' : name"
      class="px-8 mt-8"
      back
      :should-not-translate="!isCreate"
    />
    <lxc-container
      class="half-container mb-4"
      :is-loading="isLoading"
      :error="error"
    >
      <lxc-mandatory />

      <el-steps direction="vertical" :active="step" finish-status="success">
        <el-container direction="horizontal">
          <el-step :title="t('user.form.userIdentity.title')" />
          <lxc-user-identity-form
            :disabled="step !== 0 || isModeAD"
            :user="user"
            @submit="onSaveUserIdentity"
          />
        </el-container>

        <el-container direction="horizontal">
          <el-step :title="t('user.form.userProfile.title')" />

          <lxc-user-profile-form
            :disabled="step !== 1 || isModeAD"
            :user-id="userId"
            :user="user"
            @previous="step = 0"
            @submit="step = 2"
          />
        </el-container>

        <el-container direction="horizontal">
          <el-step :title="t('user.form.userGroups.title')" />
          <lxc-user-groups-form
            :disabled="step !== 2 || isModeAD"
            :user-id="userId"
            :user="user"
            @previous="step = 1"
            @submit="onSaveUserGroups"
          />
        </el-container>
      </el-steps>

      <el-row justify="center">
        <el-button
          plain
          class="lxc-plain"
          data-cy="back-button"
          @click="router.back()"
        >
          {{ t("button.cancel") }}
        </el-button>
      </el-row>
    </lxc-container>
  </el-container>
</template>

<style lang="scss" scoped>
:deep(.el-steps) {
  .el-container .el-form {
    flex: 1;
    margin: 40px 0 0;
  }

  .el-step {
    flex-basis: auto !important;
    width: 50px;
  }

  .el-step:last-of-type .el-step__line {
    display: block;
  }

  .el-container:last-of-type .el-step .el-step__line {
    display: none;
  }
}
</style>
