<script setup lang="ts">
export interface LxcDtwinsDescriptionStateI {
  lifeCycleState: string;
  firstConnection: string;
  lastConnection: string;
  batteryStatus: number | undefined;
  lastChange: string;
  configurationApplicationDate: string;
  lastRebootDate: string;
  firstCommissioningDate: string;
  certificateExpirationDate: string;
  lastCertificateUpdate: string;
  firmwareVersion: string;
}

interface Props {
  modelValue: LxcDtwinsDescriptionStateI;
}

defineProps<Props>();

const { t } = useI18n();

/*
TODO: uncomment this section when the battery attribute will be available
const batteryStatus: Ref<string> = ref(
  props.modelValue.batteryStatus?.toString() ?? NO_CONTENT,
);

const batteryStatusColorSuffix = computed(() => {
  if (props.modelValue.batteryStatus) {
    if (props.modelValue.batteryStatus <= 5) {
      return "error-700";
    } else if (props.modelValue.batteryStatus <= 25) {
      return "warning-600";
    } else {
      return "primary-600";
    }
  }
});*/
</script>

<template>
  <LxcDescriptionContainer :title="t(`dtwins.form.description.state.title`)">
    <template #left>
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.state')"
      >
        <lxc-input :value="modelValue.lifeCycleState" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.firstConnection')"
      >
        <lxc-input :value="modelValue.firstConnection" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.lastConnection')"
      >
        <lxc-input :value="modelValue.lastConnection" type="text" read-only />
      </lxc-form-item>
      <!-- TODO: uncomment this section when the battery attribute will be available
      <lxc-form-item
        v-if="batteryStatus !== NO_CONTENT"
        read-only
        display-label
        :label="t('dtwins.form.description.state.attributes.batteryStatus')"
      >
        <div class="flex">
          <div class="flex w-1/2 items-center">
            <div class="rounded bg-gray-200 w-full h-2">
              <div
                :class="`rounded h-2 bg-${batteryStatusColorSuffix}`"
                :style="{ width: `${modelValue.batteryStatus}%` }"
              />
            </div>
          </div>
          <div :class="`items-center ml-3 text-${batteryStatusColorSuffix}`">
            {{ `${modelValue.batteryStatus}%` }}
          </div>
        </div>
      </lxc-form-item>
      <lxc-form-item
       v-else
       :label="t('dtwins.form.description.state.attributes.batteryStatus')"
      >
       <lxc-input
         :value="batteryStatus"
         type="text"
         read-only
       />
      </lxc-form-item>
      -->
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.lastChange')"
      >
        <lxc-input :value="modelValue.lastChange" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="
          t(
            'dtwins.form.description.state.attributes.configurationApplicationDate',
          )
        "
      >
        <lxc-input
          :value="modelValue.configurationApplicationDate"
          type="text"
          read-only
        />
      </lxc-form-item>
    </template>
    <template #right>
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.lastRebootDate')"
      >
        <lxc-input :value="modelValue.lastRebootDate" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="
          t('dtwins.form.description.state.attributes.firstCommissioningDate')
        "
      >
        <lxc-input
          :value="modelValue.firstCommissioningDate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="
          t(
            'dtwins.form.description.state.attributes.certificateExpirationDate',
          )
        "
      >
        <lxc-input
          :value="modelValue.certificateExpirationDate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="
          t('dtwins.form.description.state.attributes.lastCertificateUpdate')
        "
      >
        <lxc-input
          :value="modelValue.lastCertificateUpdate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.state.attributes.firmwareVersion')"
      >
        <lxc-input :value="modelValue.firmwareVersion" type="text" read-only />
      </lxc-form-item>
    </template>
  </LxcDescriptionContainer>
</template>
