import { useFetchPage } from "~/composables/useFetchPage";
import { SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import CampaignOperationMgrService from "~/services/campaignOperationMgr.service";
import {
  Filters,
  FiltersSelection,
  FiltersType,
  OPERATOR_RSQL_ILIKE,
} from "~/types";
import filtersUtils from "~/utils/filters.utils";

export function useCampaignOperationMgr() {
  const service = CampaignOperationMgrService.findWithPagination.bind(
    CampaignOperationMgrService,
  );

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.NAME,
      {
        key: "name",
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    SearchMode.URL_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: true,
  });

  return {
    isLoading,
    error,
    results,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
    sort,
  };
}
