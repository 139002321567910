<script setup lang="ts">
defineProps({
  size: {
    type: String,
    default: "medium",
  },
});

const { t } = useI18n();
</script>

<template>
  <div :class="['lxc-mandatory', size]">
    {{ t("message.mandatoryFields.part1") }}
    <span class="requiredStar">*</span>
    {{ t("message.mandatoryFields.part2") }}
  </div>
</template>

<style scoped lang="scss">
.lxc-mandatory {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: $dark-grey-color;

  &.small {
    margin-top: 0;
    font-size: 12px;
    color: $description-text-color;
  }
}
</style>
