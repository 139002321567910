<script setup lang="ts">
import { InterfaceStatus } from "@lxc/app-device-types";
import type { AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import type { FunctionalComponent, SVGAttributes } from "vue";
import { useLicenseStatus } from "~/composables/useLicenseStatus";
import applicationMgtService from "~/services/applicationMgt.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightRotateCw from "~icons/lxc-light/rotate-cw";
import ILxcLightSlash from "~icons/lxc-light/slash";
import ILxcAlertCircle from "~icons/lxc/alert-circle";

const props = defineProps<{
  interfaceClientId: string;
  interfaceName: string;
  interfaceStatus: InterfaceStatus;
  canManageThirdPartyApp: boolean;
  disableActivateInterfaceButton: boolean;
}>();
const emits = defineEmits(["change"]);

const { t } = useI18n();
const { getLicenseStatus } = useLicenseStatus();

const isActionLoading: Ref<boolean> = ref(false);
const showConfirm: Ref<boolean> = ref(false);

interface ButtonInfo {
  icon: FunctionalComponent<SVGAttributes>;
  title: string;
}

interface ConfirmInfo {
  title: string;
  message: string;
}

const computedDisableActivateInterfaceButton: ComputedRef<boolean> = computed(
  () => {
    return props.interfaceStatus === InterfaceStatus.INACTIVE
      ? props.disableActivateInterfaceButton
      : false;
  },
);

const buttonInfo: ComputedRef<ButtonInfo> = computed<ButtonInfo>(() => {
  return props.interfaceStatus === InterfaceStatus.ACTIVE
    ? {
        icon: ILxcLightSlash,
        title: t("applicationMgt.action.deactivate.confirm.title"),
      }
    : {
        icon: ILxcLightRotateCw,
        title: computedDisableActivateInterfaceButton.value
          ? t(
              "applicationMgt.tabs.thirdPartyApp.licenseMessage.disableActivateInterface",
            )
          : t("applicationMgt.action.activate.confirm.title"),
      };
});

const confirmInfo: ComputedRef<ConfirmInfo> = computed<ConfirmInfo>(() => {
  return props.interfaceStatus === InterfaceStatus.ACTIVE
    ? {
        title: t("applicationMgt.action.deactivate.confirm.title"),
        message: t("applicationMgt.action.deactivate.confirm.message", {
          name: props.interfaceName,
        }),
      }
    : {
        title: t("applicationMgt.action.activate.confirm.title"),
        message: t("applicationMgt.action.activate.confirm.message", {
          name: props.interfaceName,
        }),
      };
});

const onActionConfirm = async () => {
  isActionLoading.value = true;
  showConfirm.value = false;
  const response: Ref<AxiosResponse<any, any> | undefined> = ref();

  if (props.interfaceStatus === InterfaceStatus.ACTIVE) {
    response.value = await applicationMgtService.deactivateAppInterface(
      props.interfaceClientId,
    );
  } else if (props.interfaceStatus === InterfaceStatus.INACTIVE) {
    response.value = await applicationMgtService.activateAppInterface(
      props.interfaceClientId,
    );
  }

  if (LxcError.check(response.value)) {
    if (response.value.status === StatusCodes.PAYMENT_REQUIRED) {
      showNotificationError(
        t(NotificationKey.saveError),
        t(
          "applicationMgt.tabs.thirdPartyApp.licenseMessage.disableNewInterface",
        ),
      );
    } else {
      response.value.notify(NotificationKey.error);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.success));
    await getLicenseStatus();
    emits("change");
  }

  isActionLoading.value = false;
};

const onActionClick = () => {
  showConfirm.value = true;
};
</script>
<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="
      isActionLoading ||
      !canManageThirdPartyApp ||
      computedDisableActivateInterfaceButton
    "
    :icon="!isActionLoading ? buttonInfo.icon : undefined"
    :title="buttonInfo.title"
    class="invisible"
    @click.stop.prevent="onActionClick"
  >
    <lxc-loader v-if="isActionLoading" />
  </lxc-button>
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="confirmInfo.title"
    :description="confirmInfo.message"
    :ok-label="t('button.confirm')"
    :cancel-label="t('button.cancel')"
    :icon="ILxcAlertCircle"
    :icon-color-theme="'warning'"
    @confirm="onActionConfirm"
    @cancel="showConfirm = false"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
