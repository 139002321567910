<script setup lang="ts">
import { saveAs } from "file-saver";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { useMobileInterface } from "~/composables/useMobileInterface";
import mobileInterfaceService from "~/services/mobileInterface.service";
import { useConfigStore } from "~/stores/useConfigStore";
import LxcError from "~/utils/LxcError";
import { addMinutesToDate } from "~/utils/date-tools";
import { NotificationKey } from "~/utils/notifications-tools";
import ILxcDownload from "~icons/lxc/download";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const { isLoading, results, error, fetchData, canViewMobileApplication } =
  useMobileInterface();
const { isMobileAppOfflineMode } = storeToRefs(useConfigStore());

const qrCode: Ref<string | undefined> = ref();
const isQrCodeLoading = ref(false);
const qrCodeGenerationError: Ref<LxcError | undefined> = ref();
const showPasswordModal = ref(false);
const isDownloadZipFileLoading = ref(false);

interface SelectMobileAppFormI {
  mobileApp: string | undefined;
}

const selectMobileAppFormRef: Ref<HTMLFormElement | undefined | null> = ref();
const selectMobileAppForm = reactive<SelectMobileAppFormI>({
  mobileApp: undefined,
});

const selectMobileAppFormRules = {
  mobileApp: [
    {
      required: true,
      message: t("connectMobileApp.form.mobileApp.validation.required"),
      trigger: "change",
    },
  ],
};

async function isFormValid() {
  return await selectMobileAppFormRef.value?.validate().catch(() => false);
}

async function generateQRCode() {
  if ((await isFormValid()) && selectMobileAppForm.mobileApp) {
    isQrCodeLoading.value = true;
    qrCodeGenerationError.value = undefined;

    const response = await mobileInterfaceService.generateQRCode(
      selectMobileAppForm.mobileApp,
    );

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
      qrCodeGenerationError.value = response;
    } else {
      qrCode.value = URL.createObjectURL(
        new Blob([response], { type: "image/png" }),
      );
    }
    isQrCodeLoading.value = false;
  }
}

async function displayPasswordModal() {
  if ((await isFormValid()) && selectMobileAppForm.mobileApp) {
    showPasswordModal.value = true;
  }
}

async function downloadZipFile(password: string) {
  if ((await isFormValid()) && selectMobileAppForm.mobileApp) {
    isDownloadZipFileLoading.value = true;
    const response =
      await mobileInterfaceService.downloadOfflineConfigurationZip(
        selectMobileAppForm.mobileApp,
        password,
      );

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
    } else {
      const zipFileName = `offlineConfiguration-${selectMobileAppForm.mobileApp}.zip`;
      const blob = new Blob([response], { type: "application/octet-stream" });
      saveAs(blob, zipFileName);
    }
  }
  isDownloadZipFileLoading.value = false;
  showPasswordModal.value = false;
}

onMounted(fetchData);
</script>
<template>
  <lxc-container :is-loading="isLoading" :error="error" :py="4">
    <div class="flex justify-between">
      <h1>{{ t("connectMobileApp.title") }}</h1>
      <lxc-button
        v-if="isMobileAppOfflineMode"
        html-type="button"
        type="secondary"
        :icon="ILxcDownload"
        title=""
        class="self-center"
        @click="displayPasswordModal"
      >
        {{ t("connectMobileApp.downloadOfflineAccess.button") }}
      </lxc-button>
    </div>
    <div class="flex gap-16">
      <div>
        <lxc-form
          ref="selectMobileAppFormRef"
          :model="selectMobileAppForm"
          :rules="selectMobileAppFormRules"
          class="flex mt-5"
        >
          <lxc-form-item
            :label="t('connectMobileApp.form.mobileApp.label')"
            prop="mobileApp"
          >
            <lxc-select
              v-model="selectMobileAppForm.mobileApp"
              :placeholder="t('connectMobileApp.form.mobileApp.placeholder')"
            >
              <lxc-option
                v-for="mobileApp in results?.data"
                :key="mobileApp.clientId"
                :value="mobileApp.clientId"
                :label="mobileApp.name"
              />
            </lxc-select>
          </lxc-form-item>
        </lxc-form>
        <lxc-button
          html-type="button"
          type="primary"
          :icon="ILxcPlus"
          :title="t('connectMobileApp.form.button.content')"
          class="mt-3 self-start"
          :disabled="!canViewMobileApplication()"
          @click="generateQRCode"
        >
          {{ t("connectMobileApp.form.button.content") }}
        </lxc-button>
      </div>
      <div
        v-if="qrCode || isQrCodeLoading || qrCodeGenerationError"
        class="border rounded border-gray-300 shrink-0 mt-5 text-center"
      >
        <lxc-container
          :px="0"
          :py="0"
          :is-loading="isQrCodeLoading"
          loader-container-class="mt-0 p-10"
          :error="qrCodeGenerationError"
        >
          <img :src="qrCode" alt="qr-code" />
          <p>
            {{ t("connectMobileApp.qrCode.expirationDate") }}
            {{ addMinutesToDate(new Date(), 5) }}
          </p>
        </lxc-container>
      </div>
    </div>
  </lxc-container>
  <lxc-download-offline-password-modal
    v-model:show="showPasswordModal"
    :is-download-zip-file-loading="isDownloadZipFileLoading"
    @download="downloadZipFile"
  />
</template>
