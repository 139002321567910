import RestService from "./rest.service";
import type { RolesI } from "@lxc/app-device-types";

export class RolesService extends RestService {
  protected BASE_URL = "/roles";

  /**
   * Retrieve all grantable roles
   */
  getAllGrantableRoles(): Promise<RolesI> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new RolesService();
