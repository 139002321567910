import { OperationStatus } from "@lxc/app-device-types";

export enum MetadataStatus {
  ERROR,
  MIXED,
  SUCCESS,
  UNKNOWN,
}

/**
 * Function used to compute the global metadata status to be used in the LxcDtwinsListOperationStatus component. It
 * computes the all the metastatus statuses and return a global status which describe if all the operations can be
 * considered as successful, in failure or mixed (mix of success and failures). If there is no metastatus statuses,
 * return an unknown global status.
 * @param operationStatuses The metastatus statuses to be computed to determine the global metadata status.
 */
export function compute(
  operationStatuses: Record<string, OperationStatus> | undefined,
): MetadataStatus {
  if (
    operationStatuses !== undefined &&
    Object.keys(operationStatuses).length !== 0
  ) {
    const values = Object.values(operationStatuses);
    const valuesSimplified = new Set(values);
    if (
      (valuesSimplified.has(OperationStatus.DONE) ||
        valuesSimplified.has(OperationStatus.CANCELLED)) &&
      !(
        valuesSimplified.has(OperationStatus.FAILED) ||
        valuesSimplified.has(OperationStatus.EXPIRED)
      )
    ) {
      return MetadataStatus.SUCCESS;
    } else if (
      (valuesSimplified.has(OperationStatus.FAILED) ||
        valuesSimplified.has(OperationStatus.EXPIRED)) &&
      !(
        valuesSimplified.has(OperationStatus.DONE) ||
        valuesSimplified.has(OperationStatus.CANCELLED)
      )
    ) {
      return MetadataStatus.ERROR;
    }
    return MetadataStatus.MIXED;
  }
  return MetadataStatus.UNKNOWN;
}
