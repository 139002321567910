<script setup lang="ts">
interface Props {
  tags: unknown | unknown[];
  max?: number;
}
withDefaults(defineProps<Props>(), {
  max: 1,
});
</script>
<template>
  <LxcTagSet :child-class="['!px-2 !py-1 text-xs']" :data="tags" :max="max" />
</template>
