import type {
  ReportingConfigurationI,
  ReportingRecipientI,
} from "@lxc/app-device-types";
import type { Ref } from "vue";
import CampaignService from "~/services/campaign.service";
import LxcError from "~/utils/LxcError";

export function useCertificateUpdateReporting() {
  const configuration = ref<ReportingConfigurationI>();
  const configurationBackup = ref<ReportingConfigurationI>();
  const isLoading = ref(false);
  const error: Ref<LxcError | null> = ref(null);

  function cloneConfiguration(
    response: ReportingConfigurationI,
  ): ReportingConfigurationI {
    return { ...response };
  }

  async function fetchConfiguration() {
    isLoading.value = true;
    const response = await CampaignService.getReportingConfiguration();

    if (LxcError.check(response)) {
      error.value = response;
    } else {
      configuration.value = response;
      configurationBackup.value = cloneConfiguration(response);
    }

    isLoading.value = false;

    return response;
  }

  async function updateConfiguration(config: ReportingConfigurationI) {
    isLoading.value = true;
    const response = await CampaignService.postReportingConfiguration(config);

    if (!LxcError.check(response)) {
      configuration.value = response;
      configurationBackup.value = cloneConfiguration(response);
    }
    isLoading.value = false;

    return response;
  }

  async function addReportingRecipients(
    configurationId: string,
    recipients: Array<ReportingRecipientI>,
  ) {
    return await CampaignService.addReportingRecipients(
      configurationId,
      recipients,
    );
  }

  async function removeReportingRecipients(
    configurationId: string,
    recipients: Array<ReportingRecipientI>,
  ) {
    return await CampaignService.removeReportingRecipients(
      configurationId,
      recipients,
    );
  }

  return {
    configuration,
    configurationBackup,
    error,
    isLoading,
    fetchConfiguration,
    updateConfiguration,
    addReportingRecipients,
    removeReportingRecipients,
  };
}
