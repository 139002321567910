<script setup lang="ts">
import { EMPTY_PLACEHOLDER } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";

export interface LxcDtwinsDescriptionIdCardI {
  friendlyName: string;
  type: string;
  serialNumber: string;
  hardwareVersion: string;
  doesUpdateSucceed: boolean | undefined;
}

interface Props {
  modelValue: LxcDtwinsDescriptionIdCardI;
  isAllowedToEdit: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(["update:name"]);

const { t } = useI18n();

const name: Ref<string> = ref(props.modelValue.friendlyName);
const isEditing: Ref<boolean> = ref(false);
const isSending: Ref<boolean> = ref(false);
const isEdited: ComputedRef<boolean> = computed(
  () => name.value !== props.modelValue.friendlyName,
);

// When the request to the backend succeed, reset the editing flag to true
watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue.doesUpdateSucceed) {
      isEditing.value = false;
    }
    isSending.value = false;
  },
);

function onEdit(): void {
  isEditing.value = !isEditing.value;
}

function onSave(): void {
  isSending.value = true;
  emit("update:name", name.value);
}

function onCancel(): void {
  isEditing.value = false;
  name.value = props.modelValue.friendlyName;
}

// activate the leave page confirm message if edited
watch(
  () => isEdited.value,
  (isEdited) => useConfirmLeavePageStore().activate(isEdited),
);
</script>

<template>
  <LxcDescriptionContainer :title="t(`dtwins.form.description.idCard.title`)">
    <template #left>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.name')"
      >
        <lxc-input
          v-model="name"
          type="text"
          :placeholder="EMPTY_PLACEHOLDER"
          :disabled="!isEditing"
          style="padding-bottom: 10px"
        />
        <lxc-button
          v-if="!isEditing"
          :disabled="!isAllowedToEdit"
          type="secondary"
          :title="t('button.edit')"
          @click="onEdit"
        >
          {{ t("button.edit") }}
        </lxc-button>
        <div v-if="isEditing" class="flex gap-2.5">
          <lxc-button
            :disabled="!isEdited"
            :title="t('button.validate')"
            @click="onSave"
          >
            <div class="flex">
              {{ t("button.validate") }}
              <lxc-loader v-if="isSending" class="ml-2" :size="5" />
            </div>
          </lxc-button>
          <lxc-button
            type="secondary"
            :title="t('button.cancel')"
            @click="onCancel"
          >
            {{ t("button.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.type')"
      >
        <lxc-input :value="modelValue.type" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.serialNumber')"
      >
        <lxc-input :value="modelValue.serialNumber" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.hardwareVersion')"
      >
        <lxc-input :value="modelValue.hardwareVersion" type="text" read-only />
      </lxc-form-item>
    </template>
  </LxcDescriptionContainer>
</template>
