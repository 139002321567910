<script setup lang="ts">
import { LicenseStatus } from "@lxc/app-device-types";
import type { DebuggerEventExtraInfo } from "vue";
import { BreadcrumbConfig } from "~/components/shared/breadcrumb/breadcrumb.config";
import { useAppInterface } from "~/composables/useAppInterface";
import { useLicenseStatus } from "~/composables/useLicenseStatus";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { FeaturesName } from "~/types/deviceFeatures";
import ILxcPlus from "~icons/lxc/plus";

const mobileAppTabKey = "mobileApp";
const thirdPartyAppKey = "thirdPartyApp";

const interfaceFormShown = ref<boolean>(false);
const isLoading: Ref<boolean> = ref(false);
const selectedTab: Ref<string> = ref("");

const { t } = useI18n();
const {
  canViewMobileApp,
  canManageMobileApp,
  canManageThirdPartyApps,
  canViewThirdPartyApps,
} = useAppInterface();
const { getLicenseStatus } = useLicenseStatus();

async function fetchLicenseStatus() {
  isLoading.value = true;
  await getLicenseStatus();
  selectedTab.value = canViewMobileApp() ? mobileAppTabKey : thirdPartyAppKey;
  isLoading.value = false;
}

const licenseStatusStore = useLicenseStatusStore();
const disableCreateActivateInterfaceButtons = computed(() => {
  return (
    (licenseStatusStore.licenseStatus?.datasharingFeature &&
      (licenseStatusStore.licenseStatus.datasharingFeature
        .currentNumberOfInterfaces >=
        licenseStatusStore.licenseStatus.datasharingFeature
          .maxNumberOfInterfaces ||
        licenseStatusStore.licenseStatus.statusOfLicense ===
          LicenseStatus.EXPIRED_DATE)) ??
    false
  );
});

const displayThirdPartyAppsTabs: Ref<boolean | undefined> = ref();
licenseStatusStore.$subscribe((mutation) => {
  if (
    (mutation.events as DebuggerEventExtraInfo).key ===
    licenseStatusStore.licenseStateKeys.LICENSE_STATUS
  ) {
    displayThirdPartyAppsTabs.value =
      licenseStatusStore.isLicenseFeatureDataSharingPresent &&
      licenseStatusStore.licenseStatus?.statusOfLicense !==
        LicenseStatus.NOT_ACTIVATED;
  }
});

const onUpdateInterfaceFormShown = (show: boolean) => {
  interfaceFormShown.value = show;
};

const onCreateNewInterface = () => {
  onUpdateInterfaceFormShown(true);
};

const onTabSelected = (_: number, name?: string) => {
  selectedTab.value = name ?? "";
};

const newInterfaceDisabled = computed(() => {
  if (selectedTab.value === mobileAppTabKey) {
    return !canManageMobileApp();
  } else if (selectedTab.value === thirdPartyAppKey) {
    return (
      !canManageThirdPartyApps() || disableCreateActivateInterfaceButtons.value
    );
  }
  return true;
});

const newInterfaceTooltip = computed(() => {
  return newInterfaceDisabled.value &&
    selectedTab.value === thirdPartyAppKey &&
    canManageThirdPartyApps()
    ? t("applicationMgt.tabs.thirdPartyApp.licenseMessage.disableNewInterface")
    : t("applicationMgt.newInterface");
});

onMounted(fetchLicenseStatus);
</script>
<template>
  <lxc-container>
    <div class="flex justify-between">
      <lxc-breadcrumb :name="BreadcrumbConfig.APPLICATION_MGT.title" readonly />
      <lxc-button
        :icon="ILxcPlus"
        :title="newInterfaceTooltip"
        class="self-end"
        html-type="button"
        type="primary"
        :disabled="newInterfaceDisabled"
        @click="onCreateNewInterface"
      >
        {{ t("applicationMgt.newInterface") }}
      </lxc-button>
    </div>
    <lxc-tabs v-if="!isLoading" class="py-8" @on-tab-selected="onTabSelected">
      <lxc-tab-pane
        v-if="canViewMobileApp()"
        :label="t('applicationMgt.tabs.mobileApp.title')"
        :name="mobileAppTabKey"
      >
        <lxc-mobile-application-list
          :interface-form-shown="interfaceFormShown"
          @update:side-canvas-shown="onUpdateInterfaceFormShown"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        v-if="canViewThirdPartyApps() && displayThirdPartyAppsTabs"
        :label="t('applicationMgt.tabs.thirdPartyApp.title')"
        :name="thirdPartyAppKey"
      >
        <lxc-license-warning-messages :context="FeaturesName.DATA_SHARING" />
        <lxc-third-party-application-list
          :interface-form-shown="interfaceFormShown"
          :disable-activate-interface-button="
            disableCreateActivateInterfaceButtons
          "
          @update:side-canvas-shown="onUpdateInterfaceFormShown"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
