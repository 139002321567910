<script setup lang="ts">
import type { AppDeviceState, ApplicationI } from "@lxc/app-device-types";
import { ErrorCode } from "@lxc/app-device-types";
import { useUpdateState } from "~/composables/useUpdateState";
import ApplicationService from "~/services/application.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcClose from "~icons/lxc/x";

const { t } = useI18n();
const route = useRoute();
const { createDataForStateUpdate, getState } = useUpdateState();
const [confirmUpdateStateVisible, toggleConfirmUpdateState] = useToggle();

const application: Ref<ApplicationI | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);
const applicationId = route.params.appId;
const isLoading = ref(true);
const activeName = ref("description");
const disabledConfirm = ref(false);

async function fetchApplication() {
  const response = await ApplicationService.getApplicationDetails(
    applicationId.toString(),
  );

  if (LxcError.check(response)) {
    application.value = null;
    error.value = response;
  } else {
    application.value = response;
  }

  isLoading.value = false;
}

async function updateState(state: AppDeviceState, id: string) {
  disabledConfirm.value = true;
  const response = await ApplicationService.updateApplicationState(
    id,
    getState(state),
  );

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DEACTIVATION_CONFLICT) {
      showNotificationError(t("application.updateState.deactivate.error"));
    } else {
      response.notify(NotificationKey.saveError);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
  }

  toggleConfirmUpdateState();
  disabledConfirm.value = false;
  await fetchApplication();
}

const options = computed(() => {
  if (application.value) {
    return createDataForStateUpdate(
      application.value.state!,
      "application",
      application.value.name!,
    );
  }
});

const connectivityStateBadge = computed(() => {
  return application.value?.connectivity?.state === "inactive"
    ? "danger"
    : "secondary";
});

onMounted(async () => {
  await fetchApplication();
});
</script>

<template>
  <div class="flex justify-between px-8 pt-8">
    <div class="flex items-center">
      <lxc-breadcrumb
        back
        :name="application?.name ?? ''"
        should-not-translate
      />
      <div v-if="application" class="self-center ml-4">
        <lxc-badge :type="connectivityStateBadge">
          {{
            t(`filters.connectivityState.${application?.connectivity?.state}`)
          }}
        </lxc-badge>
      </div>
    </div>
    <lxc-button
      v-if="application && options"
      class="self-end"
      type="tertiary"
      :icon="ILxcClose"
      :title="options?.activatedButton"
      @click="toggleConfirmUpdateState(true)"
    >
      {{ options.activatedButton }}
    </lxc-button>
  </div>

  <lxc-container :is-loading="isLoading" :error="error">
    <lxc-tabs v-model="activeName" class="tabs">
      <lxc-tab-pane
        :label="t('application.description.title')"
        name="description"
      >
        <lxc-application-description :application="application" />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="t('application.additionalMetadata.title')"
        name="additionalMetadata"
      >
        <lxc-application-metadata :application="application!" />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t('application.certificate')" name="certificate">
        <lxc-application-certificate :application="application!" />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
  <lxc-confirm-modal
    v-if="options"
    :is-dialog-visible="confirmUpdateStateVisible"
    :title="options.title"
    :description="options.description"
    :info="options.warning"
    :disabled-confirm="disabledConfirm"
    @cancel="toggleConfirmUpdateState"
    @confirm="updateState(application?.state!, application?.id!)"
  />
</template>
