<script setup lang="ts">
import { s4ListTypeOptions } from "~/constants/deviceFilters.config";
import { FeaturesName } from "~/types/deviceFeatures";
</script>
<template>
  <lxc-container>
    <lxc-license-warning-messages :context="FeaturesName.ADVANCED_FLEET_MGT" />
    <lxc-breadcrumb class="mb-8" />
    <lxc-devices-list
      :type-options="s4ListTypeOptions"
      :use-query-parameters-for-pagination="true"
    />
  </lxc-container>
</template>
