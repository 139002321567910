<script setup lang="ts">
import { FirmwareStatus } from "@lxc/app-device-types";
import { typeOptions as defaultRangeOptions } from "~/constants/deviceFilters.config";
import { FilterInputType, Filters } from "~/types";
import type {
  FilterFormSection,
  FilterOptions,
  FilterSelectionValue,
  FiltersSelection,
} from "~/types";

const props = defineProps<{
  filters: FiltersSelection;
  statusOptions?: FilterOptions;
  rangeOptions?: FilterOptions;
}>();

const { t } = useI18n();

const statusLabel = t("firmware.filters.status");
const rangeLabel = t("filters.firmware.range");
const creationDateLabel = t("firmware.filters.creationDate.label");

const defaultStatusOptions: FilterOptions = {
  label: statusLabel,
  options: Object.values(FirmwareStatus).map((value) => {
    return {
      value,
      label: `firmware.status.${value}`,
    };
  }),
};

const filterStatusOptions = ref(
  props.statusOptions?.options ?? defaultStatusOptions.options,
);
const filterRangeOptions = ref(
  props.rangeOptions?.options ?? defaultRangeOptions.options,
);

const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: Filters.STATUS,
    footerEnabled: true,
    footerId: "status-footer",
    header: statusLabel,
    id: "status",
    inputType: FilterInputType.RADIO,
    menuLabel: statusLabel,
    options: filterStatusOptions,
    tagPrefix: statusLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.RANGE,
    footerId: "ranges-footer",
    footerEnabled: true,
    header: rangeLabel,
    id: "ranges",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: rangeLabel,
    options: filterRangeOptions,
    tagPrefix: rangeLabel,
    translate: false,
  },
  {
    additionalFilter: Filters.DATE_ADDED_BEFORE,
    disabled: false,
    filter: Filters.DATE_ADDED_AFTER,
    footerId: "creationDate-footer",
    footerEnabled: true,
    header: creationDateLabel,
    id: "creationDate",
    inputType: FilterInputType.PERIOD_PICKER,
    menuLabel: creationDateLabel,
    tagPrefix: creationDateLabel,
    translate: true,
  },
];

const emit = defineEmits(["change", "enter"]);

function setFilter(filter: Filters, value: FilterSelectionValue) {
  emit("change", filter, value);
}
</script>

<template>
  <div class="relative mb-4">
    <lxc-filters
      :filter-sections="filterFormSections"
      :filters="filters"
      is-button-right
      @change="setFilter"
      @enter="emit('enter', $event)"
    />
  </div>
</template>
