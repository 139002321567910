import type {
  ConnectivityStatusParameters,
  DtwinModelI,
} from "@lxc/app-device-types";

export enum Unit {
  SECONDS,
  MINUTES,
  HOURS,
}

export enum RoundMode {
  ROUND_UP,
  ROUND_DOWN,
}

/**
 * Function used to extract the unit type to be displayed in the tooltip.
 * @param durationInSeconds Duration in seconds to be analyzed.
 */
export function getUnit(durationInSeconds: number): Unit {
  let unit = Unit.HOURS;
  if (durationInSeconds < 60) {
    unit = Unit.SECONDS;
  } else if (durationInSeconds / 60 < 60) {
    unit = Unit.MINUTES;
  }
  return unit;
}

/**
 * Function used to convert a duration to the valid tooltip representation.
 * Return the input value by default if the unit is not supported.
 * @param durationInSeconds Duration in seconds to be computed.
 * @param unit Duration unit.
 * @param mode Used to configure the round method (floor or round).
 */
export function computeTooltipDurationValue(
  durationInSeconds: number,
  unit: Unit,
  mode: RoundMode,
): number {
  let computedDuration = durationInSeconds;
  switch (unit) {
    case Unit.MINUTES:
      computedDuration = durationInSeconds / 60;
      break;
    case Unit.HOURS:
      computedDuration = durationInSeconds / 3600;
      break;
  }
  return mode === RoundMode.ROUND_DOWN
    ? Math.floor(computedDuration)
    : Math.ceil(computedDuration);
}

export function getConnectivityStatusParameters(
  deviceModelUid?: string,
  models?: DtwinModelI[],
): ConnectivityStatusParameters | undefined {
  return models?.find((model: DtwinModelI) => model.uid === deviceModelUid)
    ?.connectivityStatusParameters;
}
