export function roundToTwoDecimals(value: number): number {
  return Math.round(value * 100) / 100;
}

export function zeroIfNaN(value?: number): number {
  return value === undefined || isNaN(value) ? 0 : value;
}

export function sum(values: Array<number | undefined>): number {
  return (
    values.reduce(
      (total: number | undefined, value: number | undefined) =>
        zeroIfNaN(total) + zeroIfNaN(value),
      0,
    ) || 0
  );
}

export function round(value: number, decimal: number): number {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
