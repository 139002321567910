import type { ErrorCause } from "@lxc/app-device-common";
import type { ErrorCode, ErrorResponse } from "@lxc/app-device-types";
import { i18n } from "~/plugins/i18n";
import {
  NotificationKey,
  setDetailMessageAsListItem,
  showNotificationError,
} from "~/utils/notifications-tools";

export interface ErrorResponseWithStatus extends ErrorResponse {
  status?: number;
}

export default class LxcError implements ErrorResponseWithStatus {
  public code: ErrorCode;
  public message: string | undefined;
  public timestamp: number | undefined;
  public details: string[];
  public status?: number;
  public variables: string[][];

  constructor(error: ErrorResponseWithStatus) {
    this.code = error.code;
    this.message = error.message;
    this.timestamp = error.timestamp;
    this.details = error.details ?? [];
    this.variables = error.variables ?? [];
    this.status = error.status;
  }

  /**
   * Return true if the provided parameter is an error, false otherwise
   * @param error any
   * @returns true|false
   */
  static check(error: any): error is LxcError {
    return error instanceof LxcError;
  }

  /**
   * Show a notification about the error
   * @param titleKey title i18n translation key
   */
  public notify(titleKey: NotificationKey) {
    const error: Error = this.toError(titleKey);
    if ((error.cause as ErrorCause).values) {
      showNotificationError(
        i18n.global.t(titleKey),
        (error.cause as ErrorCause).values
          .map(setDetailMessageAsListItem)
          .join(""),
      );
    } else {
      showNotificationError(
        i18n.global.t(titleKey),
        (error.cause as unknown as string) ?? error.message,
      );
    }
  }

  /**
   * Format an LxcError to Error
   * Build Error based on details if it is not empty, otherwise buid Error on code
   * @param titleKey title i18n translation key, default: NotificationKey.error
   * @returns Error
   */
  public toError(titleKey: NotificationKey = NotificationKey.error): Error {
    // case when details is not empty => build error from details
    if (this.details.length) {
      const options = {
        cause: {
          values: this.getDetailsTranslations(),
        },
      };

      return new Error(i18n.global.t(titleKey), options);
    }
    // case when details is empty => build error from code
    else {
      if (
        i18n.global.te(`errors.${this.code}.title`) &&
        i18n.global.te(`errors.${this.code}.subtitle`)
      ) {
        return new Error(i18n.global.t(`errors.${this.code}.title`), {
          cause: i18n.global.t(`errors.${this.code}.subtitle`),
        });
      } else {
        return new Error(i18n.global.t("errors.UNEXPECTED.title"), {
          cause: i18n.global.t("errors.UNEXPECTED.subtitle"),
        });
      }
    }
  }

  /**
   * Build and return the translations for the error details attributes
   * Do not return translations for unexisting keys
   * @returns
   */
  private getDetailsTranslations(): Array<string> {
    return this.details
      .map((detail, detailIndex) => {
        const options: any = {};
        for (const variableIndex in this.variables[detailIndex]) {
          options[`var${variableIndex}`] =
            this.variables[detailIndex][variableIndex];
        }

        const key = `errors.${this.code}.details.${detail}`;
        return i18n.global.te(key) ? i18n.global.t(key, options) : "";
      })
      .filter((detailTranslation: string) => !!detailTranslation);
  }
}
