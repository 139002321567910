<script setup lang="ts">
import type {
  CampaignI,
  CampaignOperationMgrI,
  InclusionPeriodI,
} from "@lxc/app-device-types";

const props = defineProps<{
  campaign?: CampaignI | null;
  campaignOperationMgr?: CampaignOperationMgrI;
}>();

const { t } = useI18n();

const inclusionPeriods = computed(() => {
  const inclusionPeriods: Map<string, string> = new Map([
    ["MONDAY", ""],
    ["TUESDAY", ""],
    ["WEDNESDAY", ""],
    ["THURSDAY", ""],
    ["FRIDAY", ""],
    ["SATURDAY", ""],
    ["SUNDAY", ""],
  ]);

  let campaignInclusionPeriods = undefined;

  if (props.campaign) {
    campaignInclusionPeriods = props.campaign?.config?.inclusionPeriods;
  } else if (
    props.campaignOperationMgr?.operationParametersList &&
    props.campaignOperationMgr?.operationParametersList.length > 0 &&
    props.campaignOperationMgr.operationParametersList[0].customInputParameters
  ) {
    // only inspect the first operation because the inclusion periods are the same for all the operations
    campaignInclusionPeriods = props.campaignOperationMgr
      .operationParametersList[0].customInputParameters
      .inclusionPeriods as InclusionPeriodI[];
  }

  if (campaignInclusionPeriods && campaignInclusionPeriods?.length > 0) {
    for (const inclusionPeriod of campaignInclusionPeriods) {
      const valueOfDay = inclusionPeriods.get(inclusionPeriod.day);
      const value = valueOfDay
        ? `${valueOfDay} | ${inclusionPeriod.startTimeSlot} - ${inclusionPeriod.endTimeSlot}`
        : `${inclusionPeriod.startTimeSlot} - ${inclusionPeriod.endTimeSlot}`;
      inclusionPeriods.set(inclusionPeriod.day, value);
    }
  } else {
    for (const key of inclusionPeriods.keys()) {
      inclusionPeriods.set(key, "00:00 - 00:00");
    }
  }
  return inclusionPeriods;
});
</script>

<template>
  <p class="underline underline-offset-auto font-medium">
    {{ t("campaign.details.characteristics.inclusionPeriods.title") }}
  </p>
  <div v-for="[key, value] in inclusionPeriods" :key="key">
    <p v-if="value.length" class="font-medium">
      {{
        t(
          `campaign.details.characteristics.inclusionPeriods.${key.toLocaleLowerCase()}`,
          { value: `${value}` },
        )
      }}
    </p>
    <p v-else class="text-gray-500 font-medium">
      {{
        t(
          `campaign.details.characteristics.inclusionPeriods.${key.toLocaleLowerCase()}`,
          {
            value: t(
              `campaign.details.characteristics.inclusionPeriods.inactive`,
            ),
          },
        )
      }}
    </p>
  </div>
</template>
