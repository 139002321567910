<script setup lang="ts">
import type { ComponentI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ComponentVersion } from "~/components/about/componentsVersion/LxcComponentsVersion.model";
import platformService from "~/services/platform.service";
import { useConfigStore } from "~/stores/useConfigStore";

const { t } = useI18n();
const { technicalVersion } = storeToRefs(useConfigStore());

const isLoading: Ref<boolean> = ref(false);
const componentsVersion: Ref<ComponentVersion[]> = ref<ComponentVersion[]>([]);
const shouldVersionBeDisplayed: ComputedRef<boolean> = computed(
  () => componentsVersion.value.length > 0,
);

/**
 * Map the raw components versions to the UI model. Sort the component by name and translate the name if defined in the
 * project translation files.
 * @param components The raw components versions.
 */
function map(components: ComponentI[]): ComponentVersion[] {
  const componentsVersion: ComponentVersion[] = [];
  for (const component of components) {
    const name = t(`platform.components.${component.name}`);
    // If the name of the module is not found in the translation file, use the input key as name.
    componentsVersion.push(
      new ComponentVersion(
        name !== `platform.components.${component.name}`
          ? name
          : component.name,
        component.version,
      ),
    );
  }
  return componentsVersion.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
}

/**
 * Fetch the component versions and sort the components by name.
 */
async function getComponentsVersion() {
  isLoading.value = true;
  const components = await platformService.fetch();
  componentsVersion.value = map(components);
  isLoading.value = false;
}

onMounted(async () => {
  await getComponentsVersion();
});
</script>
<template>
  <lxc-container :is-loading="isLoading">
    <h2 class="title">LX CONNECT</h2>
    <p>
      {{ t("platform.technicalVersion") }}
      {{ technicalVersion ?? t("parameters.technicalVersion.unknown") }}
    </p>
    <div v-if="shouldVersionBeDisplayed">
      <h4 class="title">
        {{ t("platform.title") }}
      </h4>
      <div id="app">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ t(`platform.tile.component`) }}</th>
              <th>{{ t(`platform.tile.version`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="component in componentsVersion" :key="component.name">
              <td>{{ component.name }}</td>
              <td v-if="component.version">
                {{ component.version }}
              </td>
              <td v-else>
                {{ t(`platform.tile.noData`) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </lxc-container>
</template>
<style scoped lang="scss">
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 60%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #e2e2e2;
}
</style>
