<script lang="ts" setup>
import {
  CACertificateItemType,
  KeystoreCertificateI,
} from "@lxc/app-device-types";
import { useExportCertificateAsString } from "~/composables/useCaCertificates";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import ILxcLightDownload from "~icons/lxc-light/download";

const { t } = useI18n();
const { exportCertificateAsString } = useExportCertificateAsString();
const { canViewCertificates } = useCertificatesACLRoles();

const props = defineProps<{
  certificate: KeystoreCertificateI;
}>();

const downloadLabel: ComputedRef<string> = computed(() =>
  props.certificate.type === CACertificateItemType.CSR
    ? t("certificates.downloadCSR")
    : t("certificates.download"),
);

const isDownloadDisabled: ComputedRef<boolean> = computed(
  () => !canViewCertificates() || !props.certificate.certificate,
);

const onDownloadCertificateClick = () => {
  if (props.certificate.certificate) {
    exportCertificateAsString(
      props.certificate.certificate,
      props.certificate.alias,
      props.certificate.type,
    );
  }
};
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="isDownloadDisabled"
    :icon="ILxcLightDownload"
    :title="downloadLabel"
    class="invisible"
    @click.stop.prevent="onDownloadCertificateClick"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
