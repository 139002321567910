import { createI18n } from "vue-i18n";
import messages from "~/assets/locales";

export const fallbackLocaleTag = "en";
export const i18n = createI18n({
  legacy: false,
  locale: fallbackLocaleTag,
  fallbackLocale: fallbackLocaleTag,
  globalInjection: true,
  messages,
});
export function setLocaleTag(localeTag: string) {
  // @ts-expect-error `createI18n` creates an instance of i18n using the internal locale of the project (generated
  // code). If a locale is not supported, the fallback locale is already defined by the plugin. The `expected error`
  // has been added as the locale are extracted from the generated translation files and cannot be find using the
  // source code.
  i18n.global.locale.value = localeTag;
}
