import { useFetchPage } from "~/composables/useFetchPage";
import { SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import OperationManagerService from "~/services/operationManager.service";
import {
  Filters,
  FiltersSelection,
  FiltersType,
  OPERATOR_RSQL_IN,
} from "~/types";
import filtersUtils from "~/utils/filters.utils";

export function useOperationManager() {
  const service = OperationManagerService.getOperations.bind(
    OperationManagerService,
  );

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.OPERATION_CAMPAIGN_UID,
      {
        key: "campaignUid",
        operator: OPERATOR_RSQL_IN,
        value: [],
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    SearchMode.URL_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: true,
  });

  return {
    isLoading,
    error,
    results,
    filters,
    setFilter,
    fetchData,
    onSortChange,
    search,
    onSearch,
    sort,
  };
}
