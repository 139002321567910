<script setup lang="ts">
import type { DeviceI } from "@lxc/app-device-types";
import { AppDeviceState, OperationType } from "@lxc/app-device-types";
import { useUserSession } from "~/stores/useUserSession";
import { ObjectType } from "~/types";
import { Roles } from "~/types/roles";
import { formatIsoDate } from "~/utils/date-tools";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  device: DeviceI;
}>();
const { t } = useI18n();
const [confirmUpdateCertificateVisible, toggleConfirmUpdateCertificate] =
  useToggle();

const activeName = ref("unitary");
const operationsListRef = ref();
const certificateFormRef = ref();
const certificateForm = reactive({
  expirationDate: "",
  certificateOrigin: "",
  lastUpdate: "",
});

const userSessionStore = useUserSession();
const disabledUpdateCertificateButton = computed(
  () =>
    props.device.state !== AppDeviceState.ACTIVATED ||
    !userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW),
);

onMounted(() => {
  Object.assign(certificateForm, {
    expirationDate: props.device?.certificate?.notValidAfter
      ? formatIsoDate(
          props.device?.certificate?.notValidAfter,
          t("dateFormat.date"),
        )
      : "-",
    certificateOrigin: "n/a",
    lastUpdate: "n/a",
  });
});

function updateOperationList() {
  operationsListRef.value.updateTable();
}
</script>
<template>
  <div class="flex flex-col">
    <h3>{{ t("device.certificate") }}</h3>
    <lxc-form ref="certificateFormRef" :model="certificateForm">
      <div class="flex">
        <div class="w-1/3">
          <lxc-form-item :label="t('device.certificateForm.expirationDate')">
            <lxc-input
              v-model="certificateForm.expirationDate"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item :label="t('device.certificateForm.certificateOrigin')">
            <lxc-input
              v-model="certificateForm.certificateOrigin"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
        <div class="w-1/3">
          <lxc-form-item :label="t('device.certificateForm.lastUpdate')">
            <lxc-input
              v-model="certificateForm.lastUpdate"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </div>
    </lxc-form>
    <lxc-button
      class="w-fit"
      :icon="ILxcPlus"
      :title="t('device.updateCertificate')"
      :disabled="disabledUpdateCertificateButton"
      @click="toggleConfirmUpdateCertificate(true)"
    >
      {{ t("device.updateCertificate") }}
    </lxc-button>
  </div>
  <div class="mt-12">
    <h3>
      {{ t("section.operations.title") }}
    </h3>
    <lxc-tabs v-model="activeName" class="tabs">
      <lxc-tab-pane :label="t('device.operation.unitary')" name="unitary">
        <lxc-device-operations-list
          v-if="device?.id"
          ref="operationsListRef"
          :device-id="device.id"
          :device="device"
          :operation-type="OperationType.CRTCLT_RENEWAL_DVC"
          :has-callback-url="false"
        />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t('device.operation.campaign')" name="campaign">
        <lxc-device-campaign-operations-list
          v-if="device?.id"
          :device-id="device.id"
          :operation-type="OperationType.CRTCLT_RENEWAL_DVC"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </div>

  <lxc-update-certificate-modal
    :is-dialog-visible="confirmUpdateCertificateVisible"
    :object="device"
    :object-type="ObjectType.DEVICE"
    @update:toggle-dialog="toggleConfirmUpdateCertificate"
    @change="updateOperationList"
  />
</template>
