<script setup lang="ts">
import type { OperationStatus } from "@lxc/app-device-types";
import { EMPTY_TOOLTIP } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import {
  MetadataStatus,
  compute,
} from "~/components/dtwins/dtwinsList/metastatus/LxcDtwinsListMetadataStatus.helper";
import ILxMetaStatusError from "~icons/lxc-business/metastatus-error";
import ILxMetaStatusMixed from "~icons/lxc-business/metastatus-mixed";
import ILxMetaStatusSuccess from "~icons/lxc-business/metastatus-success";

const { t } = useI18n();

const props = defineProps<{
  operationStatuses: Record<string, OperationStatus> | undefined;
}>();

const metadataStatus: ComputedRef<MetadataStatus> = computed(() => {
  return compute(props.operationStatuses);
});

const tooltip: ComputedRef<string> = computed(() => {
  switch (metadataStatus.value) {
    case MetadataStatus.SUCCESS:
      return `${t("dtwins.list.attributes.metastatus.status.success")}`;
    case MetadataStatus.MIXED:
      return `${t("dtwins.list.attributes.metastatus.status.mixed")}`;
    case MetadataStatus.ERROR:
      return `${t("dtwins.list.attributes.metastatus.status.error")}`;
    case MetadataStatus.UNKNOWN:
    default:
      return EMPTY_TOOLTIP;
  }
});
</script>

<template>
  <span :title="tooltip" class="block mx-auto w-fit">
    <ILxMetaStatusError v-if="metadataStatus === MetadataStatus.ERROR" />
    <ILxMetaStatusMixed v-if="metadataStatus === MetadataStatus.MIXED" />
    <ILxMetaStatusSuccess v-if="metadataStatus === MetadataStatus.SUCCESS" />
  </span>
</template>
