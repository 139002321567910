<script setup lang="ts">
import { useLicenseStatus } from "~/composables/useLicenseStatus";
import applicationMgtService from "~/services/applicationMgt.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  interfaceClientId: string;
  interfaceName: string;
  canManageThirdPartyApps: boolean;
}>();

const emits = defineEmits(["deleted"]);

const { t } = useI18n();

const isDeleting: Ref<boolean> = ref(false);
const showConfirm: Ref<boolean> = ref(false);

const { getLicenseStatus } = useLicenseStatus();

const onDeleteInterface = async () => {
  isDeleting.value = true;
  showConfirm.value = false;
  const response = await applicationMgtService.deleteAppInterface(
    props.interfaceClientId,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    await getLicenseStatus();
    emits("deleted");
  }

  isDeleting.value = false;
};

const onDeleteInterfaceClick = () => {
  showConfirm.value = true;
};
</script>
<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="isDeleting || !canManageThirdPartyApps"
    :icon="!isDeleting ? ILxcLightTrash2 : undefined"
    :title="t('applicationMgt.action.delete.confirm.title')"
    class="invisible"
    @click.stop.prevent="onDeleteInterfaceClick"
  >
    <lxc-loader v-if="isDeleting" />
  </lxc-button>
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('applicationMgt.action.delete.confirm.title')"
    :description="
      t('applicationMgt.action.delete.confirm.message', { name: interfaceName })
    "
    :ok-label="t('button.confirm')"
    :cancel-label="t('button.cancel')"
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @confirm="onDeleteInterface"
    @cancel="showConfirm = false"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
