import type { LicenseStatusI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import { useLicenseStatus } from "~/composables/useLicenseStatus";

export const useLicenseStatusStore = defineStore("licenseStatusStore", () => {
  interface LicenseFeaturesState {
    licenseStatus: LicenseStatusI | undefined | null;
    isLicenseFeatureAdvancedFleetManagementPresent: boolean | undefined;
    isLicenseFeatureAdvancedFleetManagementActive: boolean | undefined;
    isLicenseFeatureDataSharingPresent: boolean | undefined;
    isLicenseFeatureDataSharingActive: boolean | undefined;
  }

  const state = reactive<LicenseFeaturesState>({
    licenseStatus: undefined,
    isLicenseFeatureAdvancedFleetManagementPresent: undefined,
    isLicenseFeatureAdvancedFleetManagementActive: undefined,
    isLicenseFeatureDataSharingPresent: undefined,
    isLicenseFeatureDataSharingActive: undefined,
  });

  // list of the states key in order to be used in $subscribe listener
  const licenseStateKeys = {
    LICENSE_STATUS: "licenseStatus",
    IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_PRESENT:
      "isLicenseFeatureAdvancedFleetManagementPresent",
    IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_ACTIVE:
      "isLicenseFeatureAdvancedFleetManagementActive",
    IS_LICENSE_FEATURE_DATA_SHARING_PRESENT:
      "isLicenseFeatureDataSharingPresent",
    IS_LICENSE_FEATURE_DATA_SHARING_ACTIVE: "isLicenseFeatureDataSharingActive",
  };

  const { getLicenseStatus: useLicenseGetLicenseStatus } = useLicenseStatus();

  async function getLicenseStatus() {
    return useLicenseGetLicenseStatus();
  }

  return {
    ...toRefs(state),
    licenseStateKeys,
    getLicenseStatus,
  };
});
