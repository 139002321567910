<script setup lang="ts">
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { ACL_ROLES } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const acl = useAcl();

const isAllowedToManageFleet = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM));

const fleetFormShown: Ref<boolean> = ref(false);
const onUpdateFleetFormShown = (show: boolean) => {
  fleetFormShown.value = show;
};

const reload: Ref<boolean> = ref(false);
const onReloadFleetList = (reloadList: boolean) => {
  reload.value = reloadList;
};
</script>
<template>
  <lxc-container>
    <div class="flex justify-between mb-[1.375rem]">
      <lxc-breadcrumb />
      <lxc-button
        :icon="ILxcPlus"
        :title="
          isAllowedToManageFleet
            ? t('fleet.addFleet')
            : t('fleet.actionForbidden')
        "
        :disabled="!isAllowedToManageFleet"
        @click="() => (fleetFormShown = true)"
      >
        {{ t("fleet.addFleet") }}
      </lxc-button>
    </div>
    <lxc-fleets-list v-model:reload="reload" />
    <lxc-fleet-form
      :fleet-form-shown="fleetFormShown"
      @update:fleet-form-shown="onUpdateFleetFormShown"
      @reload-fleet-list="onReloadFleetList"
    />
  </lxc-container>
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
