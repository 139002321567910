<script lang="ts" setup>
import type { SectorI, SectorItemActionResult } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import LxcInformationRow from "~/components/shared/LxcInformationRow.vue";
import { useApplication } from "~/composables/useApplication";
import { useDevices } from "~/composables/useDevices";
import { useDtwins } from "~/composables/useDtwins";
import { useToggle } from "~/composables/useToggle";
import { useUserGroups } from "~/composables/useUserGroups";
import { useUsers } from "~/composables/useUsers";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import SectorsService from "~/services/sectors.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { Filters, SectorResource } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  setDetailMessageAsListItem,
  showNotificationError,
  showNotificationSuccess,
  showNotificationWarning,
} from "~/utils/notifications-tools";
import ILxcArrowUpLeft from "~icons/lxc/arrow-up-left";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  primaryKey: string;
  sector: SectorI;
  selectedItems: any[];
  resource: SectorResource;
}>();
const emit = defineEmits(["change"]);
defineExpose({
  openDetachModal,
});

const { t } = useI18n();
const [attachModalVisible, toggleAttachModalVisible] = useToggle();
const [detachModalVisible, toggleDetachModalVisible] = useToggle();

function getSearchComposable() {
  switch (props.resource) {
    case SectorResource.APPLICATION:
      return useApplication;
    case SectorResource.DEVICE:
      return useDevices;
    case SectorResource.DTWINS:
      return useDtwins;
    case SectorResource.USER_GROUP:
      return useUserGroups;
    case SectorResource.USER:
      return useUsers;
  }
}

const useSearch = getSearchComposable();
const { defaultSectorCode } = storeToRefs(useConfigStore());
const {
  itemType,
  isLoading,
  results,
  error,
  getDescription,
  filters,
  fetchData,
  setFilter,
  canManageItems,
  getItemIds,
} = useSearch!();

const attachSelectedItems = ref<any[]>([]);
const detachSelectedItems = ref<any[]>([]);
const displayActions = computed(() => canManageItems(props.sector));
const detachDisabled: ComputedRef<boolean> = computed(
  () => !props.selectedItems?.length,
);

const FilterKey = computed(() => {
  switch (props.resource) {
    case SectorResource.DTWINS:
      return Filters.DTWIN_NAME_OR_SERIAL_NUMBER;
    default:
      return Filters.NAME;
  }
});

/**
 * Fetch items
 * @param page
 */
async function fetchItems(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  const params = new Map();

  switch (props.resource) {
    case SectorResource.DTWINS:
      setFilter(Filters.DTWIN_IN_SECTORS, [defaultSectorCode.value!]);
      setFilter(Filters.DTWIN_NOT_IN_SECTORS, [props.sector.code]);
      break;
    default:
      setFilter(Filters.SECTORS, [defaultSectorCode.value!]);
      params.set("sectorsExclude", [props.sector.code]);
  }

  await fetchData(page, pageSize, params);
}

/**
 * Clearing search input and refetch
 */
async function onSearchClear() {
  setFilter(FilterKey.value, "");
  await fetchItems();
}

/**
 * Search items
 */
async function search() {
  const params = new Map();

  switch (props.resource) {
    case SectorResource.DTWINS:
      setFilter(Filters.DTWIN_NOT_IN_SECTORS, [props.sector.code]);
      break;
    default:
      params.set("sectorsExclude", [props.sector.code]);
  }

  await fetchData(DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE, params);
}

/**
 * Attach item(s) to the sector
 */
async function onAttachToSector() {
  if (attachSelectedItems.value.length > 0) {
    const response = await SectorsService.attachToSector(
      props.sector.code,
      getItemIds(attachSelectedItems),
      props.resource,
    );
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
    } else {
      const responseWithItemDescription = response.map((action) => ({
        ...action,
        name: getDescription(
          attachSelectedItems.value.find(
            (item) => item[props.primaryKey] === action.name,
          ),
        ),
      }));
      displayActionStatus(
        responseWithItemDescription,
        `${NotificationKey.attachSuccess}.${itemType}`,
        `${NotificationKey.attachError}.${itemType}`,
      );
    }

    clearSelectionAndCloseAttachModal();
    emit("change");
  } else {
    showNotificationWarning(t("sectors.modals.attach.errors.list_empty"));
  }
}

function clearSelectionAndCloseAttachModal() {
  toggleAttachModalVisible(false);
  attachSelectedItems.value = [];
}

function clearSelectionAndCloseDetachModal() {
  toggleDetachModalVisible(false);
  detachSelectedItems.value = [];
}

/**
 * Detach item(s) from the sector
 */
async function detachFromSector() {
  if (detachSelectedItems.value.length > 0) {
    const detachResponse = await SectorsService.detachFromSector(
      props.sector.code,
      getItemIds(detachSelectedItems),
      props.resource,
    );
    if (LxcError.check(detachResponse)) {
      detachResponse.notify(NotificationKey.error);
    } else {
      const responseWithItemDescription = detachResponse.map((action) => ({
        ...action,
        name: getDescription(
          detachSelectedItems.value.find(
            (item) => item[props.primaryKey] === action.name,
          ),
        ),
      }));
      displayActionStatus(
        responseWithItemDescription,
        `${NotificationKey.detachSuccess}.${itemType}`,
        `${NotificationKey.detachError}.${itemType}`,
      );
    }

    clearSelectionAndCloseDetachModal();
    emit("change");
  } else {
    showNotificationWarning(
      t("sectors.modals.confirmDetach.errors.list_empty"),
    );
  }
}

/**
 * Show Action Status
 * @param data
 * @param successMessage
 * @param errorMessage
 */
function displayActionStatus(
  data: SectorItemActionResult[],
  successMessage: string,
  errorMessage: string,
) {
  const { notificationNumber: nbSuccess, msg: success } = getByStatus(
    data,
    true,
  );
  const { notificationNumber: nbError, msg: errors } = getByStatus(data, false);

  if (nbError) {
    showNotificationError(
      t(errorMessage, { sectorName: props.sector.label }, nbError),
      errors,
    );
  }

  if (nbSuccess > 0) {
    showNotificationSuccess(
      t(successMessage, { sectorName: props.sector.label }, nbSuccess),
      success,
    );
  }
}

function getByStatus(
  data: any[],
  success: boolean,
): { msg: string; notificationNumber: number } {
  const notificationsByStatus = data.filter((d) => d.success === success);

  const msg = notificationsByStatus
    .map((d) => d.name)
    .filter(Boolean)
    .map(setDetailMessageAsListItem)
    .join("");

  return {
    msg,
    notificationNumber: notificationsByStatus.length,
  };
}

/**
 * Open Attach modal, fetch items before
 */
async function openAttachModal() {
  toggleAttachModalVisible();
  await fetchItems();
}

/**
 * Open detach modal, accept single or multiple item as arg
 * @param data
 */
function openDetachModal(data: any) {
  detachSelectedItems.value = !data.length ? [data] : data;
  toggleDetachModalVisible();
}
</script>

<template>
  <div class="flex" style="justify-content: flex-end">
    <lxc-button
      v-if="displayActions"
      class="self-center"
      html-type="button"
      type="primary"
      :icon="ILxcPlus"
      :title="t(`sectors.details.buttons.attach.${resource}`)"
      @click="openAttachModal"
    >
      {{ t(`sectors.details.buttons.attach.${resource}`) }}
    </lxc-button>

    <lxc-button
      v-if="displayActions"
      :disabled="detachDisabled"
      class="self-center ml-4"
      html-type="button"
      type="secondary"
      :title="t('sectors.details.buttons.detach')"
      :icon="ILxcArrowUpLeft"
      @click="openDetachModal(selectedItems)"
    >
      {{ t("sectors.details.buttons.detach") }}
    </lxc-button>
  </div>

  <lxc-common-modal
    :show="attachModalVisible"
    class="!w-[70%] !max-w-4xl"
    @close="clearSelectionAndCloseAttachModal"
  >
    <template #header>
      <h3>{{ t(`sectors.modals.attach.${resource}.title`) }}</h3>
    </template>
    <template #body>
      <div>
        <p class="modal-description">
          {{ t(`sectors.modals.attach.${resource}.description`) }}
        </p>
        <lxc-search-bar
          class="search-bar"
          :search-query="filters.get(FilterKey)"
          :search-placeholder="t('label.search')"
          @input-change="setFilter(FilterKey, $event)"
          @clear="onSearchClear"
          @search="search"
        />
        <lxc-container
          :px="0"
          :is-loading="isLoading && !results"
          :error="error"
        >
          <lxc-sectors-details-table
            v-model="attachSelectedItems"
            :primary-key="primaryKey"
            :resource="resource"
            :is-loading="isLoading"
            :display-actions="false"
            force-selection-mode
            :selection="true"
            :items="results"
            :get-description="getDescription"
            @pagination:get-next-page="fetchItems"
          >
            <slot />
          </lxc-sectors-details-table>
        </lxc-container>
      </div>
    </template>
    <template #footer>
      <div class="flex grow shrink-0 justify-end gap-4">
        <lxc-button
          type="secondary"
          html-type="button"
          :title="t('button.cancel')"
          @click="clearSelectionAndCloseAttachModal"
        >
          {{ t("button.cancel") }}
        </lxc-button>

        <lxc-button
          type="primary"
          html-type="button"
          :title="t('button.confirm')"
          @click="onAttachToSector"
        >
          {{ t("button.confirm") }}
        </lxc-button>
      </div>
    </template>
  </lxc-common-modal>

  <lxc-common-modal
    :show="detachModalVisible"
    class="!w-[50%] !max-w-4xl"
    @close="clearSelectionAndCloseDetachModal"
  >
    <template #header>
      <h3>{{ t(`sectors.modals.confirmDetach.${resource}.title`) }}</h3>
    </template>
    <template #body>
      <div>
        <lxc-information-row
          :title="
            t(
              `sectors.modals.confirmDetach.${props.resource}.warning`,
              detachSelectedItems.length,
            )
          "
        />
        <p class="update-description">
          {{
            t(
              `sectors.modals.confirmDetach.${props.resource}.description`,
              {
                sectorLabel: sector.label,
                name: getDescription(detachSelectedItems[0]),
              },
              detachSelectedItems.length,
            )
          }}
        </p>
        <ul v-if="detachSelectedItems.length > 1">
          <li v-for="item in detachSelectedItems" :key="item">
            {{ getDescription(item) }}
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <div class="flex grow shrink-0 justify-end gap-4">
        <lxc-button
          type="secondary"
          html-type="button"
          :title="t('button.cancel')"
          @click="clearSelectionAndCloseDetachModal"
        >
          {{ t("button.cancel") }}
        </lxc-button>

        <lxc-button
          type="primary"
          html-type="button"
          :title="t('button.confirm')"
          @click="detachFromSector"
        >
          {{ t("button.confirm") }}
        </lxc-button>
      </div>
    </template>
  </lxc-common-modal>
</template>
