import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/composables/useFetchPage";
import { SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import ApplicationMgtService from "~/services/applicationMgt.service";
import {
  ACL_ROLES,
  Filters,
  type FiltersSelection,
  FiltersType,
} from "~/types";

export function useAppInterface() {
  const service = ApplicationMgtService.getAppInterfaces.bind(
    ApplicationMgtService,
  );
  const { can } = useAcl();

  function canManageThirdPartyApps() {
    return can(ACL_ROLES.DVTM_APPM_THIRDPARTY_ADM);
  }

  function canViewThirdPartyApps() {
    return can(ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW);
  }

  function canManageMobileApp() {
    return can(ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM);
  }

  function canViewMobileApp() {
    return can(ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW);
  }

  // To be completed with other filters when they are defined
  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=like=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    SearchMode.FILTER_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: false,
  });

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
    canManageThirdPartyApps,
    canViewThirdPartyApps,
    canViewMobileApp,
    canManageMobileApp,
  };
}
