<script setup lang="ts">
import {
  CampaignOMStatus,
  CampaignOperationMgrI,
  FirmwareI,
  OperationModelI,
} from "@lxc/app-device-types";
import { CampaignFunnelStep1 } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep1.vue";
import { CampaignFunnelStep2 } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep2.vue";
import ILxcArrowLeft from "~icons/lxc/arrow-left";

const props = defineProps<{
  campaign?: CampaignOperationMgrI;
  show?: boolean;
}>();

const { t } = useI18n();

defineEmits(["update:show"]);

const step = ref(1);

// Possibility to edit the campaign on:
//   - creation
//   - or modification if the campaign is scheduled only
const canEditCampaign = computed(
  () => !props.campaign || props.campaign.status === CampaignOMStatus.SCHEDULED,
);

const campaignOperationMgrFunnelStep1: Ref<HTMLElement | undefined> = ref();
const campaignOperationMgrFunnelStep2: Ref<HTMLElement | undefined> = ref();

const campaignFunnelStep1: Ref<CampaignFunnelStep1> = ref({
  name: undefined,
  operationModelType: undefined,
  firmwareUuid: undefined,
});

const campaignFunnelStep2: Ref<CampaignFunnelStep2> = ref({
  dtwins: [],
});

const selectedFirmware: Ref<FirmwareI | undefined> = ref();

// reset campaign funnel step 1 from campaign
function resetCampaignFunnelStep1() {
  campaignFunnelStep1.value.name = props.campaign?.name;
  campaignFunnelStep1.value.operationModelType = undefined; // set to undefined, it will be set after all operation models loaded
  campaignFunnelStep1.value.firmwareUuid =
    props.campaign?.operationParametersList[0]?.customInputParameters?.firmwareUid;
}

// reset the dtwins list when changing the operation model type
watch(
  () => campaignFunnelStep1.value.operationModelType,
  () => {
    campaignFunnelStep2.value.dtwins = [];
  },
);

const operationModels: Ref<OperationModelI[]> = ref([]);

// set campaign type from campaign and all the operation models
function onAllOperationModelsLoaded(allOperationModels: OperationModelI[]) {
  operationModels.value = allOperationModels;

  if (!campaignFunnelStep1.value.operationModelType) {
    // only inspect the first operation in order to retrieve the settings attributes
    // because only one operation model type is possible for now
    const operationModel = allOperationModels.find(
      (operationModel) =>
        operationModel.uid ===
        props.campaign?.operationParametersList[0]?.operationModelUid,
    );
    campaignFunnelStep1.value.operationModelType = operationModel
      ? operationModel.type
      : undefined;
  }
}

async function next() {
  if (step.value === 1) {
    const isValid = await campaignOperationMgrFunnelStep1.value
      .validate()
      .catch(() => false);
    if (isValid) {
      step.value++;
    }
  } else if (step.value === 2) {
    const isValid = await campaignOperationMgrFunnelStep2.value
      .validate()
      .catch(() => false);
    if (isValid) {
      step.value++;
    }
  }
}

// when showing the funnel:
// - reset the step to first one
// - reset campaign settings
watch(
  () => props.show,
  () => {
    step.value = 1;
    resetCampaignFunnelStep1();
  },
);
watch(() => props.campaign, resetCampaignFunnelStep1);
</script>

<template>
  <lxc-common-modal
    :show="show"
    is-full-screen
    with-steps
    :step="step"
    :number-of-steps="3"
    @close="$emit('update:show', false)"
  >
    <template #header>
      <h2 v-show="step === 1" class="text-gray-900">
        {{
          campaign
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.newCampaign")
        }}
      </h2>
      <h2 v-show="step === 2" class="text-gray-900">
        {{ t("campaign.funnel.fleetSelection.title") }}
      </h2>
      <h2 v-show="step === 3" class="text-gray-900">
        {{ t("campaign.funnel.planification") }}
      </h2>
      <lxc-button
        v-if="step > 1"
        :title="t('campaign.funnel.previous')"
        type="tertiary"
        class="w-11 h-11 absolute top-1/2 -translate-y-1/2 left-8"
        @click="step--"
      >
        <ILxcArrowLeft class="text-gray-700" height="20" width="20" />
      </lxc-button>
    </template>

    <template #body>
      <div v-show="step === 1" class="mt-7">
        <lxc-campaign-operation-mgr-funnel-step1
          ref="campaignOperationMgrFunnelStep1"
          v-model="campaignFunnelStep1"
          :can-edit="canEditCampaign"
          @select-firmware="selectedFirmware = $event"
          @all-operation-models-loaded="onAllOperationModelsLoaded"
        />
      </div>
      <div v-if="step === 2" class="mt-3">
        <lxc-campaign-operation-mgr-funnel-step2
          ref="campaignOperationMgrFunnelStep2"
          v-model="campaignFunnelStep2"
          :operation-models="operationModels"
          :operation-model-type="campaignFunnelStep1.operationModelType"
          :firmware="selectedFirmware"
        />
      </div>
      <div v-show="step === 3" class="mt-7">
        <lxc-campaign-operation-mgr-funnel-step3 />
      </div>

      <!-- TODO: temporary display logs, to be removed -->
      <div class="max-w-screen-sm">
        Campaign settings:
        <pre>{{ campaignFunnelStep1 }}</pre>
        Selected firmware:
        <pre>{{ selectedFirmware }}</pre>
        Selected dtwins:
        <pre>{{ campaignFunnelStep2 }}</pre>
      </div>
    </template>

    <template #footer>
      <lxc-button
        v-if="step < 3"
        :title="t('campaign.funnel.next')"
        type="primary"
        @click="next"
      >
        {{ t("campaign.funnel.next") }}
      </lxc-button>
      <lxc-button
        v-if="step === 3"
        :title="
          campaign
            ? t('campaign.funnel.updateCampaign')
            : t('campaign.funnel.publish')
        "
        type="primary"
      >
        {{
          campaign
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.publish")
        }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>
