<script lang="ts" setup>
export interface LxcDtwinsFirmwareInformationI {
  readonly firmwareVersion: string;
  readonly firmwareUpdateSource: string;
  readonly latestFirmwareUpdate: string;
}

defineProps<{
  modelValue: LxcDtwinsFirmwareInformationI;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="flex-col">
    <h3>
      {{ t("dtwins.form.firmware.title") }}
    </h3>
    <div class="flex">
      <div class="w-1/3">
        <lxc-form-item
          :label="t('dtwins.form.firmware.state.attributes.firmwareVersion')"
        >
          <lxc-input
            :value="modelValue.firmwareVersion"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item
          :label="
            t(
              'dtwins.form.firmware.information.attributes.latestFirmwareUpdate',
            )
          "
        >
          <lxc-input
            :value="modelValue.latestFirmwareUpdate"
            type="text"
            read-only
          />
        </lxc-form-item>
      </div>
      <div class="w-1/3">
        <lxc-form-item
          :label="
            t(
              'dtwins.form.firmware.information.attributes.firmwareUpdateSource',
            )
          "
        >
          <lxc-input
            :value="modelValue.firmwareUpdateSource"
            type="text"
            read-only
          />
        </lxc-form-item>
      </div>
    </div>
  </div>
</template>
