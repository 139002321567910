<script lang="ts" setup>
import type { SectorI } from "@lxc/app-device-types";
import { useAcl } from "vue-simple-acl";

const acl = useAcl();
const router = useRouter();
const { t } = useI18n();
const props = defineProps<{
  activePageIndex: int;
  tabsItems: TabItem[];
  sector: SectorI;
}>();

async function onTabSelected(index: number) {
  await router.push(props.tabsItems[index].to);
}

const displayableTabItem = computed(() => {
  return props.tabsItems.filter((tab) => acl.can(tab.can));
});
</script>

<template>
  <lxc-tabs
    class="px-8"
    :initial-selected-tab-index="activePageIndex"
    disable-tab-pane-click
    @on-tab-selected="onTabSelected"
  >
    <template v-for="(tabItem, index) in displayableTabItem" :key="index">
      <lxc-tab-pane :label="t(tabItem.label)" :index="index.toString()">
        <router-view v-if="index === activePageIndex" :sector="sector" />
      </lxc-tab-pane>
    </template>
  </lxc-tabs>
</template>
