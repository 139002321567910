import { DisplayPermission } from "@lxc/app-device-types";
import { createAcl, defineAclRules } from "vue-simple-acl";
import "~/assets/styles/main/main.scss";
import "~/assets/styles/vendors/element-plus.scss";
import { router } from "~/plugins/router";
import { useUserSession } from "~/stores/useUserSession";
import { ACL_ROLES } from "~/types";

const rolesFromPermission: {
  role: ACL_ROLES;
  permissions: DisplayPermission[];
}[] = [
  { role: ACL_ROLES.NO_CONTROL, permissions: [] },
  {
    role: ACL_ROLES.DISPLAY_DEVICES,
    permissions: [DisplayPermission.DVM_VIEW_DEV],
  },
  {
    role: ACL_ROLES.UPDATE_DEVICES,
    permissions: [DisplayPermission.DVM_UPDATE_DEV],
  },
  {
    role: ACL_ROLES.CONFIGURE_PKI,
    permissions: [DisplayPermission.DVM_PKI_MANAGEMENT],
  },
  {
    role: ACL_ROLES.DISPLAY_USERS,
    permissions: [DisplayPermission.DVM_USER_MANAGEMENT],
  },
  {
    role: ACL_ROLES.DISPLAY_APPLICATIONS,
    permissions: [DisplayPermission.DVM_VIEW_APP],
  },
  {
    role: ACL_ROLES.UPDATE_APPLICATIONS,
    permissions: [DisplayPermission.DVM_UPDATE_APP],
  },
  {
    role: ACL_ROLES.DISPLAY_SECTORS,
    permissions: [DisplayPermission.DVM_SECT_VIEW],
  },
  {
    role: ACL_ROLES.MANAGE_SECTORS,
    permissions: [DisplayPermission.DVM_SECT_MANAGEMENT],
  },
  {
    role: ACL_ROLES.MANAGE_TRUSTSTORE,
    permissions: [DisplayPermission.DVM_TRST_MANAGEMENT],
  },
  {
    role: ACL_ROLES.MANAGE_CERTIFICATES,
    permissions: [DisplayPermission.DVM_CERT_MANAGEMENT],
  },
  {
    role: ACL_ROLES.DVT_CACRT_VIEW,
    permissions: [DisplayPermission.CACRT_READ, DisplayPermission.CACRT_NOTIFS],
  },
  {
    role: ACL_ROLES.DVT_CACRT_ADM,
    permissions: [DisplayPermission.CACRT_ADM, DisplayPermission.CACRT_NOTIFS],
  },
  {
    role: ACL_ROLES.DISPLAY_FIRMWARES,
    permissions: [DisplayPermission.DVM_VIEW_FIRM],
  },
  {
    role: ACL_ROLES.MANAGE_FIRMWARES,
    permissions: [DisplayPermission.DVM_FIRM_MANAGEMENT],
  },
  {
    role: ACL_ROLES.CONFIGURE_NOTIFICATION,
    permissions: [DisplayPermission.DVM_ALER_MANAGEMENT],
  },
  {
    role: ACL_ROLES.ASK_MANUAL_CERTIFICATE_RENEWAL_APP,
    permissions: [DisplayPermission.DVM_CRTRENEW_APP_MANAGEMENT],
  },
  {
    role: ACL_ROLES.ASK_MANUAL_CERTIFICATE_RENEWAL_DEVICE,
    permissions: [DisplayPermission.DVM_CRTRENEW_DEV_MANAGEMENT],
  },
  {
    role: ACL_ROLES.ASK_MANUAL_FIRMWARE_UPDATE_DEVICE,
    permissions: [DisplayPermission.DVM_UPDATE_FIRM_DEV_MANAGEMENT],
  },
  {
    role: ACL_ROLES.DISPLAY_CAMPAIGNS,
    permissions: [DisplayPermission.CAMP_VIEW],
  },
  {
    role: ACL_ROLES.MANAGE_CAMPAIGNS,
    permissions: [DisplayPermission.CAMP_MANAGEMENT],
  },
  {
    role: ACL_ROLES.USR_CERTIFICATES,
    permissions: [DisplayPermission.USR_CERTIFICATES],
  },
  {
    role: ACL_ROLES.CYBER_LOGS_VIEW,
    permissions: [DisplayPermission.CYBER_LOGS_VIEW],
  },
  {
    role: ACL_ROLES.DEVICE_LOGS_VIEW,
    permissions: [DisplayPermission.DEVICE_LOGS_VIEW],
  },
  {
    role: ACL_ROLES.DEVICE_FLEET_LOGS_VIEW,
    permissions: [DisplayPermission.DEVICE_FLEET_LOGS_VIEW],
  },
  {
    role: ACL_ROLES.DVTM_DVT_ADM,
    permissions: [
      DisplayPermission.DVM_VIEW_DEV,
      DisplayPermission.DVM_ADM_DEV,
    ],
  },
  {
    role: ACL_ROLES.SYSTEM_LOGS_VIEW,
    permissions: [DisplayPermission.SYSTEM_LOGS_VIEW],
  },
  {
    role: ACL_ROLES.BSCOMMON_ERRDESC_LIST,
    permissions: [DisplayPermission.BSCOMMON_ERRDESC_LIST],
  },
  {
    role: ACL_ROLES.LICENSEM_UPLOAD,
    permissions: [DisplayPermission.LICENSEM_UPLOAD],
  },
  {
    role: ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW,
    permissions: [DisplayPermission.APPMGR_READ_THIRDPARTYAPP],
  },
  {
    role: ACL_ROLES.DVTM_APPM_THIRDPARTY_ADM,
    permissions: [DisplayPermission.APPMGR_ADM_THIRDPARTYAPP],
  },
  {
    role: ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW,
    permissions: [DisplayPermission.APPMGR_READ_MOBILEAPP],
  },
  {
    role: ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM,
    permissions: [DisplayPermission.APPMGR_ADM_MOBILEAPP],
  },
];

export default {
  install: (app: any) => {
    const user = computed(() => useUserSession().$state.userSession);
    const rules = () =>
      defineAclRules<typeof user.value>((setRule) =>
        rolesFromPermission.forEach((t) => {
          setRule(t.role, (user) =>
            t.permissions.every((permission) =>
              (user?.permissions ?? []).includes(permission),
            ),
          );
        }),
      );

    app.use(
      createAcl({
        user,
        rules,
        router,
      }),
    );
  },
};
