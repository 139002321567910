import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/composables/useFetchPage";
import { SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import MobileInterfaceService from "~/services/mobileInterface.service";
import { ACL_ROLES, FiltersType } from "~/types";

export function useMobileInterface() {
  const service = MobileInterfaceService.findWithPagination.bind(
    MobileInterfaceService,
  );
  const { can } = useAcl();

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.PIPE_SEPARATOR,
    new Map(),
    SearchMode.FILTER_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: false,
  });

  function canViewMobileApplication() {
    return can(ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW);
  }

  function canGenerateQRCode() {
    return canViewMobileApplication() && can(ACL_ROLES.DISPLAY_SECTORS);
  }

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
    canViewMobileApplication,
    canGenerateQRCode,
  };
}
