<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import { DeviceModelConfigI } from "@lxc/app-device-types";
import type {
  DeviceTypeI,
  MobileInterfaceI,
} from "@lxc/app-device-types/dist/interfaces/mobile.interface";
import { StatusCodes } from "http-status-codes";
import { useAcl } from "vue-simple-acl";
import { PATHS } from "~/constants/paths";
import dtwinsService from "~/services/dtwins.service";
import mobileInterfaceService from "~/services/mobileInterface.service";
import { useUserSession } from "~/stores/useUserSession";
import { ACL_ROLES } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  sideCanvasShown: boolean;
  selectedInterfaceClientId?: string;
}>();

const emit = defineEmits([
  "update:sideCanvasShown",
  "interfaceCreated",
  "interfaceUpdated",
  "clearInterfaceClientId",
]);

const acl = useAcl();
const { t } = useI18n();
const router = useRouter();
const serialize = useSerialize();
const { userSession } = useUserSession();

const canManageMobileApp = computed(() =>
  acl.can(ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM),
);

const error: Ref<LxcError | undefined | null> = ref();
const isLoading: Ref<boolean> = ref(false);
const isEditingTypes: Ref<boolean> = ref(false);

const deviceTypes: Ref<DeviceTypeI[]> = ref([]);
const deviceModels: Ref<DeviceTypeI[]> = ref([]);

const getAllDeviceTypesForApplication = (
  applicationDeviceTypes: DeviceTypeI[],
) => {
  const allDeviceTypes: DeviceTypeI[] = [];
  for (const applicationDeviceType of applicationDeviceTypes) {
    allDeviceTypes.push(applicationDeviceType);
  }
  for (const deviceType of deviceTypes.value) {
    if (
      allDeviceTypes.filter((value) => value.uid === deviceType.uid).length ===
      0
    ) {
      allDeviceTypes.push(deviceType);
    }
  }
  return allDeviceTypes.sort((s1, s2) =>
    s1.friendlyName > s2.friendlyName ? 1 : -1,
  );
};

const fetchDeviceTypesAndModels = async () => {
  const response = await dtwinsService.getDtwinTypes();
  if (LxcError.check(response)) {
    response.notify(NotificationKey.error);
  } else {
    deviceTypes.value = response;
  }

  let fetchedDeviceModels: DeviceModelConfigI[] = [];
  for (const dtwinType of deviceTypes.value) {
    const deviceModel = await fetchDeviceModel({ uid: dtwinType.uid });
    if (deviceModel !== undefined) {
      fetchedDeviceModels.push(deviceModel);
    }
  }
  deviceModels.value = fetchedDeviceModels;
};

const fetchDeviceModel = async ({ uid }: { uid: string }) => {
  const response = await dtwinsService.getDeviceModelConfig(uid);
  if (!LxcError.check(response)) {
    return response;
  }
  return undefined;
};

const findDeviceModelMobileEndpoints = ({ uid }: { uid: string }) => {
  return (
    toRaw(deviceModels.value).find((model) => model.deviceModelUid === uid)
      ?.internal?.mobileEndpoints ?? []
  );
};

const formSideCanvasShown = computed({
  get: () => {
    return props.sideCanvasShown;
  },
  set: (shown: boolean) => {
    emit("update:sideCanvasShown", shown);
  },
});

const isInterfaceUpdate: ComputedRef<boolean> = computed(() => {
  return !!props.selectedInterfaceClientId;
});
const sidePanelHeader: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value
    ? t("applicationMgt.updateInterface")
    : t("applicationMgt.newInterface");
});
const closeButtonLabel: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value ? t("button.cancel") : t("button.close");
});
const validateButtonLabel: ComputedRef<string> = computed(() => {
  return isInterfaceUpdate.value
    ? t("button.validate")
    : t("applicationMgt.generateNewInterface");
});

const interfaceFormRef: Ref<HTMLFormElement | undefined | null> = ref();
const interfaceForm = reactive<MobileInterfaceI>({
  organization: {
    code: userSession?.organisation.code,
    name: userSession?.organisation.name,
  },
  name: "",
  description: "",
  deviceTypes: [],
});

let initInterfaceFormStringified: string = serialize(interfaceForm);
const edited: ComputedRef<boolean> = computed(() => {
  const stringifiedForm = serialize(interfaceForm);
  return stringifiedForm !== initInterfaceFormStringified;
});
const isSaving: Ref<boolean> = ref(false);

async function getInterfaceDetails() {
  if (props.selectedInterfaceClientId) {
    isLoading.value = true;
    const response = await mobileInterfaceService.findById(
      props.selectedInterfaceClientId,
    );
    Object.assign(interfaceForm, {
      organization: {
        code: response.organization?.code,
        name: response.organization?.name,
      },
      name: response.name,
      description: response.description,
      deviceTypes: response.deviceTypes,
    });
    isLoading.value = false;
  } else {
    Object.assign(interfaceForm, {
      organization: {
        code: userSession?.organisation.code,
        name: userSession?.organisation.name,
      },
      name: "",
      description: "",
      deviceTypes: [],
    });
  }

  initInterfaceFormStringified = serialize(interfaceForm);
}

const isInterfaceNameValid = (rule: any, value: any, callback: any) => {
  const validator = /^[a-zA-Z0-9_]+$/;
  if (!validator.test(value)) {
    return callback(
      new Error(t("applicationMgt.tabs.mobileApp.form.validation.nameFormat")),
    );
  }
  return callback();
};

const interfaceFormRules = {
  name: [
    {
      required: true,
      message: t("applicationMgt.tabs.mobileApp.form.validation.name"),
      whitespace: true,
      trigger: "blur",
    },
    { validator: isInterfaceNameValid, trigger: "blur" },
  ],
};

async function isFormValid() {
  return await interfaceFormRef.value?.validate().catch((_: any) => false);
}

async function onSubmit() {
  if ((await isFormValid()) && userSession) {
    isSaving.value = true;
    if (isInterfaceUpdate && props.selectedInterfaceClientId) {
      const response = await mobileInterfaceService.update(
        props.selectedInterfaceClientId,
        interfaceForm,
      );
      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError);
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
        emit("interfaceUpdated");
        onClose();
      }
    } else {
      const response = await mobileInterfaceService.create(interfaceForm);
      if (LxcError.check(response)) {
        switch (response.status) {
          case StatusCodes.CONFLICT:
            showNotificationError(
              t(NotificationKey.saveError),
              t("applicationMgt.tabs.mobileApp.form.validation.uniqueName"),
            );
            break;
          default:
            response.notify(NotificationKey.saveError);
            break;
        }
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
        emit("interfaceCreated");
        onClose();
      }
    }
    isSaving.value = false;
  }
}

function onClose() {
  formSideCanvasShown.value = false;
  isEditingTypes.value = false;
  emit("clearInterfaceClientId");
  setTimeout(interfaceFormRef.value?.clearValidate, 0);
  error.value = undefined;
  router.push(`${PATHS.PARAMETERS_APPLICATIONS}`);
}

watch(() => props.sideCanvasShown, getInterfaceDetails);

onMounted(fetchDeviceTypesAndModels);
</script>
<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    :header="sidePanelHeader"
    :close-tooltip="t('filters.close')"
    @discard="onClose"
  >
    <lxc-container
      :key="props.selectedInterfaceClientId"
      :px="0"
      :py="0"
      :error="error"
      :is-loading="isLoading"
    >
      <h6 class="mb-4">
        {{ t("applicationMgt.tabs.mobileApp.form.information.title") }}
      </h6>
      <lxc-form
        ref="interfaceFormRef"
        :model="interfaceForm"
        :rules="interfaceFormRules"
        class="flex flex-col gap-2"
      >
        <lxc-form-item
          :label="t('applicationMgt.tabs.mobileApp.form.information.name')"
          prop="name"
        >
          <lxc-input
            v-model="interfaceForm.name"
            type="text"
            :disabled="isInterfaceUpdate"
          />
        </lxc-form-item>
        <lxc-form-item
          :label="
            t('applicationMgt.tabs.mobileApp.form.information.description')
          "
          prop="description"
        >
          <lxc-text-area
            v-model="interfaceForm.description"
            display-counter
            :max-length="500"
            :disabled="!canManageMobileApp"
          />
        </lxc-form-item>
        <h6 class="mb-4">
          {{ t("applicationMgt.tabs.mobileApp.form.deviceTypes.title") }}
        </h6>
        <lxc-form-item
          :label="t('applicationMgt.tabs.mobileApp.form.deviceTypes.label')"
          prop="deviceTypes"
        >
          <lxc-select-table
            v-model="interfaceForm.deviceTypes"
            v-model:is-editing="isEditingTypes"
            :data="
              getAllDeviceTypesForApplication(interfaceForm.deviceTypes ?? [])
            "
            prop="friendlyName"
            :disabled="!canManageMobileApp"
            :with-edition="true"
            :add-button-label="
              t('applicationMgt.tabs.mobileApp.form.deviceTypes.add')
            "
            :save-button-label="
              t('applicationMgt.tabs.mobileApp.form.deviceTypes.save')
            "
            :cancel-button-label="
              t('applicationMgt.tabs.mobileApp.form.deviceTypes.cancel')
            "
            :no-label="
              t('applicationMgt.tabs.mobileApp.form.deviceTypes.noLabel')
            "
          >
            <template
              #[index]
              v-for="(deviceType, index) in getAllDeviceTypesForApplication(
                interfaceForm.deviceTypes ?? [],
              )"
              :key="`${deviceType}-${index}`"
            >
              <div
                v-for="model in findDeviceModelMobileEndpoints({
                  uid: deviceType.uid,
                })"
                :key="model"
                class="mt-3 mx-1"
              >
                <lxc-input
                  :model-value="model.url"
                  type="text"
                  class="h-11 text-sm"
                  disabled
                >
                  <template #prefix>
                    <div class="w-16">
                      <span
                        class="text-gray-500 text-sm whitespace-nowrap truncate"
                      >
                        {{ model.type }}
                      </span>
                    </div>
                  </template>
                </lxc-input>
              </div>
            </template>
          </lxc-select-table>
        </lxc-form-item>
      </lxc-form>
    </lxc-container>
    <template #footer>
      <div class="flex gap-4">
        <lxc-button
          html-type="button"
          type="tertiary"
          :title="closeButtonLabel"
          @click="onClose"
        >
          {{ closeButtonLabel }}
        </lxc-button>
        <lxc-button
          html-type="button"
          type="primary"
          :icon="isInterfaceUpdate ? undefined : ILxcPlus"
          :title="validateButtonLabel"
          class="flex-1"
          :disabled="
            !canManageMobileApp || !edited || isSaving || isEditingTypes
          "
          @click="onSubmit"
        >
          {{ validateButtonLabel }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
