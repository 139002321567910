<script lang="ts" setup>
import type { DeviceModelConfigurationI } from "@lxc/app-device-types";
import { ConfigParam, S4Types } from "~/types/certConfiguration";

defineProps<{
  s4Device: DeviceModelConfigurationI;
}>();
defineEmits(["change"]);

const { t } = useI18n();
</script>

<template>
  <h3>{{ t("table.header.equipments") }}</h3>
  <section class="config-row">
    <lxc-certificate-renewal
      :delay="s4Device.delay"
      :polling="s4Device.polling"
      :is-autoactivated="s4Device.autoActivation"
      :title="t('parameters.certificates.s4Device')"
      @select-change="$emit('change', $event, S4Types.DVC, ConfigParam.DELAY)"
      @polling-change="
        $emit('change', $event, S4Types.DVC, ConfigParam.POLLING)
      "
      @auto-activation-change="
        $emit('change', $event, S4Types.DVC, ConfigParam.AUTO_ACTIVATION)
      "
    />
  </section>
</template>
