import { ElNotification } from "element-plus";

const DO_NOT_CLOSE_DURATION = 0;

export enum NotificationKey {
  success = "notification.success.default",
  error = "notification.error.default",
  certificateGenerationSuccess = "notification.success.certificate.generation",
  cancel = "notification.cancellation.cancel",
  saveSuccess = "notification.success.save",
  saveError = "notification.error.save",
  uploadError = "notification.error.upload",
  removeSuccess = "notification.success.remove",
  detachSuccess = "notification.success.detach",
  attachSuccess = "notification.success.attach",
  removeError = "notification.error.remove",
  detachError = "notification.error.detach",
  attachError = "notification.error.attach",
  startDateError = "notification.error.startDate",
}

export function showNotificationError(
  message: string,
  info?: string,
  doNotClose = true,
): void {
  ElNotification({
    title: message,
    message: info,
    dangerouslyUseHTMLString: true,
    type: "error",
    duration: doNotClose ? DO_NOT_CLOSE_DURATION : undefined,
  });
}

export function showNotificationWarning(
  message: string,
  info?: string,
  doNotClose = true,
): void {
  ElNotification({
    title: message,
    message: info,
    type: "warning",
    duration: doNotClose ? DO_NOT_CLOSE_DURATION : undefined,
  });
}

export function showNotificationSuccess(message: string, info?: string): void {
  ElNotification({
    title: message,
    message: info,
    dangerouslyUseHTMLString: true,
    type: "success",
  });
}

export function setDetailMessageAsListItem(
  message: string | false,
  idx: number,
  details: (string | false)[],
): string {
  return `${idx === 0 ? "<ul>" : ""}<li>${message}</li>${idx === details.length ? "</ul>" : ""}`;
}
