<script setup lang="ts">
defineEmits(["click"]);

const { t } = useI18n();
</script>

<template>
  <a
    class="font-medium whitespace-nowrap ml-3"
    href="#"
    @click="$emit('click', $event)"
  >
    {{ t("filters.resetFilter") }}
  </a>
</template>
