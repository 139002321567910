import RestService from "./rest.service";
import type { AboutI } from "@lxc/app-device-types";

class AboutService extends RestService {
  private BASE_URL = "/about";

  /**
   * Retrieve about with version
   */
  getAbout(): Promise<AboutI> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new AboutService();
