import type { AboutI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import { FeatureToggleComponent as featureToggle } from "vue-feature-toggle";
import AboutService from "~/services/about.service";
import { APP_FEATURES } from "~/types/appFeatures";

export const useConfigStore = defineStore("configStore", () => {
  const state = reactive<AboutI>({
    defaultSectorCode: null,
    technicalVersion: null,
    marketingVersion: null,
    isModeAD: false,
    isLoraActivated: false,
    aboutGrafana: null,
    isFeatureFotaEnabled: false,
    isFeatureApplicationManagementEnabled: false,
    isFeatureDataManagementEnabled: false,
    isFeatureUserCertificateEnabled: false,
    isFeatureDtwinsEnabled: false,
    isFeatureCampaignDtwinsEnabled: false,
    isMobileAppOfflineMode: false,
  });

  async function getConfig() {
    const response = await AboutService.getAbout();
    Object.assign(state, response);

    featureToggle.visibility(APP_FEATURES.FOTA, state.isFeatureFotaEnabled);
    featureToggle.visibility(
      APP_FEATURES.APPLICATION_MANAGEMENT,
      state.isFeatureApplicationManagementEnabled,
    );
    featureToggle.visibility(
      APP_FEATURES.LOGS,
      state.isFeatureDataManagementEnabled,
    );
    featureToggle.visibility(
      APP_FEATURES.USER_CERTIFICATE,
      state.isFeatureUserCertificateEnabled,
    );
    featureToggle.visibility(APP_FEATURES.DTWINS, state.isFeatureDtwinsEnabled);
    featureToggle.visibility(
      APP_FEATURES.CAMPAIGN_DTWINS,
      state.isFeatureCampaignDtwinsEnabled,
    );
  }

  return {
    ...toRefs(state),
    getConfig,
    featureToggle,
  };
});
