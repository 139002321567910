<script setup lang="ts">
import type { ConnectivityStatusParameters } from "@lxc/app-device-types";
import { DtwinConnectivityStatus } from "@lxc/app-device-types";
import { EMPTY_TOOLTIP } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import {
  RoundMode,
  Unit,
  computeTooltipDurationValue,
  getUnit,
} from "~/components/dtwins/dtwinsList/connectivity/LxcDtwinsListConnectivityStatus.helper";
import ILxStepError from "~icons/lxc-business/step-error";
import ILxStepSuccess from "~icons/lxc-business/step-success";
import ILxStepWarning from "~icons/lxc-business/step-warning";

const { t } = useI18n();

const props = defineProps<{
  connectivityStatus?: DtwinConnectivityStatus | undefined;
  connectivityStatusParameters?: ConnectivityStatusParameters | undefined;
}>();

/**
 * Function used to extract the tooltip to be displayed in the tooltip.
 * @param durationInSeconds Duration in seconds to be analyzed.
 * @param mode Duration computation mode. It is used to the right rounded duration representation.
 */
function getDuration(
  durationInSeconds: number,
  mode: RoundMode,
): string | null {
  if (durationInSeconds != null && durationInSeconds !== 0) {
    const unit = getUnit(durationInSeconds);
    if (unit === Unit.SECONDS) {
      return `${t("dtwins.list.attributes.connectivity.duration.seconds", { count: durationInSeconds })}`;
    } else if (unit === Unit.MINUTES) {
      return `${t("dtwins.list.attributes.connectivity.duration.minutes", { count: computeTooltipDurationValue(durationInSeconds, Unit.MINUTES, mode) })}`;
    } else {
      return `${t("dtwins.list.attributes.connectivity.duration.hours", { count: computeTooltipDurationValue(durationInSeconds, Unit.HOURS, mode) })}`;
    }
  }
  return null;
}

const tooltip: ComputedRef<string> = computed(() => {
  let tooltip = EMPTY_TOOLTIP;
  if (props.connectivityStatus !== undefined) {
    switch (props.connectivityStatus) {
      case DtwinConnectivityStatus.GOOD:
        if (
          props.connectivityStatusParameters?.durationLimitBeforeWarn !==
          undefined
        ) {
          const duration: string | null = getDuration(
            props.connectivityStatusParameters.durationLimitBeforeWarn,
            RoundMode.ROUND_UP,
          );
          if (duration != null) {
            tooltip = `${t("dtwins.list.attributes.connectivity.connected.lessThan", { duration })}`;
          }
        }
        break;
      case DtwinConnectivityStatus.WARN:
        if (
          props.connectivityStatusParameters?.durationLimitBeforeWarn !==
          undefined
        ) {
          const duration: string | null = getDuration(
            props.connectivityStatusParameters.durationLimitBeforeWarn,
            RoundMode.ROUND_DOWN,
          );
          if (duration != null) {
            tooltip = `${t("dtwins.list.attributes.connectivity.connected.greaterThan", { duration })}`;
          }
        }
        break;
      case DtwinConnectivityStatus.ERROR:
        if (
          props.connectivityStatusParameters?.durationLimitBeforeError !==
          undefined
        ) {
          const duration: string | null = getDuration(
            props.connectivityStatusParameters.durationLimitBeforeError,
            RoundMode.ROUND_DOWN,
          );
          if (duration != null) {
            tooltip = `${t("dtwins.list.attributes.connectivity.connected.greaterThan", { duration })}`;
          }
        }
        break;
      case DtwinConnectivityStatus.UNKNOWN:
      default:
      // Default case, no icon is displayed.
    }
  }
  return tooltip;
});
</script>

<template>
  <span :title="tooltip" class="block mx-auto w-fit">
    <ILxStepSuccess v-if="connectivityStatus == DtwinConnectivityStatus.GOOD" />
    <ILxStepWarning v-if="connectivityStatus == DtwinConnectivityStatus.WARN" />
    <ILxStepError v-if="connectivityStatus == DtwinConnectivityStatus.ERROR" />
  </span>
</template>
