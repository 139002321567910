<script setup lang="ts">
import ILxcPlus from "~icons/lxc/plus";

defineProps<{
  closeButtonLabel: string;
  validateButtonLabel: string;
  disabledValidateButton?: boolean;
  isLoading?: boolean;
}>();
defineEmits(["close", "validate"]);

const { t } = useI18n();
</script>
<template>
  <div
    class="grid grid-cols-[max-content_auto] gap-4 p-4 bg-white border-t border-gray-300"
  >
    <lxc-button
      type="secondary"
      :title="closeButtonLabel"
      @click="$emit('close')"
    >
      {{ t("button.close") }}
    </lxc-button>
    <lxc-button
      :title="validateButtonLabel"
      :icon="ILxcPlus"
      :disabled="disabledValidateButton"
      :is-loading="isLoading"
      @click="$emit('validate')"
    >
      {{ validateButtonLabel }}
    </lxc-button>
  </div>
</template>
