<script lang="ts" setup>
import { type FirmwareI, FirmwareStatus } from "@lxc/app-device-types";
import { useDeactivateFirmware } from "~/composables/useFirmware";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightSlash from "~icons/lxc-light/slash";

const props = defineProps<{
  firmware: FirmwareI;
}>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const {
  deactivateConfirmDialogVisible,
  deactivateFirmware,
  deactivateError,
  canDeactivateFirmware,
  isDeactivateLoading,
  toggleDeactivateConfirmVisible,
} = useDeactivateFirmware();

const buttonVisible = computed<boolean>(
  () =>
    canDeactivateFirmware.value &&
    props.firmware?.status === FirmwareStatus.ACTIVATED,
);

const onDeactivateFirmware = async () => {
  await deactivateFirmware(props.firmware);

  if (LxcError.check(deactivateError.value)) {
    (deactivateError.value as LxcError).notify(NotificationKey.saveError);
  } else {
    const newFirmware = { ...props.firmware };
    newFirmware.status = FirmwareStatus.DEACTIVATED;
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    emit("change", newFirmware);
  }
};

const confirmDeactivateFirmware = async (event: MouseEvent) => {
  event?.preventDefault();
  event?.stopPropagation();

  if (props.firmware.uuid) {
    toggleDeactivateConfirmVisible();
  }
};
</script>

<template>
  <lxc-button
    v-if="buttonVisible"
    html-type="button"
    type="borderless"
    :disabled="isDeactivateLoading"
    :icon="!isDeactivateLoading ? ILxcLightSlash : undefined"
    :title="t('firmware.actions.deactivate.label')"
    class="invisible"
    @click.prevent="confirmDeactivateFirmware"
  >
    <lxc-loader v-if="isDeactivateLoading" />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="deactivateConfirmDialogVisible"
    :title="t('firmware.actions.deactivate.label')"
    :description="
      t('firmware.actions.deactivate.description', {
        name: props.firmware.name,
      })
    "
    @cancel="toggleDeactivateConfirmVisible"
    @confirm="onDeactivateFirmware"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
