<script setup lang="ts">
import type { PeriodI } from "@lxc/app-device-common";
import type { AsyncLogI } from "@lxc/app-device-types";
import { LogType } from "@lxc/app-device-types";
import dayjs from "dayjs";
import { useLogs } from "~/composables/useLogs";
import { Filters } from "~/types";
import type { LogEntityI } from "~/types/logEntity";
import { formatIsoDate, getTodayPeriod } from "~/utils/date-tools";

const { t } = useI18n();
const {
  error,
  fetchData,
  filters,
  isLoading,
  results,
  setFilter,
  search,
  onSearch,
} = useLogs();
const dropdownLink: Ref<HTMLLinkElement | undefined | null> = ref();
const rowsSelected: Ref<Array<AsyncLogI>> = ref([]);

const filterDateFormat = "YYYY-MM-DD";
const dateFormat: string = t("logs.list.column.timestamp.format");

function addRequiredFilters() {
  if (!filters.get(Filters.LOG_TYPE)?.value) {
    setFilter(Filters.LOG_TYPE, LogType.AUDIT);
  }

  if (
    !(
      filters.get(Filters.LOG_START_DATE)?.value &&
      filters.get(Filters.LOG_END_DATE)?.value
    )
  ) {
    const todayPeriod: PeriodI = getTodayPeriod();

    if (!filters.get(Filters.LOG_START_DATE)?.value) {
      setFilter(
        Filters.LOG_START_DATE,
        dayjs(todayPeriod.startDate).format(filterDateFormat),
      );
    }
    if (!filters.get(Filters.LOG_END_DATE)?.value) {
      setFilter(
        Filters.LOG_END_DATE,
        dayjs(todayPeriod.endDate).format(filterDateFormat),
      );
    }
  }
}

async function loadData(page?: number, pageSize?: number) {
  addRequiredFilters();
  await fetchData(page, pageSize);
  dropdownLink.value = null;
}

const onPageChange = async (page?: number, pageSize?: number) => {
  await loadData(page, pageSize);
};

const openDropdown = (element?: HTMLLinkElement | null) => {
  dropdownLink.value = element;
};

const onApplyEntityAsAFilter = async (logEntity: LogEntityI) => {
  setFilter(Filters.LOG_TYPE, LogType.AUDIT);
  setFilter(Filters.LOG_COMPONENT_ID, "");
  setFilter(Filters.LOG_ENTITY_CLASS, logEntity.entityClass);
  setFilter(
    Filters.LOG_ENTITY_ID,
    logEntity.entityId ? [logEntity.entityId] : [],
  );
  setFilter(Filters.LOG_EVENT_TYPE, []);
  setFilter(Filters.LOG_LEVEL, []);
  setFilter(Filters.LOG_ACTION, []);
  setFilter(Filters.SORT_BY, "");
  setFilter(Filters.SORT_DIRECTION, "");
  search();
};

onSearch(loadData);
</script>

<template>
  <lxc-log-filters
    :filters="filters"
    :rows-selected="rowsSelected"
    @change="setFilter"
    @enter="search"
  />
  <lxc-container :px="0" :py="4">
    <lxc-table
      :empty-text="t('logs.list.empty')"
      :data="results?.data"
      :context="results?.context"
      :is-loading="isLoading"
      :error="error?.toError()"
      @change-page-and-page-size="onPageChange"
      @select="rowsSelected = $event"
    >
      <lxc-table-column type="selection" />
      <lxc-table-column align="left" :label="t('logs.timestamp.label')">
        <template #default="scope">
          {{ formatIsoDate(scope.row.timestamp, dateFormat) }}
        </template>
      </lxc-table-column>
      <lxc-table-column align="left" :label="t('logs.level.label')">
        <template #default="scope">
          {{ t(`logs.level.value.${scope.row.level}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column :label="t('logs.eventType.label')">
        <template #default="scope">
          {{ t(`logs.eventType.value.${scope.row.eventType}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column :label="t('logs.list.column.label.label')" prop="label">
        <template #default="scope">
          <lxc-log-label v-model="scope.row" @click="openDropdown" />
        </template>
      </lxc-table-column>
      <lxc-table-column :label="t('logs.origin.label')" class="!py-0">
        <template #default="scope">
          <LxcLogOrigin :model-value="scope.row.origin" />
        </template>
      </lxc-table-column>
      <lxc-table-column prop="action" :label="t('logs.action.label')" />
    </lxc-table>
  </lxc-container>
  <lxc-log-dropdown
    :element="dropdownLink"
    @apply-as-a-filter="onApplyEntityAsAFilter"
  />
</template>
