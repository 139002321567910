<script lang="ts" setup>
import ILxcDotMenu from "~icons/lxc-custom/dot-menu";

const emit = defineEmits(["command"]);
</script>

<template>
  <div @click.stop>
    <el-dropdown
      trigger="click"
      placement="left"
      @command="$emit('command', $event)"
    >
      <el-button size="small" text>
        <ILxcDotMenu />
      </el-button>
      <template #dropdown>
        <el-dropdown-menu class="table-menu">
          <slot />
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
