import { useAcl } from "vue-simple-acl";
import { ACL_ROLES } from "~/types";

export function useCertificatesACLRoles() {
  const { can } = useAcl();

  function canManageCertificates() {
    return can(ACL_ROLES.DVT_CACRT_ADM);
  }

  function canViewCertificates() {
    return can(ACL_ROLES.DVT_CACRT_VIEW);
  }

  return {
    canManageCertificates,
    canViewCertificates,
  };
}
