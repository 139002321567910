<script setup lang="ts">
import ILxSuccess from "~icons/lxc-business/step-success";
import ILxWarning from "~icons/lxc-business/step-warning";

const props = defineProps<{
  connectivityState: string;
}>();

const { t } = useI18n();
</script>

<template>
  <span
    :title="t(`filters.connectivityState.${props.connectivityState}`)"
    class="block mx-auto w-fit"
  >
    <ILxSuccess v-if="props.connectivityState === 'active'" />
    <ILxWarning v-if="props.connectivityState !== 'active'" />
  </span>
</template>
