import type {
  AsyncDeviceTwinsFirmwareStatisticsMap,
  Dashboard,
} from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import DashboardService from "~/services/dashboard.service";
import deviceService from "~/services/device.service";
import { useSectorStore } from "~/stores/useSectorStore";
import { ACL_ROLES } from "~/types";
import { DashboardCardStatus } from "~/types/dashboard";
import LxcError from "~/utils/LxcError";

export function useDashboard() {
  const dashboardExpireSoonInMonth = 1;
  const { selectedSectorCodes } = storeToRefs(useSectorStore());
  const dashboardLoadingStatus = ref<DashboardCardStatus>(
    DashboardCardStatus.LOADING,
  );
  const firmwareStatisticsStatus = ref<DashboardCardStatus>(
    DashboardCardStatus.LOADING,
  );
  const dashboard = ref<Dashboard | undefined>();
  const firmwareStatistics = ref<
    AsyncDeviceTwinsFirmwareStatisticsMap | undefined
  >();
  const error: Ref<LxcError | null> = ref(null);

  const acl = useAcl();
  const canManageFirmwares = computed(() =>
    acl.can(ACL_ROLES.MANAGE_FIRMWARES),
  );

  async function fetchDashboard() {
    dashboardLoadingStatus.value = DashboardCardStatus.LOADING;
    /* TODO: Set sectors here to have dynamic dashboard
        Usage: ['0', '654321', '5321', '78961654', '654987']
        All sectors: []
     */
    const response = await DashboardService.getDashboard(
      selectedSectorCodes.value,
      dashboardExpireSoonInMonth,
    );

    if (LxcError.check(response)) {
      error.value = response;
      dashboardLoadingStatus.value = DashboardCardStatus.FAILED;
    } else {
      dashboard.value = response;
      dashboardLoadingStatus.value = DashboardCardStatus.LOADED;
    }
  }

  async function fetchFirmwareStatistics() {
    firmwareStatisticsStatus.value = DashboardCardStatus.LOADING;
    const response = await deviceService.getFirmwareStatistics(
      selectedSectorCodes.value,
    );

    if (LxcError.check(response)) {
      error.value = response;
      firmwareStatisticsStatus.value = DashboardCardStatus.FAILED;
    } else {
      firmwareStatistics.value = response;
      firmwareStatisticsStatus.value = DashboardCardStatus.LOADED;
    }
  }

  return {
    canManageFirmwares,
    dashboardExpireSoonInMonth,
    dashboardLoadingStatus,
    error,
    dashboard,
    fetchDashboard,
    fetchFirmwareStatistics,
    firmwareStatistics,
    firmwareStatisticsStatus,
  };
}
