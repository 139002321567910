import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/composables/useFetchPage";
import { useSearch } from "~/composables/useSearch";
import type { SearchMode } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import actionsService from "~/services/actions.service";
import type { FiltersSelection } from "~/types";
import { ACL_ROLES, Filters, FiltersType } from "~/types";

export function useActions(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = actionsService.getActions.bind(actionsService);

  const { can } = useAcl();

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RAW,
    appliedFilters,
    searchMode,
  );
  const { sort } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  function canManageItems() {
    return can(ACL_ROLES.LOGS_LIST_AUDIT);
  }

  return {
    canManageItems,
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    results,
    search,
    setFilter,
  };
}
