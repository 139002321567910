<script lang="ts" setup>
import type { ApplicationModelConfigurationI } from "@lxc/app-device-types";
import { ConfigParam, S4Types } from "~/types/certConfiguration";

defineProps<{
  s4Tool: ApplicationModelConfigurationI;
  s4Manager: ApplicationModelConfigurationI;
  s4View: ApplicationModelConfigurationI;
  sg4000: ApplicationModelConfigurationI;
}>();
defineEmits(["change"]);

const { t } = useI18n();
</script>

<template>
  <h3>{{ t("table.header.applications") }}</h3>
  <section class="config-row">
    <lxc-certificate-renewal
      :delay="s4Tool.delay"
      :polling="s4Tool.polling"
      :is-autoactivated="s4Tool.autoActivation"
      :title="t('parameters.certificates.s4ToolsApp')"
      :is-app="true"
      @select-change="$emit('change', $event, S4Types.TOOLS, ConfigParam.DELAY)"
      @polling-change="
        $emit('change', $event, S4Types.TOOLS, ConfigParam.POLLING)
      "
      @auto-activation-change="
        $emit('change', $event, S4Types.TOOLS, ConfigParam.AUTO_ACTIVATION)
      "
    />

    <lxc-certificate-renewal
      :delay="s4Manager.delay"
      :polling="s4Manager.polling"
      :is-autoactivated="s4Manager.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.s4ManagerApp')"
      @select-change="
        $emit('change', $event, S4Types.MANAGER, ConfigParam.DELAY)
      "
      @polling-change="
        $emit('change', $event, S4Types.MANAGER, ConfigParam.POLLING)
      "
      @auto-activation-change="
        $emit('change', $event, S4Types.MANAGER, ConfigParam.AUTO_ACTIVATION)
      "
    />

    <lxc-certificate-renewal
      :delay="s4View.delay"
      :polling="s4View.polling"
      :is-autoactivated="s4View.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.s4ViewApp')"
      @select-change="$emit('change', $event, S4Types.VIEW, ConfigParam.DELAY)"
      @polling-change="
        $emit('change', $event, S4Types.VIEW, ConfigParam.POLLING)
      "
      @auto-activation-change="
        $emit('change', $event, S4Types.VIEW, ConfigParam.AUTO_ACTIVATION)
      "
    />

    <lxc-certificate-renewal
      :delay="sg4000.delay"
      :polling="sg4000.polling"
      :is-autoactivated="sg4000.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.sg4000')"
      @select-change="
        $emit('change', $event, S4Types.SG4000, ConfigParam.DELAY)
      "
      @polling-change="
        $emit('change', $event, S4Types.SG4000, ConfigParam.POLLING)
      "
      @auto-activation-change="
        $emit('change', $event, S4Types.SG4000, ConfigParam.AUTO_ACTIVATION)
      "
    />
  </section>
</template>
