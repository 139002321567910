import RestService from "./rest.service";
import type {
  ApiListResult,
  FleetCreationPayloadI,
  FleetI,
  FleetUpdatePayloadI,
} from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

export class FleetService extends RestService {
  protected BASE_URL = "/fleets";

  async getFleets(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<FleetI>> {
    const params = { page, pageSize, filter, sort };
    return this.$api.get(this.BASE_URL, { params });
  }

  async getFleetByUid(uid: string): Promise<FleetI> {
    return this.$api.get(`${this.BASE_URL}/${uid}`);
  }

  async createFleet(fleet: FleetCreationPayloadI): Promise<FleetI> {
    return this.$api.post(`${this.BASE_URL}`, { fleet });
  }

  async updateFleet(
    uid: string,
    fleetUpdatePayload: FleetUpdatePayloadI,
  ): Promise<void> {
    return this.$api.patch(`${this.BASE_URL}/${uid}`, { fleetUpdatePayload });
  }

  async deleteFleet(uid: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${uid}`);
  }
}
export default new FleetService();
