import { CampaignOMOperationStatisticsStatus } from "@lxc/app-device-types";
import { roundToTwoDecimals, sum } from "~/utils/number-tools";

export enum DisplayedStatistic {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export function computeCampaignStats(
  operationStatistics: Record<string, number>,
  displayedStatistics: DisplayedStatistic,
): number {
  const valueOf = (key: string) => {
    return operationStatistics[key] || 0;
  };

  switch (displayedStatistics) {
    case DisplayedStatistic.PENDING:
      return valueOf(CampaignOMOperationStatisticsStatus.SCHEDULED);
    case DisplayedStatistic.IN_PROGRESS:
      return sum([
        valueOf(CampaignOMOperationStatisticsStatus.PENDING),
        valueOf(CampaignOMOperationStatisticsStatus.DELIVERED),
        valueOf(CampaignOMOperationStatisticsStatus.RETRY),
        valueOf(CampaignOMOperationStatisticsStatus.RUNNING),
        valueOf(CampaignOMOperationStatisticsStatus.CANCELLING),
        valueOf(CampaignOMOperationStatisticsStatus.QUEUED),
      ]);
    case DisplayedStatistic.SUCCESS:
      return valueOf(CampaignOMOperationStatisticsStatus.DONE);
    case DisplayedStatistic.FAILED:
      return sum([
        valueOf(CampaignOMOperationStatisticsStatus.FAILED),
        valueOf(CampaignOMOperationStatisticsStatus.EXPIRED),
        valueOf(CampaignOMOperationStatisticsStatus.CANCELLED),
      ]);
    default:
      return 0;
  }
}

export function computeCampaignStatPercentage(
  totalCount: number,
  statisticCount: number,
) {
  return totalCount > 0
    ? `${roundToTwoDecimals((statisticCount / totalCount) * 100)}%`
    : "-";
}
