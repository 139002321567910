<script setup lang="ts">
import {
  DtwinI,
  FirmwareI,
  OperationModelI,
  OperationModelType,
} from "@lxc/app-device-types";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { SearchMode } from "~/composables/useSearch";
import { Filters } from "~/types";
import { showNotificationError } from "~/utils/notifications-tools";

export interface CampaignFunnelStep2 {
  dtwins: DtwinI[];
}

const props = defineProps<{
  firmware?: FirmwareI;
  operationModels: OperationModelI[];
  operationModelType: OperationModelType;
  modelValue: CampaignFunnelStep2;
}>();

const { t } = useI18n();

const emit = defineEmits(["update:modelValue"]);

const {
  error,
  isLoading,
  fetchAllModels,
  results: allDtwinModels,
} = useDtwinModels();

const form: ComputedRef<CampaignFunnelStep2> = computed({
  get() {
    return props.modelValue;
  },
  set(form: CampaignFunnelStep2) {
    emit("update:modelValue", form);
  },
});

const selectedDtwins = computed({
  get() {
    return form.value.dtwins;
  },
  set(selectedDtwins) {
    form.value.dtwins = selectedDtwins;
  },
});

// compute model UID from firmware range
const modelUid = computed(() => {
  let modelUid: string | undefined = undefined;

  if (
    props.operationModelType === OperationModelType.FIRM_UPDATE &&
    props.firmware &&
    allDtwinModels.value
  ) {
    const model = allDtwinModels.value.results.find(
      (dtwinModel) => dtwinModel.name === props.firmware.range,
    );
    modelUid = model?.uid;
  }

  return modelUid;
});

// compute filters to apply on dtwins list
const dtwinsListDefaultFilters = computed(() => {
  const dtwinsListDefaultFilters: Map<Filters, unknown> = new Map();

  let modelUids: string[] = [];

  // build filter on dtwins model corresponding to the selected operation model
  if (allDtwinModels.value) {
    for (const operationModel of props.operationModels) {
      if (operationModel.type === props.operationModelType) {
        for (const deviceType of operationModel.deviceTypes) {
          const model = allDtwinModels.value.results.find(
            (dtwinModel) => dtwinModel.name === deviceType,
          );
          if (model && !modelUids.includes(model.uid)) {
            modelUids.push(model.uid);
          }
        }
      }
    }
  }

  if (props.operationModelType === OperationModelType.FIRM_UPDATE) {
    if (modelUid.value) {
      modelUids = [modelUid.value];
    }

    dtwinsListDefaultFilters.set(
      Filters.DTWIN_IN_SOFTWARE_VERSION,
      toRaw(props.firmware?.firmwareVersions) ?? [],
    );
    dtwinsListDefaultFilters.set(
      Filters.DTWIN_IN_HARDWARE_VERSION,
      toRaw(props.firmware?.hardwareVersions) ?? [],
    );
  }

  dtwinsListDefaultFilters.set(Filters.DTWIN_MODEL_TYPE, modelUids);

  return dtwinsListDefaultFilters;
});

const displayDtwinsList = ref(false);

onMounted(async () => {
  await fetchAllModels();
  displayDtwinsList.value = true;
});

defineExpose({
  validate: () => {
    if (selectedDtwins.value.length === 0) {
      const message = t("campaign.funnel.fleetSelection.validation.required");
      showNotificationError(message);
      return Promise.reject(message);
    } else {
      return Promise.resolve(true);
    }
  },
});
</script>

<template>
  <lxc-container class="!p-0" :is-loading="isLoading" :error="error">
    <lxc-tabs>
      <lxc-tab-pane label="Devices">
        <lxc-dtwins-list
          v-if="displayDtwinsList"
          v-model:selected-dtwins="selectedDtwins"
          no-quick-action-menu
          no-quick-filters
          :default-filters="dtwinsListDefaultFilters"
          :search-mode="SearchMode.FILTER_SEARCH"
        />
      </lxc-tab-pane>
      <lxc-tab-pane label="Fleet"> TODO </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
