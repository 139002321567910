<script setup lang="ts">
import type { UserDataI } from "@lxc/app-device-types";
import type { ElTable } from "element-plus";
import { useElTable } from "~/composables/useElTable";
import { useUserAccountCertificates } from "~/composables/useUserAccountCertificates";

const { t } = useI18n();
const { getCellStyle } = useElTable();
const tableRef = ref<InstanceType<typeof ElTable>>();
const { error, findUserCertificateStatus, isLoading, userCertificatesStatus } =
  useUserAccountCertificates();

const props = defineProps<{
  user: UserDataI | null;
  refreshUserCertificateStatus: boolean;
}>();

const dateFormat: string = t(
  "user.userAccount.forms.certificateStatusDateFormat",
);

async function findAndUpdateUserCertificateStatus() {
  if (props.user?.id && dateFormat) {
    await findUserCertificateStatus(props.user.id, dateFormat);
  }
}

const getCellClassName = (cellCls: any) => {
  let columnClass = "";
  switch (cellCls.column.property) {
    case "information.creationDate":
      columnClass = `certificate-creation-date-${cellCls.rowIndex}`;
      break;
    case "information.notBefore":
      columnClass = `certificate-not-before-${cellCls.rowIndex}`;
      break;
    case "information.notAfter":
      columnClass = `certificate-not-after-${cellCls.rowIndex}`;
      break;
    case "download":
      columnClass = `certificate-download-${cellCls.rowIndex}`;
      break;
  }

  return `${columnClass} no-no-wrap`;
};

onMounted(findAndUpdateUserCertificateStatus);

watch(
  () => props.refreshUserCertificateStatus,
  findAndUpdateUserCertificateStatus,
);
</script>
<template>
  <lxc-container
    :px="0"
    :py="0"
    :error="error"
    :is-loading="isLoading && !userCertificatesStatus"
  >
    <el-table
      ref="tableRef"
      v-loading="isLoading"
      :data="userCertificatesStatus?.data"
      :fit="true"
      :cell-style="getCellStyle"
      :cell-class-name="getCellClassName"
      :empty-text="t('user.userAccount.forms.certificateEmpty')"
    >
      <el-table-column
        label-class-name="certificate-creation-date-header"
        prop="information.creationDate"
        :label="t('user.userAccount.forms.generatedOn')"
      />
      <el-table-column
        label-class-name="certificate-not-before-header"
        prop="information.notBefore"
        :label="t('user.userAccount.forms.validFrom')"
      />
      <el-table-column
        label-class-name="certificate-not-after-header"
        prop="information.notAfter"
        :label="t('user.userAccount.forms.validTo')"
      />
      <el-table-column
        label-class-name="certificate-download-header"
        prop="download"
        width="100"
      >
        <lxc-user-account-certificate-download-button :user="$props.user" />
      </el-table-column>
    </el-table>
  </lxc-container>
</template>
