import type { Ref } from "vue";

export function useToggle(): [Ref<boolean>, (next?: boolean) => void] {
  const currentValue: Ref<boolean> = ref(false);

  function toggle(next?: boolean): void {
    if (typeof next === "boolean") {
      currentValue.value = next;
      return;
    }
    currentValue.value = !currentValue.value;
  }

  return [currentValue, toggle];
}
