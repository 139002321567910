import type { UniqueLabel } from "@lxc/app-device-common";
import type {
  ApplicationI,
  DeviceI,
  RoleI,
  UserDataI,
  UserGroupI,
  UserProfileI,
  UserSessionSector,
} from "@lxc/app-device-types";
import dayjs from "dayjs";
import type {
  FilterOptions,
  FilterSelectionDefinition,
  FilterSelectionValue,
  Option,
} from "~/types";

function formatRSQLValue(value: FilterSelectionValue): FilterSelectionValue {
  if (Array.isArray(value)) {
    return value.map(formatRSQLValue) as FilterSelectionValue;
  } else {
    let newValue = value.replaceAll("\\", "\\\\");
    newValue = newValue.replaceAll('"', '\\"');
    return `"${newValue}"`;
  }
}

function getTagText(prefix: string, value?: string | null): string | undefined {
  if (value) {
    return `${prefix.toLowerCase()} ${value}`;
  }
}

function getTag(
  prefix: string,
  uid?: string,
  value?: string | null,
  disabled = false,
): UniqueLabel | undefined {
  const label = getTagText(prefix, value);

  if (label && uid) {
    return {
      label,
      uid,
      disabled,
      deletable: true,
    };
  }

  return undefined;
}

function getDeviceDvtmEsoftTag(
  device: DeviceI,
  deviceLabel: string,
): UniqueLabel | undefined {
  return getTag(deviceLabel, device.id, device.name ?? device.id);
}

function getProfileTag(
  profile: UserProfileI,
  profileLabel: string,
): UniqueLabel | undefined {
  return getTag(profileLabel, profile.code, profile.label);
}

function getPeriodTag(
  serializedStartDate: string,
  tagDateFormatter: string,
  prefix: string,
  periodSeparator: string,
  serializedEndDate?: string,
): UniqueLabel | undefined {
  let tag: UniqueLabel | undefined;

  if (serializedStartDate) {
    const displayStartDate =
      dayjs(serializedStartDate).format(tagDateFormatter);

    if (prefix) {
      if (serializedEndDate) {
        const displayEndDate =
          dayjs(serializedEndDate).format(tagDateFormatter);
        if (displayStartDate !== displayEndDate) {
          tag = getTag(
            prefix,
            `${serializedStartDate}-${serializedEndDate}`,
            `${displayStartDate} ${periodSeparator} ${displayEndDate}`,
          );
        }
      }

      if (!tag) {
        tag = getTag(prefix, serializedStartDate, displayStartDate);
      }
    }
  }

  return tag;
}

function getRoleTag(role: RoleI, roleLabel: string): UniqueLabel | undefined {
  return getTag(
    roleLabel,
    String(role.id),
    role.translation?.label ?? role.code,
  );
}

function getSectorTag(
  sector: UserSessionSector,
  sectorLabel: string,
): UniqueLabel | undefined {
  return getTag(sectorLabel, sector.code, sector.label);
}

function getUserTag(
  user: UserDataI,
  userLabel: string,
): UniqueLabel | undefined {
  return getTag(
    userLabel,
    user.id,
    user.lastName ? `${user.firstName} ${user.lastName}` : user.id,
  );
}

function getApplicationTag(
  application: ApplicationI,
  applicationLabel: string,
): UniqueLabel | undefined {
  return getTag(
    applicationLabel,
    application.id,
    application.name ?? application.id,
  );
}

function getUserGroupTag(
  userGroup: UserGroupI,
  userGroupLabel: string,
): UniqueLabel | undefined {
  return getTag(
    userGroupLabel,
    userGroup.code,
    userGroup.label ?? userGroup.code,
  );
}

function getDisabledOptionValues(
  value: FilterSelectionValue,
  options?: Option[],
): FilterSelectionValue {
  return (
    options
      ?.filter(
        (option) =>
          option.disabled &&
          Array.isArray(value) &&
          value.includes(option.value),
      )
      .map((option) => option.value) ?? []
  );
}

function findDisabledOptionValue(
  value: FilterSelectionValue,
  options?: Option[],
): FilterSelectionValue {
  return (
    options?.find((option) => option.disabled && option.value === value)
      ?.value ?? ""
  );
}

function cloneFilterSelectionDefinition(
  filterSelectionDefinition: FilterSelectionDefinition,
): FilterSelectionDefinition {
  const { key, operator } = filterSelectionDefinition;
  const value = filterSelectionDefinition.value.slice(0);
  const cloneFilterSelectionDefinition: FilterSelectionDefinition = {
    key,
    operator,
    value,
  };

  if (filterSelectionDefinition.fallbackValue != null) {
    cloneFilterSelectionDefinition.fallbackValue =
      filterSelectionDefinition.fallbackValue.slice(0);
  }

  return cloneFilterSelectionDefinition;
}

function buildFilterOptions(
  filterOptions: FilterOptions,
  isDisabled?: boolean,
  disabledOptions?: Array<string>,
): FilterOptions {
  return {
    label: filterOptions.label,
    options: filterOptions.options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        disabled: isDisabled ?? disabledOptions?.includes(option.value),
      };
    }),
  };
}

export default {
  formatRSQLValue,
  buildFilterOptions,
  cloneFilterSelectionDefinition,
  getApplicationTag,
  getDeviceDvtmEsoftTag,
  getDisabledOptionValues,
  findDisabledOptionValue,
  getPeriodTag,
  getProfileTag,
  getRoleTag,
  getSectorTag,
  getUserGroupTag,
  getUserTag,
  getTag,
  getTagText,
};
