<script lang="ts" setup>
import type { UserDataI } from "@lxc/app-device-types";
import { useToggle } from "~/composables/useToggle";
import userService from "~/services/users.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

defineProps<{ user: UserDataI }>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const [removeModalVisible, toggleRemoveModalVisible] = useToggle();

/**
 * When removal confirmation of the user
 * @param userId
 */
async function onRemoveUser(userId: string) {
  const removeResponse = await userService.removeUser(userId);

  if (LxcError.check(removeResponse)) {
    removeResponse.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    toggleRemoveModalVisible();
    emit("change");
  }
}

const onDeleteUserClick = () => {
  toggleRemoveModalVisible(true);
};
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :icon="ILxcLightTrash2"
    :title="t('user.button.delete')"
    class="invisible"
    @click.stop.prevent="onDeleteUserClick"
  />

  <lxc-confirm-modal
    :is-dialog-visible="removeModalVisible"
    :title="t('user.confirmRemove.title')"
    :description="
      t('user.confirmRemove.description', {
        userName: user?.lastName,
        userFirstname: user?.firstName,
      })
    "
    @cancel="toggleRemoveModalVisible"
    @confirm="onRemoveUser(user.id)"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
