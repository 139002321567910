import type { Ref } from "vue";
import dtwinsService from "~/services/dtwins.service";
import { fetchAllWithPagination } from "~/utils/pagination.utils";

export interface DeviceTypeFilter {
  label: string;
  value: string;
  uid?: string | undefined;
}

// Hardcoded magic strings defined in the old device twin (eSoftlink).
const eSoftMagicS4GPLDeviceType = "S4G-PL";
const eSoftMagicDeviceType: DeviceTypeFilter[] = [
  {
    value: eSoftMagicS4GPLDeviceType,
    label: eSoftMagicS4GPLDeviceType,
  },
];

export function isESoftMagicDeviceTypeByValue(value: string) {
  return eSoftMagicDeviceType.map((type) => type.value).includes(value);
}

export async function useDeviceTypeFilters() {
  const deviceTypeFilters: Ref<DeviceTypeFilter[]> = ref([]);

  const deviceModels = await fetchAllWithPagination({
    callback: (page: number, pageSize: number) => {
      return dtwinsService.getModels(page, pageSize);
    },
  });
  const deviceTypes: DeviceTypeFilter[] = deviceModels.map((model) => {
    return { value: model.name, label: model.friendlyName, uid: model.uid };
  });
  deviceTypeFilters.value = [...eSoftMagicDeviceType, ...deviceTypes].sort(
    (a, b) => (a.label > b.label ? 1 : -1),
  );

  return {
    deviceTypeFilters,
  };
}
