import { useFetchPage } from "~/composables/useFetchPage";
import { SearchMode, useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import FleetService from "~/services/fleet.service";
import type { FiltersSelection } from "~/types";
import { Filters, FiltersType, OPERATOR_RSQL_ILIKE } from "~/types";
import filtersUtils from "~/utils/filters.utils";

export function useFleet(searchMode: SearchMode = SearchMode.URL_SEARCH) {
  const service = FleetService.getFleets.bind(FleetService);

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.FLEET_NAME,
      {
        key: "friendlyName",
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
  });

  return {
    isLoading,
    error,
    results,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
    sort,
  };
}
