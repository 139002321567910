<script setup lang="ts">
import type { CampaignI } from "@lxc/app-device-types";
import { useCampaign, useGetCampaignStat } from "~/composables/useCampaign";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";

const { t } = useI18n();

const router = useRouter();
const {
  isLoading,
  results: campaigns,
  filters,
  error,
  fetchData,
  onSearch,
  search,
  setFilter,
} = useCampaign();

async function onSearchClear() {
  setFilter(Filters.NAME, "");
  search();
}

async function loadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
}

onSearch(loadData);

const onSelectCampaign = (row: CampaignI) => {
  router.push(`${PATHS.CAMPAIGNS}/${row.id}/${PATHS.DETAILS_SUBPATH}`);
};
</script>
<template>
  <div class="mb-4">
    <lxc-search-bar
      :search-query="filters.get(Filters.NAME)"
      :search-placeholder="t('filters.searchByName')"
      @clear="onSearchClear"
      @input-change="setFilter(Filters.NAME, $event)"
      @search="search"
    />
  </div>
  <lxc-table
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="campaigns?.data"
    :context="campaigns?.context"
    :empty-text="t('campaign.list.empty')"
    clickable
    @row-click="onSelectCampaign"
    @change-page-and-page-size="loadData"
  >
    <lxc-table-column
      prop="name"
      :label="t('campaign.list.column.name.label')"
      width="200"
      max-characters="22"
    >
      <template #default="scope">
        {{ scope.row.config.name }}
      </template>
    </lxc-table-column>
    <lxc-table-column prop="type" :label="t('campaign.list.column.type.label')">
      <template #default="scope">
        {{ t(`campaign.list.column.type.${scope.row.type}`) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="dates"
      :label="t('campaign.list.column.dates.label')"
      width="200"
    >
      <template #default="scope">
        {{
          formatIsoDate(
            scope.row.config.plannedStartAt,
            t("campaign.list.column.dates.format"),
          )
        }}
        →
        {{
          formatIsoDate(
            scope.row.config.plannedEndAt,
            t("campaign.list.column.dates.format"),
          )
        }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      :label="t('campaign.list.column.numberOfEquipments.label')"
      width="150"
    >
      <template #default="scope">
        {{
          t(
            "campaign.list.column.numberOfEquipments.value",
            scope.row.objects.length,
          )
        }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="status"
      :label="t('campaign.list.column.status.label')"
      width="130"
    >
      <template #default="scope">
        <lxc-campaign-state :state="scope.row.state" />
      </template>
    </lxc-table-column>

    <lxc-table-column
      :label="t('campaign.list.column.pending.label')"
      class="column-pending"
      width="100"
    >
      <template #default="scope">
        {{ useGetCampaignStat(scope.row).get("percentPending") }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      :label="t('campaign.list.column.inProgress.label')"
      class="column-in-progress"
      width="100"
    >
      <template #default="scope">
        {{ useGetCampaignStat(scope.row).get("percentInProgress") }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      :label="t('campaign.list.column.success.label')"
      class="column-success"
      width="100"
    >
      <template #default="scope">
        {{ useGetCampaignStat(scope.row).get("percentSuccess") }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      :label="t('campaign.list.column.failed.label')"
      class="column-failed"
      width="100"
    >
      <template #default="scope">
        {{ useGetCampaignStat(scope.row).get("percentFailed") }}
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
<style scoped lang="scss">
// The colors must be validated
:deep(td.column-pending) {
  color: grey;
}

:deep(td.column-in-progress) {
  color: #b36f37;
}

:deep(td.column-success) {
  color: #5f7349;
}

:deep(td.column-failed) {
  color: #c4314b;
}
</style>
