import RestService from "./rest.service";
import type { AsyncTaskIdI, AsyncTaskStatusI } from "@lxc/app-device-types";

class AlertService extends RestService {
  private BASE_URL = "/alert";

  getExportLogsTaskId(): Promise<AsyncTaskIdI> {
    return this.$api.get(`${this.BASE_URL}/logs/export`);
  }

  getExportLogsTaskStatus(taskId: string): Promise<AsyncTaskStatusI> {
    return this.$api.get(`${this.BASE_URL}/logs/tasks/${taskId}/status`);
  }

  downloadExportLogs(taskId: string): Promise<any> {
    return this.$api.post(
      `/proxy?serviceId=DOWNLOAD_LOGS&target=${taskId}/download`,
      null,
      {
        headers: { Accept: "application/octet-stream" },
        responseType: "arraybuffer",
        timeout: Infinity,
      },
    );
  }
}

export default new AlertService();
