<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import LxcAccordion from "@lxc/app-device-common/src/components/LxcAccordion.vue";
import LxcLoader from "@lxc/app-device-common/src/components/LxcLoader.vue";
import LxcSideCanvas from "@lxc/app-device-common/src/components/LxcSideCanvas.vue";
import type {
  LxcAccordionItemI,
  LxcAccordionRowI,
} from "@lxc/app-device-common/src/components/accordion/LxcAccordion.model";
import LxcForm from "@lxc/app-device-common/src/components/form/LxcForm.vue";
import LxcFormItem from "@lxc/app-device-common/src/components/form/LxcFormItem.vue";
import LxcInput from "@lxc/app-device-common/src/components/form/LxcInput.vue";
import type {
  ProfileRolesI,
  RoleI,
  RolesI,
  UserProfileI,
} from "@lxc/app-device-types";
import type { Rules } from "async-validator";
import userProfilesService from "~/services/userProfiles.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  newProfile: boolean;
  rolesBySectionAndContext?: RolesI | null;
}>();
const emit = defineEmits(["update", "close"]);

const { t } = useI18n();
const route = useRoute();
const serialize = useSerialize();

const TAG_READ_SUFFIX = ":read";
const TAG_WRITE_SUFFIX = ":write";

const show: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);
const isSubmitting: Ref<boolean> = ref(false);
const sideCanvasHeader: Ref<string> = ref("");

const profileFormRef = ref();

/**
 * Form rules
 */
const rules = reactive<Rules>({
  label: [
    { required: true, message: t("profile.validation.name"), type: "string" },
    { max: 50, message: t("profile.validation.maxLength", { maxLength: 50 }) },
  ],
});

/**
 * Local profile form interface
 */
interface Form {
  label: string;
  roleRowsByHeader: Map<string, Array<LxcAccordionRowI>>;
  additionalRoles: Array<RoleI>;
  isDefault: boolean;
}

/**
 * Local profile form snapshot interface
 */
interface FormSnapshot {
  label: string;
  roles: Array<RoleI>;
}

/**
 * Local profile form
 */
const form: Ref<Form> = ref({
  label: "",
  roleRowsByHeader: new Map(),
  additionalRoles: [],
  isDefault: false,
});

let formSnapshot: FormSnapshot = snapshotForm(form.value);

/**
 * Return a snapshot of a form
 * @param form
 */
function snapshotForm(form: Form): FormSnapshot {
  return {
    label: form.label,
    roles: buildProfileRoles(form),
  };
}

/**
 * Compare and return true if the form snapshots are equals, false otherwise
 * @param form1
 * @param form2
 */
function areFormSnapshotsEqual(
  form1: FormSnapshot,
  form2: FormSnapshot,
): boolean {
  return serialize(form1) === serialize(form2);
}

/**
 * Set the profile form with the provided profile
 * Or reset it if no profile is provided
 * @param profile
 */
function setProfileForm(profile?: UserProfileI) {
  clearValidateForm();

  form.value.label = profile?.label ?? "";

  form.value.roleRowsByHeader = new Map();
  if (props.rolesBySectionAndContext) {
    for (const section of props.rolesBySectionAndContext.sections) {
      form.value.roleRowsByHeader.set(
        section.name,
        section.contexts.map((context) => {
          return {
            label: context.name,
            items: context.roles.map((contextRole) => {
              return {
                label: contextRole.translation?.label ?? "",
                hint: contextRole.tooltip?.label,
                value: contextRole.code,
                checked: !!profile?.roles?.find(
                  (profileRole) => profileRole.code === contextRole.code,
                ),
                disabled: profile?.isDefault,
              };
            }),
          };
        }),
      );
    }

    form.value.additionalRoles = props.rolesBySectionAndContext.others.filter(
      (otherRole) =>
        profile?.roles?.find(
          (profileRole) => profileRole.code === otherRole.code,
        ),
    );
  }

  form.value.isDefault = profile?.isDefault ?? false;

  formSnapshot = snapshotForm(form.value);
}

/**
 * Set the form by fetching the profile with the profile code provided in the route
 * Or reset the form if property "newProfile" is true
 */
async function setForm() {
  if (isLoading.value) {
    return;
  }

  sideCanvasHeader.value = "";

  if (props.newProfile) {
    setProfileForm();

    sideCanvasHeader.value = t("profile.new");
    show.value = true;
  } else {
    const profileCode: string = route.params.profileCode as string;

    if (profileCode) {
      setProfileForm();

      isLoading.value = true;
      show.value = true;

      const response =
        await userProfilesService.getUserProfileByCode(profileCode);

      if (LxcError.check(response)) {
        response.notify(NotificationKey.error);
      } else {
        setProfileForm(response);
      }

      sideCanvasHeader.value = form.value.label;

      isLoading.value = false;
    } else {
      show.value = false;
    }
  }
}

/**
 * Count of columns for accordion context depending on the max items count per row
 */
const accordionColumnsCount: ComputedRef<number> = computed(() => {
  let columns = 0;

  for (const [_, rows] of form.value.roleRowsByHeader) {
    for (const row of rows) {
      columns = Math.max(columns, row.items.length);
    }
  }

  return columns;
});

/**
 * List of all the roles from property roles of type RolesI
 * Used in profile roles building
 */
const roles: ComputedRef<Array<RoleI>> = computed(() => {
  const roles: Array<RoleI> = [];

  if (props.rolesBySectionAndContext) {
    for (const section of props.rolesBySectionAndContext.sections) {
      for (const context of section.contexts) {
        for (const role of context.roles) {
          roles.push(role);
        }
      }
    }
  }

  return roles;
});

/**
 * Build the roles to save the profile
 */
function buildProfileRoles(form: Form): RoleI[] {
  const profileRoles: ProfileRolesI[] = [];

  for (const [_, rows] of form.roleRowsByHeader) {
    for (const row of rows) {
      for (const item of row.items) {
        if (item?.checked) {
          const role = roles.value.find((role) => item.value === role.code);
          if (role) {
            profileRoles.push({
              code: role.code,
              orgCode: role.orgCode?.toString(),
            });
          }
        }
      }
    }
  }

  for (const additionalRole of form.additionalRoles) {
    profileRoles.push({
      code: additionalRole.code,
      orgCode: additionalRole.orgCode?.toString(),
    });
  }

  return profileRoles;
}

async function validateForm() {
  return await profileFormRef.value?.validate().catch((_: any) => false);
}

function clearValidateForm() {
  setTimeout(profileFormRef.value?.clearValidate, 0); // need to be asynchronous in order to be well applied
}

async function onSubmit() {
  if (await validateForm()) {
    isSubmitting.value = true;

    const profile: UserProfileI = {
      label: form.value.label,
      roles: buildProfileRoles(form.value),
    };

    let response;
    if (props.newProfile) {
      response = await userProfilesService.createUserProfile(profile);
    } else {
      profile.code = route.params.profileCode as string;
      response = await userProfilesService.updateUserProfile(
        route.params.profileCode as string,
        profile,
      );
    }

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      formSnapshot = snapshotForm(form.value);

      showNotificationSuccess(t(NotificationKey.saveSuccess));

      emit("update");

      show.value = false;
    }

    isSubmitting.value = false;
  }
}

/**
 * On item changed
 * @param item
 */
function onItemChanged(item: LxcAccordionItemI) {
  // loop on the role rows of the form in order to find the changed item
  for (const [_, rows] of form.value.roleRowsByHeader) {
    for (const row of rows) {
      for (const rowItem of row.items) {
        // the changed item is found
        if (rowItem && rowItem.value === item.value) {
          onItemChangedInRow(rowItem, row);

          // exit the function once the item has been processed
          return;
        }
      }
    }
  }
}

/**
 * On item changed in a row
 * - if write role checked => check the corresponding role read
 * - if read role unchecked => uncheck the corresponding role write
 * supposed that the "read" and "write" items are in the same row
 */
function onItemChangedInRow(item: LxcAccordionItemI, row: LxcAccordionRowI) {
  // get the role corresponding to the item
  const role = roles.value.find((role) => role.code === item.value);
  if (role) {
    // find the "write" tag of the role
    const tagWrite = role.tags?.find((tag) => tag.endsWith(TAG_WRITE_SUFFIX));
    if (tagWrite) {
      // the role is a "write" one and is checked
      if (item.checked) {
        // find the corresponding "read" role and check it
        updateItemByRoleTagInRow(
          tagWrite.replace(TAG_WRITE_SUFFIX, TAG_READ_SUFFIX),
          true,
          row,
        );
      }
    }
    // no "write" tag found
    else {
      // find the "read" tag of the role
      const tagRead = role.tags?.find((tag) => tag.endsWith(TAG_READ_SUFFIX));
      // the role is a "read" one and is unchecked
      if (tagRead && !item.checked) {
        // find the item of the "write" role and uncheck it
        updateItemByRoleTagInRow(
          tagRead.replace(TAG_READ_SUFFIX, TAG_WRITE_SUFFIX),
          false,
          row,
        );
      }
    }
  }
}

/**
 * Update an item by a role tag in a specific row
 * @param tag
 * @param checked
 * @param row
 */
function updateItemByRoleTagInRow(
  tag: string,
  checked: boolean,
  row: LxcAccordionRowI,
) {
  // find the role containing the tag
  const role = roles.value.find((role) => role.tags?.includes(tag));
  if (role) {
    // find the item of the role in order to update it
    const item = row.items.find((item) => item?.value === role.code);
    if (item) {
      item.checked = checked;
    }
  }
}

watch(route, setForm);
watch(() => props.rolesBySectionAndContext, setForm);
watch(
  () => props.newProfile,
  (isNew) => (isNew ? setForm() : null),
);

onMounted(setForm);
</script>

<template>
  <LxcSideCanvas
    v-model:show="show"
    :header="sideCanvasHeader"
    :confirm-enabled="!areFormSnapshotsEqual(formSnapshot, snapshotForm(form))"
    :confirm-title="t('message.confirm.cancel.title')"
    :confirm-message="t('message.confirm.cancel.message')"
    :confirm-ok-label="t('button.confirm')"
    :confirm-cancel-label="t('button.cancel')"
    size="1/2"
    @hidden="$emit('close')"
  >
    <div v-if="isLoading" class="flex flex-col items-center h-5 justify-center">
      <LxcLoader :size="20" />
    </div>
    <LxcForm
      v-else
      ref="profileFormRef"
      :rules="rules"
      :model="form"
      @submit.prevent="validateForm"
    >
      <h5>{{ t("profile.identity") }}</h5>

      <LxcFormItem prop="label" :label="t('profile.name')">
        <LxcInput v-model="form.label" type="text" :disabled="form.isDefault" />
      </LxcFormItem>

      <h5>{{ t("profile.roles") }}</h5>

      <div v-if="form.roleRowsByHeader.size > 0">
        <LxcAccordion
          v-for="[accordionHeader, accordionRows] in form.roleRowsByHeader"
          :key="accordionHeader"
          :columns="accordionColumnsCount + 1"
          :header="accordionHeader"
          :rows="accordionRows"
          @item-changed="onItemChanged"
        />
      </div>
    </LxcForm>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.cancel')"
          @click="show = false"
        >
          {{ t("button.cancel") }}
        </lxc-button>
        <lxc-button
          html-type="button"
          :disabled="
            form.isDefault ||
            isSubmitting ||
            areFormSnapshotsEqual(formSnapshot, snapshotForm(form))
          "
          :is-loading="isSubmitting"
          :title="t('button.validate')"
          @click="onSubmit"
        >
          {{ t("button.validate") }}
        </lxc-button>
      </div>
    </template>
  </LxcSideCanvas>
</template>
