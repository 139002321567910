<script setup lang="ts">
import type { TestPkiConnectivity } from "@lxc/app-device-types";
import { ConnectivityState, PkiUsage } from "@lxc/app-device-types";
import type { Ref } from "vue";
import LxcBreadcrumb from "~/components/shared/breadcrumb/LxcBreadcrumb.vue";
import { BreadcrumbConfig } from "~/components/shared/breadcrumb/breadcrumb.config";
import PkiConnectorService from "~/services/pkiConnector.service";
import type { PkiConfigFormI } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const { t } = useI18n();

const isLoading = ref(false);
const pkiConfiguration = ref<PkiConfigFormI | null>();
const error: Ref<LxcError | null> = ref(null);
let pkiConfigurationBackup: PkiConfigFormI | undefined | null;

onMounted(async () => await fetchPkiConnectorConfiguration());

async function fetchPkiConnectorConfiguration() {
  isLoading.value = true;
  const response = await PkiConnectorService.fetchByUsage(PkiUsage.DEVICE);

  if (LxcError.check(response)) {
    error.value = response;
    pkiConfigurationBackup = null;
  } else {
    pkiConfiguration.value = response;
    pkiConfigurationBackup = Object.assign<PkiConfigFormI, PkiConfigFormI>(
      {},
      response,
    );
  }

  isLoading.value = false;
}

async function updatePkiConnectorConfiguration(formContent: PkiConfigFormI) {
  isLoading.value = true;
  const response = await PkiConnectorService.updateByUsage(
    PkiUsage.DEVICE,
    formContent,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError);
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    pkiConfigurationBackup = pkiConfiguration.value
      ? Object.assign({}, pkiConfiguration.value)
      : null;
  }

  isLoading.value = false;
}

function isConnectivitySucceeded(testResult?: TestPkiConnectivity): boolean {
  return [
    testResult?.connectivity,
    testResult?.authentication,
    testResult?.rootCA,
  ].every((test) => test === ConnectivityState.OK);
}

function cancelForm() {
  pkiConfiguration.value = pkiConfigurationBackup
    ? Object.assign({}, pkiConfigurationBackup)
    : null;
  showNotificationSuccess(t(NotificationKey.cancel));
}

function sendForm(event: PkiConfigFormI) {
  pkiConfiguration.value = event;
  updatePkiConnectorConfiguration(event);
}
</script>

<template>
  <lxc-container class="mb-4">
    <lxc-breadcrumb :name="BreadcrumbConfig.PKI_CONNECTOR.title" readonly />
    <lxc-container :error="error" :is-loading="isLoading" :px="0">
      <lxc-pki-connector-form
        :pki-configuration="pkiConfiguration"
        @cancel="cancelForm"
        @validate="sendForm"
      />
    </lxc-container>
  </lxc-container>
</template>
