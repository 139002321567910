import RestService from "./rest.service";
import type { ApiListResult } from "@lxc/app-device-types";
import { BASE_URL_MAP } from "~/constants/constants";
import type { FiltersSelection } from "~/types";

export class ActionsService extends RestService {
  protected BASE_URL = BASE_URL_MAP.ACTIONS;

  /**
   * Retrieve actions with pagination
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  public getActions(
    page: number,
    pageSize: number,
    searchParams?: string | FiltersSelection,
    _sort?: string | null,
    _paramsMap?: Map<string, any>,
  ): Promise<ApiListResult<string>> {
    const params = {
      page,
      pageSize,
      search: searchParams,
    };

    return this.$api.get(this.BASE_URL, { params });
  }
}

export default new ActionsService();
