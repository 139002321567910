<script setup lang="ts">
import type { UserDataI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { useAcl } from "vue-simple-acl";
import usersService from "~/services/users.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { useUserSession } from "~/stores/useUserSession";
import type { UserLanguageI } from "~/types";
import { ACL_ROLES } from "~/types";
import LxcError from "~/utils/LxcError";
import { NotificationKey } from "~/utils/notifications-tools";

const { t } = useI18n();
const acl = useAcl();
const { featureToggle } = useConfigStore();
const { userSession } = useUserSession();
const { isModeAD } = storeToRefs(useConfigStore());

const error: Ref<LxcError | null> = ref(null);
const user: Ref<UserDataI | null> = ref(null);
const canViewCertificate = computed(
  () =>
    featureToggle.isVisible("user-certificate") &&
    acl.can(ACL_ROLES.USR_CERTIFICATES),
);

const userAccountProfile = ref();

function saveLanguage(language: UserLanguageI) {
  userAccountProfile.value.saveLanguage(language);
}

const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  error.value = null;

  const userId = userSession?.userId;
  if (userId) {
    const response = await usersService.getUserById(userId);
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
      error.value = response;
    } else {
      user.value = response;
    }
  }

  isLoading.value = false;
});
</script>

<template>
  <lxc-container>
    <lxc-breadcrumb />

    <lxc-container :is-loading="isLoading" :error="error" :px="0">
      <lxc-tabs class="tabs">
        <lxc-tab-pane
          :label="t('user.userAccount.userInformations.title')"
          name="first"
        >
          <lxc-user-account-profile
            ref="userAccountProfile"
            v-model:user="user"
            :is-mode-a-d="isModeAD"
          />
        </lxc-tab-pane>

        <lxc-tab-pane
          :label="t('user.userAccount.language.title')"
          name="second"
        >
          <lxc-user-account-language
            v-model:user="user"
            :is-mode-a-d="isModeAD"
            @save-language="saveLanguage"
          />
        </lxc-tab-pane>

        <lxc-tab-pane
          v-if="canViewCertificate"
          :label="t('user.userAccount.certificate.title')"
          name="third"
        >
          <lxc-user-account-certificates v-model:user="user" />
        </lxc-tab-pane>
      </lxc-tabs>
    </lxc-container>
  </lxc-container>
</template>
