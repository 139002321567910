<script setup lang="ts">
import { useFleet } from "~/composables/useFleet";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";

const props = defineProps<{
  reload: boolean;
}>();
const emits = defineEmits(["update:reload"]);

const { t } = useI18n();
const router = useRouter();
const {
  isLoading,
  error,
  results,
  setFilter,
  fetchData,
  search,
  filters,
  onSearch,
} = useFleet();

const searchQuery = ref<string>(
  (filters.get(Filters.FLEET_NAME) ?? "") as string,
);

const loadData = async (page?: number, pageSize?: number) => {
  if (page && pageSize) {
    await fetchData(page, pageSize);
  } else {
    await fetchData(
      results.value?.context.page,
      results.value?.context.pageSize,
    );
  }
};

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.FLEET_NAME, searchQuery.value);
};

const onClearSearch = () => {
  setFilter(Filters.FLEET_NAME, "");
  search();
};

onMounted(async () => {
  onSearch(loadData);
});

watch(
  () => props.reload,
  (reload) => {
    if (reload) {
      onSearch(loadData);
      emits("update:reload", false);
    }
  },
);
</script>
<template>
  <lxc-container :px="0" :py="0">
    <div class="mb-6 mt-2.5">
      <lxc-search-bar
        :search-query="searchQuery"
        :search-placeholder="t('filters.searchByName')"
        @clear="onClearSearch"
        @input-change="onSearchInputChanged"
        @search="search"
      />
    </div>
    <lxc-table
      :is-loading="isLoading"
      :context="results?.context"
      :data="results?.data"
      :error="error?.toError()"
      data-cy="fleet-table"
      :clickable="true"
      :empty-text="t('fleet.table.empty')"
      @change-page-and-page-size="loadData"
      @row-click="router.push(`${PATHS.FLEETS}/${$event.uid}`)"
    >
      <lxc-table-column
        prop="friendlyName"
        :label="t('fleet.table.column.fleet')"
        max-characters="25"
      />
      <lxc-table-column
        prop="deviceTwinCount"
        :label="t('fleet.table.column.devices')"
      />
      <lxc-table-column
        prop="createdAt"
        :label="t('fleet.table.column.createdAt')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.createdAt, t("dateFormat.datetime")) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="modifiedAt"
        :label="t('fleet.table.column.updatedAt')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.modifiedAt, t("dateFormat.datetime")) }}
        </template>
      </lxc-table-column>
      <lxc-table-column :width="50" class="!py-0">
        <template #default="scope">
          <lxc-fleet-delete-action
            :fleet-uid="scope.row.uid"
            @deleted="loadData"
          />
        </template>
      </lxc-table-column>
    </lxc-table>
  </lxc-container>
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
