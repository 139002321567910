<script setup lang="ts">
import type { DashboardCampaign } from "@lxc/app-device-types";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import type { DashboardCardStatus } from "~/types/dashboard";
import {
  isCampaignPieDisplayable,
  isCampaignTileDisplayable,
  verifyCardStatus,
} from "~/utils/dashboard.utils";

const props = defineProps<{
  status: DashboardCardStatus;
  campaign?: DashboardCampaign;
}>();

const { t } = useI18n();
</script>

<template>
  <lxc-dashboard-card
    :display-header-margin="false"
    :should-expand-body="true"
    :status="verifyCardStatus(props.status, props.campaign)"
  >
    <div v-if="isCampaignTileDisplayable(campaign)">
      <el-row :gutter="20" justify="space-evenly">
        <el-col
          v-if="isCampaignPieDisplayable(campaign.s4Device)"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
        >
          <div class="grid-content">
            <lxc-detail-certificate-pie
              :title="t('dashboard.certificates.device')"
              :detail="campaign.s4Device"
              :url-redirect="PATHS.DEVICES_DVTM_ESOFT"
            />
          </div>
        </el-col>
        <el-col
          v-if="isCampaignPieDisplayable(campaign.s4Manager)"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
        >
          <div class="grid-content">
            <lxc-detail-certificate-pie
              :title="t('dashboard.certificates.manager')"
              :detail="campaign.s4Manager"
              :url-redirect="`${PATHS.APPLICATIONS}?${Filters.MODEL_TYPE}[]=S4-Manager`"
            />
          </div>
        </el-col>
        <el-col
          v-if="isCampaignPieDisplayable(campaign.s4Tools)"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
        >
          <div class="grid-content">
            <lxc-detail-certificate-pie
              :title="t('dashboard.certificates.tools')"
              :detail="campaign.s4Tools"
              :url-redirect="`${PATHS.APPLICATIONS}?${Filters.MODEL_TYPE}[]=S4G-Tools`"
            />
          </div>
        </el-col>
        <el-col
          v-if="isCampaignPieDisplayable(campaign.s4View)"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="6"
          :xl="6"
        >
          <div class="grid-content">
            <lxc-detail-certificate-pie
              :title="t('dashboard.certificates.view')"
              :detail="campaign.s4View"
              :url-redirect="`${PATHS.APPLICATIONS}?${Filters.MODEL_TYPE}[]=S4-View`"
            />
          </div>
        </el-col>
      </el-row>

      <ul>
        <li>
          {{ t("dashboard.certificates.legend.valid") }}
        </li>
        <li>
          {{ t("dashboard.certificates.legend.expireSoon") }}
        </li>
        <li>
          {{ t("dashboard.certificates.legend.expired") }}
        </li>
      </ul>
    </div>
    <div v-else class="no-data-style">
      <p>{{ t("dashboard.certificates.noData") }}</p>
    </div>
  </lxc-dashboard-card>
</template>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  justify-content: center;
}

li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  background: $high-priority-color;
}

li:first-child::before {
  background: $light-blue-color;
}

li:nth-child(2n)::before {
  background: $light-priority-color;
}

ul li {
  list-style-type: none;
  text-align: center;
  padding-right: 25px;
  display: inline-block;
  font-size: 14px;
}

ul {
  display: flex;
  justify-content: center;
}

.no-data-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $dashboard-min-card-height;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}
</style>
