<script setup lang="ts">
const props = defineProps<{
  items?: string[];
  type?: "primary";
}>();
</script>

<template>
  <ul
    v-if="items"
    class="text-base font-medium text-gray-700 flex flex-row justify-items-center flex-wrap gap-2 gap"
  >
    <li v-for="item in props.items" :key="item" class="list-none inline-block">
      <lxc-tag :type="props.type">
        {{ item }}
      </lxc-tag>
    </li>
  </ul>
</template>
