<script setup lang="ts">
import { EMPTY_PLACEHOLDER } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";

export interface LxcDtwinsDescriptionGeolocationI {
  address: string;
  latitude: string;
  longitude: string;
  isAllowedToEdit: boolean;
  doesUpdateSucceed: boolean | undefined;
}

interface Props {
  modelValue: LxcDtwinsDescriptionGeolocationI;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:address"]);

const { t } = useI18n();
const address: Ref<string> = ref(props.modelValue.address);
const isEditing: Ref<boolean> = ref(false);
const isSending: Ref<boolean> = ref(false);
const latLongRepresentation: Ref<string> = ref(
  t("dtwins.form.description.geolocation.attributes.latlong", {
    latitude: props.modelValue.latitude,
    longitude: props.modelValue.longitude,
  }),
);
const isEdited: ComputedRef<boolean> = computed(
  () => address.value !== props.modelValue.address,
);

// When the request to the backend succeed, reset the editing flag to true
watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue.doesUpdateSucceed) {
      isEditing.value = false;
    }
    isSending.value = false;
  },
);

function onEdit(): void {
  isEditing.value = !isEditing.value;
}

function onSave(): void {
  isSending.value = true;
  emit("update:address", address.value);
}

function onCancel(): void {
  isEditing.value = false;
  address.value = props.modelValue.address;
}

// activate the leave page confirm message if edited
watch(
  () => isEdited.value,
  (isEdited) => useConfirmLeavePageStore().activate(isEdited),
);
</script>

<template>
  <LxcDescriptionContainer
    :title="t(`dtwins.form.description.geolocation.title`)"
  >
    <template #left>
      <lxc-form-item
        :label="t('dtwins.form.description.geolocation.attributes.address')"
      >
        <lxc-input
          v-model="address"
          type="text"
          :placeholder="EMPTY_PLACEHOLDER"
          :disabled="!isEditing"
          style="padding-bottom: 10px"
        />
        <lxc-button
          v-if="!isEditing"
          :disabled="!modelValue.isAllowedToEdit"
          :title="t('button.edit')"
          type="secondary"
          @click="onEdit"
        >
          {{ t("button.edit") }}
        </lxc-button>
        <div v-if="isEditing" class="flex gap-2.5">
          <lxc-button
            :disabled="!isEdited"
            :title="t('button.validate')"
            @click="onSave"
          >
            <div class="flex relative">
              {{ t("button.validate") }}
              <lxc-loader v-if="isSending" class="ml-2" :size="5" />
            </div>
          </lxc-button>
          <lxc-button
            type="secondary"
            :title="t('button.cancel')"
            @click="onCancel"
          >
            {{ t("button.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.geolocation.attributes.status')"
      >
        <lxc-input v-model="latLongRepresentation" type="text" read-only />
      </lxc-form-item>
    </template>
  </LxcDescriptionContainer>
</template>
