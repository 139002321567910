<script setup lang="ts">
import { Origin } from "@lxc/app-device-types";
import ILxcCpu from "~icons/lxc/cpu";
import ILxcLink from "~icons/lxc/link";
import ILxcMonitor from "~icons/lxc/monitor";
import ILxcSmartphone from "~icons/lxc/smartphone";

interface Props {
  modelValue: Origin;
}
const props = defineProps<Props>();

const { t } = useI18n();

const tooltip: ComputedRef<string> = computed(() => {
  switch (props.modelValue) {
    case Origin.MOBILE_APPLICATION:
      return `${t("logs.origin.values.mobileApplication")}`;
    case Origin.DEVICE:
      return `${t("logs.origin.values.device")}`;
    case Origin.THIRD_PARTY_APPLICATION:
      return `${t("logs.origin.values.thirdPartyApplication")}`;
    default:
      return `${t("logs.origin.values.lxConnect")}`;
  }
});

const iconClasses = ["h-[1.344rem]", "w-[1.344rem]", "text-gray-700"];
</script>

<template>
  <span :title="tooltip" class="block mx-auto">
    <ILxcSmartphone
      v-if="modelValue === Origin.MOBILE_APPLICATION"
      :class="iconClasses"
    />
    <ILxcCpu v-else-if="modelValue === Origin.DEVICE" :class="iconClasses" />
    <ILxcLink
      v-else-if="modelValue === Origin.THIRD_PARTY_APPLICATION"
      :class="iconClasses"
    />
    <ILxcMonitor v-else :class="iconClasses" />
  </span>
</template>
