<script setup lang="ts">
import {
  computeCampaignStats,
  DisplayedStatistic,
} from "../campaignDetail/campaignOperationManagerDetail/campaignStatisticsComputation.utils";
import { CampaignOperationMgrI } from "@lxc/app-device-types";
import { useCampaignOperationMgr } from "~/composables/useCampaignOperationMgr";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";

const {
  isLoading,
  error,
  results,
  filters,
  fetchData,
  setFilter,
  search,
  onSearch,
} = useCampaignOperationMgr();

const { t } = useI18n();
const router = useRouter();

async function loadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
}

async function onSearchClear() {
  setFilter(Filters.NAME, "");
  search();
}

const onSelectCampaign = (row: CampaignOperationMgrI) => {
  router.push(`${PATHS.CAMPAIGNS}/${row.uid}`);
};

onSearch(loadData);
</script>
<template>
  <div class="mb-4">
    <lxc-search-bar
      :search-query="filters.get(Filters.NAME)"
      :search-placeholder="t('filters.searchByName')"
      @clear="onSearchClear"
      @input-change="setFilter(Filters.NAME, $event)"
      @search="search"
    />
  </div>
  <lxc-table
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="results?.data"
    :context="results?.context"
    :empty-text="t('campaign.list.empty')"
    clickable
    @row-click="onSelectCampaign"
    @change-page-and-page-size="loadData"
  >
    <lxc-table-column
      prop="name"
      :label="t('campaign.list.column.name.label')"
      max-characters="22"
    />
    <lxc-table-column
      prop="dates"
      :label="t('campaign.list.column.dates.label')"
      width="200"
    >
      <template #default="scope">
        {{
          formatIsoDate(
            scope.row.launchPlannedAt,
            t("campaign.list.column.dates.format"),
          )
        }}
        →
        {{
          formatIsoDate(
            scope.row.expiredAt,
            t("campaign.list.column.dates.format"),
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="deviceCount"
      :label="t('campaign.list.column.numberOfEquipments.label')"
      width="150"
    >
      <template #default="scope">
        {{
          t(
            "campaign.list.column.numberOfEquipments.value",
            scope.row.deviceCount,
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="status"
      :label="t('campaign.list.column.status.label')"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-campaign-operation-mgr-status-badge :status="scope.row.status" />
      </template>
    </lxc-table-column>
    <!-- ! Not yet defined in backend -> TO BE MODIFIED -->
    <lxc-table-column
      prop="operationStatistics.pending"
      :label="t('campaign.list.column.pending.label')"
      width="100"
    >
      <template #default="scope">
        {{
          computeCampaignStats(
            scope.row.operationStatistics,
            DisplayedStatistic.PENDING,
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="operationStatistics.inProgress"
      :label="t('campaign.list.column.inProgress.label')"
      width="100"
    >
      <template #default="scope">
        {{
          computeCampaignStats(
            scope.row.operationStatistics,
            DisplayedStatistic.IN_PROGRESS,
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="operationStatistics.success"
      :label="t('campaign.list.column.success.label')"
      width="100"
    >
      <template #default="scope">
        {{
          computeCampaignStats(
            scope.row.operationStatistics,
            DisplayedStatistic.SUCCESS,
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="operationStatistics.failed"
      :label="t('campaign.list.column.failed.label')"
      width="100"
    >
      <template #default="scope">
        {{
          computeCampaignStats(
            scope.row.operationStatistics,
            DisplayedStatistic.FAILED,
          )
        }}
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
