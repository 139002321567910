<script setup lang="ts">
const props = defineProps({
  showConfirm: {
    type: Boolean,
    default: true,
  },
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
  },
  disabledConfirm: {
    type: Boolean,
    required: false,
  },
});
defineEmits(["update:dialogVisible", "open", "opened", "cancel", "confirm"]);

const { t } = useI18n();
</script>

<template>
  <el-dialog
    :model-value="dialogVisible"
    :width="props.width ?? '30%'"
    :append-to-body="true"
    @open="$emit('open')"
    @opened="$emit('opened')"
    @update:model-value="$emit('update:dialogVisible', $event)"
  >
    <h3>
      {{ props.title }}
    </h3>

    <slot />

    <template #footer>
      <div class="px-5 grid grid-cols-2 gap-x-6 bg-white box-border">
        <lxc-button
          type="secondary"
          html-type="button"
          data-cy="cancel-modal-button"
          :title="t('campaign.cancel')"
          @click="$emit('cancel')"
        >
          {{ t("campaign.cancel") }}
        </lxc-button>
        <lxc-button
          v-if="showConfirm"
          type="primary"
          data-cy="confirm-modal-button"
          :title="t('campaign.confirm')"
          :disabled="disabledConfirm"
          @click="$emit('confirm')"
        >
          {{ t("campaign.confirm") }}
        </lxc-button>
      </div>
    </template>
  </el-dialog>
</template>
