<script setup lang="ts">
import { CampaignState } from "@lxc/app-device-types";

const props = defineProps<{
  state?: string;
}>();

const { t } = useI18n();

// These colors must be validated
function campaignStatusColor(state: string | undefined) {
  switch (state) {
    case CampaignState.RUNNING:
    case CampaignState.COMPLETE:
      return "--rounded-icon-bg:#E7F7D0; --rounded-icon-border-color: #5F7349"; // green
    case CampaignState.COMPLETE_WITH_ERRORS:
    case CampaignState.INCOMPLETE:
    case CampaignState.PLATFORM_ERROR:
      return "--rounded-icon-bg:#FEEEEE; --rounded-icon-border-color: #C4314B"; // red
    case CampaignState.SCHEDULED:
    case CampaignState.PAUSED:
    case CampaignState.CANCELLING:
    case CampaignState.CANCELLED:
      return "--rounded-icon-bg:#FFEBDA; --rounded-icon-border-color:#B36F37"; // orange
  }
}
</script>

<template>
  <div class="row-container">
    <div class="round-icon" :style="campaignStatusColor(state)" />
    <p>{{ t(`campaign.list.column.status.value.${props.state}`) }}</p>
  </div>
</template>

<style scoped lang="scss">
.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .round-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--rounded-icon-bg);
    border: 1px solid var(--rounded-icon-border-color);
    margin-right: 1rem;
  }
  p {
    margin: 0;
  }
}
</style>
