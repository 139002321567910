<script setup lang="ts">
import { type UniqueLabel, useSerialize } from "@lxc/app-device-common";
import type { KeystoreCertificateDetailI } from "@lxc/app-device-types";
import { CACertificateItemType } from "@lxc/app-device-types";
import type { Rules } from "async-validator";
import type { Ref, WritableComputedRef } from "vue";
import { computed, ref } from "vue";
import { VALIDATION_NUMBER, VALIDATION_REGEXP } from "~/constants/constants";
import keystoreService from "~/services/keystore.service";
import type { KeystoreCertificateDetailUpdateForm } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import {
  getUniqueLabelList,
  getValuesFromUniqueLabels,
} from "~/utils/unique-label-tools";
import uploadUtils from "~/utils/upload.utils";
import ILxcAlertCircle from "~icons/lxc/alert-circle";

const props = defineProps<{
  alias?: string | null;
  disabled?: boolean;
  isTagsLoading: boolean;
  sideCanvasShown: boolean;
  tags?: Array<string> | null;
  tagsError?: LxcError | null;
}>();

const emit = defineEmits(["close", "save", "update:sideCanvasShown"]);

const { t } = useI18n();
const serialize = useSerialize();

// File must be less than 750MB
const MAX_FILE_SIZE_BYTES_ALLOWED = 750_000_000;

const defaultCertificateForm: KeystoreCertificateDetailUpdateForm = {
  alias: "",
  subject: {
    cn: "",
    email: "",
    serialNumber: undefined,
    organizationUnit: undefined,
    organization: undefined,
    locality: undefined,
    region: undefined,
    country: undefined,
  },
  tags: [],
  type: CACertificateItemType.CERTIFICATE,
};

const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown;
  },
  set(sideCanvasShown: boolean) {
    emit("update:sideCanvasShown", sideCanvasShown);
  },
});

// deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
const certificateForm: Ref<KeystoreCertificateDetailUpdateForm> =
  ref<KeystoreCertificateDetailUpdateForm>({
    ...defaultCertificateForm,
    subject: { ...defaultCertificateForm.subject },
    tags: [...defaultCertificateForm.tags],
  });
const certificateFormRef: Ref = ref();
const files: Ref<FileList | undefined | null> = ref();
const fileProgress: Ref<number | undefined | null> = ref();
const filesUploadError: Ref<Error | undefined | null> = ref();
const isFileError: ComputedRef<boolean> = computed(
  () => filesUploadError.value instanceof Error,
);
const initCertificateForm: Ref<KeystoreCertificateDetailI | undefined | null> =
  ref();
const initCertificateFormStringified: Ref<string> = ref(
  serialize(defaultCertificateForm),
);
const isCertificateLoading = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const loadingCertificateError: Ref<LxcError | null | undefined> = ref();
const loadingErrorVisible: ComputedRef<boolean> = computed(() =>
  LxcError.check(loadingCertificateError.value),
);
const isCsr = computed(
  () => certificateForm.value.type === CACertificateItemType.CSR,
);
const errorHeader: ComputedRef<string> = computed(() =>
  isCsr.value
    ? t("certificates.keystore.error.csr.loading")
    : t("certificates.error.certificate.loading"),
);
const edited: ComputedRef<boolean> = computed(() => {
  const stringifiedForm = serialize(certificateForm.value);
  return (
    stringifiedForm !== initCertificateFormStringified.value ||
    (files.value != null && files.value.length !== 0)
  );
});
const rules: ComputedRef<Rules> = computed(() => {
  const rulesConfig: Rules = {
    alias: [
      {
        required: true,
        message: t("certificates.validation.alias.required"),
        type: "string",
        whitespace: false,
      },
      {
        type: "string",
        message: t("certificates.validation.alias.invalid"),
        pattern: VALIDATION_REGEXP.CERTIFICATE_ALIAS,
      },
    ],
    "subject.cn": [
      {
        required: true,
        message: t("certificates.validation.cn"),
        type: "string",
        whitespace: false,
      },
    ],
    "subject.email": [
      {
        required: true,
        message: t("certificates.validation.email"),
        whitespace: false,
      },
      {
        max: VALIDATION_NUMBER.EMAIL_MAX_LENGTH,
        message: t("input.error.maxLength", {
          maxLength: VALIDATION_NUMBER.EMAIL_MAX_LENGTH,
        }),
      },
      {
        type: "email",
        message: t("input.error.invalidFormat"),
      },
    ],
  };

  return rulesConfig;
});

function cloneCertificateForm(
  targetCertificateForm: Ref<KeystoreCertificateDetailUpdateForm>,
  certificateDetailForm?: KeystoreCertificateDetailI | null,
) {
  const certificateTags: UniqueLabel[] | undefined = getUniqueLabelList(
    certificateDetailForm?.tags,
    props.disabled,
  );
  Object.assign(
    targetCertificateForm.value,
    certificateDetailForm ?? defaultCertificateForm,
    {
      subject: {
        ...(certificateDetailForm?.subject ?? defaultCertificateForm.subject),
      },
      tags: [...(certificateTags ?? defaultCertificateForm.tags)],
    },
  );
}

function setCertificateDetailForm(
  certificateDetailForm?: KeystoreCertificateDetailI | null,
) {
  files.value = files.value !== undefined ? null : undefined;
  clearCertificateUploadStatus();
  cloneCertificateForm(certificateForm, certificateDetailForm);
  initCertificateForm.value = certificateDetailForm;
  initCertificateFormStringified.value = serialize(certificateForm.value);
  // set timeout to be sure that the clearValidate is processed after updating the form inputs
  setTimeout(certificateFormRef.value?.clearValidate, 0);
}

/**
 * Check if the file can be uploaded, show an error if not
 * @param file File to check
 * @return true if the file can be uploaded, false otherwise
 */
function canUploadFile(file?: File): boolean {
  let error: string | undefined;

  if (file === undefined) {
    return false;
  }

  if (!uploadUtils.isCertificateFile(file)) {
    error = "extension";
  } else if (!file.size) {
    error = "fileEmpty";
  } else if (file.size > MAX_FILE_SIZE_BYTES_ALLOWED) {
    error = "fileSize";
  }

  if (error) {
    filesUploadError.value = new Error(
      t(`certificates.validation.certificate.${error}`),
    );
  } else {
    filesUploadError.value =
      filesUploadError.value !== undefined ? null : undefined;
  }

  return !error;
}

function clearCertificateUploadStatus() {
  filesUploadError.value =
    filesUploadError.value !== undefined ? null : undefined;
  fileProgress.value = fileProgress.value !== undefined ? null : undefined;
}

async function getCertificateDetail(): Promise<void> {
  if (props.alias) {
    isCertificateLoading.value = true;
    const response = await keystoreService.getCertificate(props.alias);

    if (LxcError.check(response)) {
      loadingCertificateError.value = response;
    } else {
      loadingCertificateError.value = null;
      setCertificateDetailForm(response);
    }

    isCertificateLoading.value = false;
  }
}

async function saveForm(): Promise<void> {
  isSaving.value = true;
  const file: File | undefined = files.value ? files.value[0] : undefined;
  const tags: string[] =
    getValuesFromUniqueLabels(certificateForm.value.tags) ?? [];
  certificateForm.value.alias = certificateForm.value.alias.trim();
  const response = await keystoreService.updateCertificate(
    certificateForm.value.alias,
    fileProgress,
    tags,
    file,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError);
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    certificateForm.value.type = CACertificateItemType.CERTIFICATE;
    await getCertificateDetail();
    emit("save");
  }

  isSaving.value = false;
}

async function validate(): Promise<boolean> {
  let isFormValid: boolean =
    !files?.value?.length || canUploadFile(files?.value[0]);
  isFormValid =
    isFormValid ||
    (await certificateFormRef.value?.validate().catch(() => false));
  return isFormValid;
}

const onSubmit = async (): Promise<boolean> => {
  const dataValid = await validate();

  if (dataValid) {
    await saveForm();
  }

  return dataValid;
};

function close() {
  formSideCanvasShown.value = false;
  emit("close");
}

const onCancel = () => {
  close();
  setCertificateDetailForm();
};

const onPropsFormCanvasShowChange = (shown: boolean) => {
  if (shown) {
    getCertificateDetail();
  }
};

const onFileUpload = (input: FileList | undefined) => {
  files.value = input;
};

watch(() => formSideCanvasShown.value, onPropsFormCanvasShowChange);
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    :header="t('certificates.keystore.updateCertificate')"
    :close-tooltip="t('button.close')"
    @discard="onCancel"
  >
    <div v-if="isCertificateLoading" class="w-full h-5 inset-0 z-10">
      <div class="flex flex-col items-center h-5 justify-center">
        <lxc-loader :size="20" />
      </div>
    </div>
    <lxc-form
      v-else
      ref="certificateFormRef"
      :model="certificateForm"
      :rules="rules"
      @submit.prevent="onSubmit"
    >
      <lxc-alert
        v-if="loadingErrorVisible"
        :icon="ILxcAlertCircle"
        type="error"
      >
        <template #title>
          {{ errorHeader }}
        </template>
        <p>{{ loadingCertificateError?.toError()?.message ?? "" }}</p>
      </lxc-alert>

      <div class="pb-4">
        <LxcKeystoreCsrFileHandler
          :model-value="files"
          :is-csr="isCsr"
          :alias="alias"
          :is-input-disabled="disabled"
          :upload-error="filesUploadError"
          :upload-progress="fileProgress"
          :on-blur="clearCertificateUploadStatus"
          @update:model-value="onFileUpload"
        ></LxcKeystoreCsrFileHandler>
      </div>

      <lxc-keystore-generic-generation-form
        v-if="!isCertificateLoading"
        v-model="certificateForm"
        :disabled="disabled"
        :edition="true"
        :is-tags-loading="isTagsLoading"
        :tags="tags"
        :tags-error="tagsError"
      />
    </lxc-form>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.close')"
          @click="onCancel"
        >
          {{ t("button.close") }}
        </lxc-button>
        <lxc-button
          html-type="submit"
          :disabled="!edited || isFileError || isSaving"
          :title="t('certificates.keystore.updateCertificate')"
          @click="onSubmit"
        >
          {{ t("certificates.keystore.updateCertificate") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
