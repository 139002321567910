import { useExportAsString } from "@lxc/app-device-common";
import { CACertificateItemType } from "@lxc/app-device-types";

const { exportString } = useExportAsString();

export function useExportCertificateAsString() {
  function exportCertificateAsString(
    base64value: string,
    filename: string,
    type: CACertificateItemType,
  ) {
    let extension: string, mime: string;

    if (type === CACertificateItemType.CSR) {
      extension = "csr";
      mime = "application/pkcs10; charset=utf-8";
    } else {
      extension = "pem";
      mime = "application/x-pem-file; charset=utf-8";
    }

    exportString(base64value, filename, extension, mime);
  }

  return {
    exportCertificateAsString,
  };
}
