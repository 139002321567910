import type {
  ApplicationModelConfigurationI,
  DeviceModelConfigurationI,
} from "@lxc/app-device-types";
import AppsService from "~/services/application.service";
import DeviceService from "~/services/device.service";
import LxcError from "~/utils/LxcError";

export interface ICertificateConfiguration {
  isLoading: Ref<boolean>;
  error: Ref<LxcError | null | undefined>;
  appsConfig: Ref<ApplicationModelConfigurationI[] | null | undefined>;
  devicesConfig: Ref<DeviceModelConfigurationI[] | null | undefined>;
  fetchAppsConfig: () => void;
  fetchDeviceConfig: () => void;
  resetAppsConfig: () => void;
  resetDevicesConfig: () => void;
  onAppsConfigSaved: () => void;
  onDeviceConfigSaved: () => void;
}

export function useCertificateConfiguration(): ICertificateConfiguration {
  const isLoading = ref(false);
  const appsConfig = ref<ApplicationModelConfigurationI[] | undefined | null>();
  const devicesConfig = ref<DeviceModelConfigurationI[] | undefined | null>();
  let appsConfigBackup: ApplicationModelConfigurationI[] | null = null;
  let deviceConfigBackup: DeviceModelConfigurationI[] | null = null;

  const error = ref<LxcError | null | undefined>(null);

  function cloneDevicesConfig(
    paramDevicesConfig?: DeviceModelConfigurationI[] | null,
  ): DeviceModelConfigurationI[] | null {
    return (
      paramDevicesConfig?.map((deviceConfig: DeviceModelConfigurationI) => ({
        ...deviceConfig,
      })) ?? null
    );
  }

  function cloneAppsConfig(
    paramAppsConfig?: ApplicationModelConfigurationI[] | null,
  ): ApplicationModelConfigurationI[] | null {
    return (
      paramAppsConfig?.map((appConfig: ApplicationModelConfigurationI) => ({
        ...appConfig,
      })) ?? null
    );
  }

  async function fetchAppsConfig() {
    isLoading.value = true;
    const response = await AppsService.getAppsConfiguration();

    if (LxcError.check(response)) {
      appsConfig.value = null;
      appsConfigBackup = null;
      error.value = response;
    } else {
      appsConfig.value = response?.configs;
      appsConfigBackup = cloneAppsConfig(response?.configs);
    }
    isLoading.value = false;
  }

  async function fetchDeviceConfig() {
    isLoading.value = true;
    const response = await DeviceService.getDeviceConfiguration();

    if (LxcError.check(response)) {
      devicesConfig.value = null;
      deviceConfigBackup = null;
      error.value = response;
    } else {
      devicesConfig.value = response?.configs;
      deviceConfigBackup = cloneDevicesConfig(response?.configs);
    }
    isLoading.value = false;
  }

  function resetAppsConfig() {
    appsConfig.value = cloneAppsConfig(appsConfigBackup);
  }

  function resetDevicesConfig() {
    devicesConfig.value = cloneDevicesConfig(deviceConfigBackup);
  }

  function onAppsConfigSaved() {
    appsConfigBackup = cloneAppsConfig(appsConfig.value);
  }

  function onDeviceConfigSaved() {
    deviceConfigBackup = cloneAppsConfig(devicesConfig.value);
  }

  return {
    isLoading,
    error,
    appsConfig,
    devicesConfig,
    fetchAppsConfig,
    fetchDeviceConfig,
    resetAppsConfig,
    resetDevicesConfig,
    onAppsConfigSaved,
    onDeviceConfigSaved,
  };
}
