<script setup lang="ts">
import { ref } from "vue";
import ILxcChevronDown from "~icons/lxc/chevron-down";
import ILxcChevronRight from "~icons/lxc/chevron-right";

interface Props {
  /** If set to true, toggle the container. Else, display the slot content. */
  modelValue?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: true,
});

const { t } = useI18n();

const isToggled = ref(props.modelValue);
const onToggle = () => {
  isToggled.value = !isToggled.value;
};
</script>

<template>
  <div class="flex justify-between clickable select-none" @click="onToggle">
    <span class="font-bold">{{ t("input.information.more") }}</span>
    <ILxcChevronRight v-if="isToggled"></ILxcChevronRight>
    <ILxcChevronDown v-else></ILxcChevronDown>
  </div>
  <div v-if="!isToggled" class="pt-2">
    <slot />
  </div>
</template>
