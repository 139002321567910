<script setup lang="ts">
import type { Ref } from "vue";
import type { DeviceTypeFilter } from "~/composables/useDeviceTypeFilters";
import { useDeviceTypeFilters } from "~/composables/useDeviceTypeFilters";

const { t } = useI18n();

const props = defineProps<{
  modelValue: DeviceTypeFilter[];
}>();
const emit = defineEmits(["select", "update:modelValue"]);

const filters: Ref<DeviceTypeFilter[]> = ref([]);
const isLoading = ref(true);
const selectedItems = computed({
  get() {
    return props.modelValue ?? [];
  },
  set(selected?: DeviceTypeFilter[]) {
    emit("update:modelValue", selected ?? []);
    emit("select", selected ?? []);
  },
});
onMounted(async () => {
  const { deviceTypeFilters } = await useDeviceTypeFilters();
  filters.value = deviceTypeFilters.value;
  isLoading.value = false;
});
</script>

<template>
  <LxcFilterSelectableList
    v-model="selectedItems"
    :data="filters"
    :is-loading="isLoading"
    :empty-text="t('logs.filters.devices-dvtm-esoft.type.empty')"
    :header="t('device.type')"
    prop="label"
    item-prop="value"
    use-radio-buttons
  />
</template>
