<script setup lang="ts">
import type {
  CampaignType,
  DeviceI,
  UserSessionSector,
} from "@lxc/app-device-types";
import type { ElTable } from "element-plus";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { useDevices } from "~/composables/useDevices";
import type { SearchMode } from "~/composables/useSearch";
import { PATHS } from "~/constants/paths";
import { router } from "~/plugins/router";
import SectorsService from "~/services/sectors.service";
import { useSectorStore } from "~/stores/useSectorStore";
import type { FilterOptions } from "~/types";
import { Filters } from "~/types";

const props = defineProps<{
  noAction?: boolean;
  selectedDevices?: Array<DeviceI>;
  defaultFilters?: Map<Filters, any>;
  campaignType?: CampaignType;
  searchMode?: SearchMode;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
  useQueryParametersForPagination?: boolean;
}>();

const emit = defineEmits(["update:selectedDevices"]);

const tableRef = ref<InstanceType<typeof ElTable>>();

const { t } = useI18n();
const {
  isLoading,
  results: devices,
  filters,
  error,
  fetchData,
  setFilter,
  getHasActionStatusLabel,
  search,
  onSearch,
} = useDevices(props.searchMode, props.useQueryParametersForPagination);

const { selectedSectors } = storeToRefs(useSectorStore());

const currentPageSectors = ref<UserSessionSector[]>([]);

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => executeSearch(true));

const rowSelection: Ref<Array<DeviceI>> = ref([]);

/**
 * Select devices
 */
const setSelection = () => {
  if (props.selectedDevices !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedDevices.some(
          (selectedDevice) => selectedDevice.id === row.id,
        ),
      );
    }

    rowSelection.value = props.selectedDevices || [];
  }
};

watch(() => props.selectedDevices, setSelection);

function executeSearch(replaceHistory?: boolean) {
  setFilter(
    Filters.SECTORS,
    selectedSectors.value.map((sector) => sector.code),
  );

  // call search in order to update the query parameters with filters
  search(replaceHistory);
}

/**
 * Retrieve devices and sectors
 * @param page
 * @param pageSize
 */
async function loadData(page?: number, pageSize?: number) {
  const params = new Map();
  params.set("campaignType", props.campaignType);
  await fetchData(page, pageSize, params);
  await fetchDevicesSectors();

  setSelection();
}

/**
 * Retrieve all sectors matching devices
 */
async function fetchDevicesSectors() {
  const sectorCodes: string[] = (devices.value?.data ?? []).map(
    (device: DeviceI) => device.sectorCode!,
  );

  if (sectorCodes.length) {
    currentPageSectors.value =
      await SectorsService.getSectorsByCodes(sectorCodes);
  }
}

/**
 * Calcul and emit the selected devices
 */
const handleSelect = (handledSelected: DeviceI[]) => {
  // Keep the selected devices which were selected on another page and not present in handled selected
  emit(
    "update:selectedDevices",
    props.selectedDevices
      ?.filter(
        (selectedDevice: DeviceI) =>
          !tableRef.value ||
          !tableRef.value.data.find((row) => row.id === selectedDevice.id),
      )
      .filter(
        (selectedDevice: DeviceI) =>
          !handledSelected.find((row) => row.id === selectedDevice.id),
      )
      .concat(handledSelected),
  );
};

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

const onSelectDevice = (app: DeviceI) => {
  router.push(`${PATHS.DEVICES_DVTM_ESOFT}/${app.id}`);
};

const searchQuery = ref<string>((filters.get(Filters.NAME) ?? "") as string);
const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.NAME, searchQuery.value);
};

function reloadWithContext() {
  loadData(devices.value?.context?.page, devices.value?.context?.pageSize);
}

onMounted(() => {
  onSearch(loadData, false);
  executeSearch(true);
});
</script>

<template>
  <lxc-search-bar
    :search-query="searchQuery"
    :search-placeholder="t('filters.searchByName')"
    class="grow shrink basis-0 mt-8"
    @clear="
      (_) => {
        setFilter(Filters.NAME, '');
        search();
      }
    "
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-device-filters
    :filters="filters"
    :type-options="typeOptions"
    :model-options="modelOptions"
    :state-options="stateOptions"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    :is-loading="isLoading"
    :data="devices?.data"
    :empty-text="t('device.empty')"
    :context="devices?.context"
    data-cy="device-table"
    :error="error?.toError()"
    :clickable="true"
    @change-page-and-page-size="loadData"
    @row-click="onSelectDevice"
    @select="handleSelect"
    @select-all="handleSelect"
  >
    <lxc-table-column
      v-if="selectedDevices !== undefined"
      type="selection"
      width="55"
    />
    <lxc-table-column
      class-name="no-no-wrap"
      prop="name"
      :label="t('device.name')"
    />
    <lxc-table-column prop="model.type" :label="t('device.type')" />
    <lxc-table-column prop="model.declination" :label="t('device.model')" />
    <lxc-table-column prop="serialNumber" :label="t('device.serialNumber')" />
    <lxc-table-column
      prop="connectivity.state"
      :label="t('device.connectivity')"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-connectivity-status
          :connectivity-state="scope.row.connectivity.state"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('device.certificate')"
      prop="certificateExpireBefore"
      class="!py-0"
      sortable="custom"
    >
      <template #default="scope">
        <lxc-certificate-status :certificate="scope.row.certificate" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="firmwareVersion"
      :label="t('device.firmwareVersion')"
    />
    <lxc-table-column :label="t('device.state')">
      <template #default="scope">
        {{ scope.row.state ? t(`device.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="campaignType"
      :label="t('device.actions.scheduled.title')"
    >
      <template #default="scope">
        <span>{{ getHasActionStatusLabel(scope.row, campaignType) }}</span>
      </template>
    </lxc-table-column>
    <lxc-table-column v-if="!noAction" class="w-16 !py-0">
      <template #default="scoped">
        <lxc-device-actions
          :key="scoped.row.id"
          :is-activated="scoped.row.state"
          :device="scoped.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>

  <div class="mt-4">
    <el-select
      v-if="rowSelection?.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :teleported="false"
      @change="$emit('update:selectedDevices', rowSelection)"
    >
      <el-option
        v-for="device in rowSelection"
        :key="device.id"
        :label="device.name"
        :value="device"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
