<script lang="ts" setup>
import type { TreeViewDataObject } from "@lxc/app-device-common";
import type { TruststoreChainLinkOfTrustI } from "@lxc/app-device-types";
import { formatIsoDate } from "~/utils/date-tools";

const { t } = useI18n();
const props = defineProps<{
  data: Array<TruststoreChainLinkOfTrustI>;
}>();

const dateFormat = t("dateFormat.datetime");

const treeViewData: ComputedRef<Array<TreeViewDataObject>> = computed(() => {
  const vTreeViewData: Array<TreeViewDataObject> = [];
  let parentTreeViewData: TreeViewDataObject | undefined;
  let level = 0;

  for (const currentChainLink of props.data) {
    const levelLabel = t("certificates.trustChain.level", { level });
    const vCurrentTreeViewData: TreeViewDataObject = {
      id: level,
      label:
        level === 0
          ? currentChainLink.cn
          : `${currentChainLink.cn} (${levelLabel})`,
      children: [
        {
          id: `${level}_0`,
          label: `${t("certificates.start.timestamp.labelColon")} ${formatIsoDate(currentChainLink.validFrom, dateFormat)}`,
          children: [],
        },
        {
          id: `${level}_1`,
          label: `${t("certificates.expiration.timestamp.labelColon")} ${formatIsoDate(currentChainLink.validTo, dateFormat)}`,
          children: [],
        },
      ],
    };

    if (level === 0) {
      vTreeViewData.push(vCurrentTreeViewData);
    } else if (parentTreeViewData) {
      parentTreeViewData.children.push(vCurrentTreeViewData);
    }

    parentTreeViewData = vCurrentTreeViewData;
    level++;
  }

  return vTreeViewData;
});
</script>
<template>
  <div
    v-if="treeViewData"
    class="bg-gray-50 border border-solid border-gray-300 p-1 overflow-x-hidden"
  >
    <lxc-tree-view :data="treeViewData" :expanded="true" />
  </div>
</template>
