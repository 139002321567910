<script setup lang="ts">
import { FeaturesName } from "~/types/deviceFeatures";
</script>
<template>
  <lxc-container>
    <lxc-license-warning-messages :context="FeaturesName.ADVANCED_FLEET_MGT" />
    <lxc-breadcrumb class="mb-8" />
    <lxc-applications-list use-query-parameters-for-pagination />
  </lxc-container>
</template>
