<script setup lang="ts">
import type { Ref } from "vue";
import { computed, onMounted, ref } from "vue";
import campaignService from "~/services/campaign.service";
import LxcError from "~/utils/LxcError";
import { formatIsoDate } from "~/utils/date-tools";

const { t } = useI18n();
const route = useRoute();

const id = route.params.id.toString();

const isLoading = ref(false);
const error: Ref<LxcError | null> = ref(null);

const report: Ref<any> = ref(null);

const fetchReport = async () => {
  isLoading.value = true;

  const response = await campaignService.getCampaignReport(id);
  if (LxcError.check(response)) {
    error.value = response;
  } else {
    report.value = response;
  }

  isLoading.value = false;
};

const campaigns = computed(() => report.value?.campaignReports || []);

onMounted(fetchReport);
</script>

<template>
  <lxc-breadcrumb
    :name="
      t('campaign.report.title') +
      (report
        ? ' - ' + formatIsoDate(report.createdAt, t('dateFormat.datetime'))
        : '')
    "
  />

  <lxc-container :is-loading="isLoading" :error="error">
    <div v-for="(campaign, c) of campaigns" :key="c">
      <h4>{{ campaign.name }}</h4>
      <p>
        {{ t("campaign.report.plannedStartAt") }}&nbsp;{{
          formatIsoDate(campaign.plannedStartAt, t("dateFormat.datetime"))
        }}
      </p>

      <lxc-table class="mb-6" :data="campaign.processes">
        <lxc-table-column
          :label="t('campaign.report.processes.column.label')"
          prop="label"
        />
        <lxc-table-column
          :label="t('campaign.report.processes.column.status')"
          prop="status"
        >
          <template #default="scope">
            <lxc-operation-state :state="scope.row.state" />
          </template>
        </lxc-table-column>
      </lxc-table>
    </div>
  </lxc-container>
</template>
