<script lang="ts" setup>
import { userTabsItems } from "~/components/userManagement/tab-user-management.config";
import { PATHS } from "~/constants/paths";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const tabItems = userTabsItems;

const usersTabKey = "usersTab";
const groupsTabKey = "groupsTab";
const profilesTabKey = "profilesTab";
const usersTabIndex = 0;
const groupsTabIndex = 1;
const profilesTabIndex = 2;

const selectedTab: Ref<string> = ref(usersTabKey);
const profileInterfaceShown: Ref<boolean> = ref(false);

async function onTabSelected(_: number, name?: string) {
  selectedTab.value = name ?? "";
  if (name === profilesTabKey) {
    await router.push(PATHS.USER_MANAGEMENT_PROFILES);
  } else if (selectedTab.value === groupsTabKey) {
    await router.push(PATHS.USER_MANAGEMENT_GROUPS);
  } else {
    await router.push(PATHS.USER_MANAGEMENT);
  }
}

const isUsersTabSelected: ComputedRef<boolean> = computed(
  (): boolean => computeTabIndexFunctionOfRoute() === usersTabIndex,
);

const isGroupsTabSelected: ComputedRef<boolean> = computed(
  (): boolean => computeTabIndexFunctionOfRoute() === groupsTabIndex,
);

const isProfilesTabSelected: ComputedRef<boolean> = computed(
  (): boolean => computeTabIndexFunctionOfRoute() === profilesTabIndex,
);

async function onCreateUser() {
  await router.push(PATHS.USER_MANAGEMENT_USERS_NEW);
}

async function onCreateUserGroup() {
  await router.push(PATHS.USER_MANAGEMENT_GROUPS_NEW);
}

/**
 * Map the route path to the index selected in LxcTabs.
 * This is useful since the LxcTabs component does not manage changing pages but each time we press Tab we push a new Route
 */
const defaultTabIndex: ComputedRef<number> = computed((): number => {
  return computeTabIndexFunctionOfRoute();
});

function computeTabIndexFunctionOfRoute() {
  if (route.path.startsWith(PATHS.USER_MANAGEMENT_PROFILES)) {
    return profilesTabIndex;
  } else if (route.path.startsWith(PATHS.USER_MANAGEMENT_GROUPS)) {
    return groupsTabIndex;
  }
  return usersTabIndex;
}

function onCreateProfile() {
  onUpdateInterfaceFormShown(true);
}

const onUpdateInterfaceFormShown = (show: boolean) => {
  profileInterfaceShown.value = show;
};
</script>

<template>
  <lxc-container>
    <div class="flex justify-between">
      <lxc-breadcrumb
        :name="t('layout.menu.users')"
        should-not-translate
        readonly
      />

      <div class="self-end">
        <div v-if="isUsersTabSelected" class="flex justify-end gap-4">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('user.button.create')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateUser"
          >
            {{ t("user.button.create") }}
          </lxc-button>
        </div>
        <div v-else-if="isGroupsTabSelected">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('userGroup.button.create')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateUserGroup"
          >
            {{ t("userGroup.button.create") }}
          </lxc-button>
        </div>
        <div v-else-if="isProfilesTabSelected">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('profile.new')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateProfile"
          >
            {{ t("profile.new") }}
          </lxc-button>
        </div>
      </div>
    </div>

    <lxc-tabs
      class="pt-8 pb-4"
      :initial-selected-tab-index="defaultTabIndex"
      :disable-tab-pane-click="true"
      @on-tab-selected="onTabSelected"
    >
      <lxc-tab-pane :label="t(tabItems[0].label)" :name="usersTabKey">
        <lxc-user-list :actions="true" />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t(tabItems[1].label)" :name="groupsTabKey">
        <lxc-user-group-list />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t(tabItems[2].label)" :name="profilesTabKey">
        <lxc-profiles
          :interface-form-shown="profileInterfaceShown"
          @update:side-canvas-shown="onUpdateInterfaceFormShown"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
