import type { RolesI } from "@lxc/app-device-types";
import RolesService from "~/services/roles.service";
import LxcError from "~/utils/LxcError";

export function useRoles() {
  const roles: Ref<RolesI | null | undefined> = ref(undefined);
  const error: Ref<LxcError | null | undefined> = ref(undefined);
  const isLoading = ref(true);

  async function fetchRoles() {
    isLoading.value = true;
    const response = await RolesService.getAllGrantableRoles();

    if (LxcError.check(response)) {
      roles.value = null;
      error.value = response;
    } else {
      roles.value = response;
      error.value = null;
    }

    isLoading.value = false;
  }

  return {
    roles,
    error,
    fetchRoles,
    isLoading,
  };
}
