<script setup lang="ts">
import type {
  ApplicationI,
  CampaignType,
  UserSessionSector,
} from "@lxc/app-device-types";
import type { ElTable } from "element-plus";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { useApplication } from "~/composables/useApplication";
import type { SearchMode } from "~/composables/useSearch";
import { PATHS } from "~/constants/paths";
import { router } from "~/plugins/router";
import SectorsService from "~/services/sectors.service";
import { useSectorStore } from "~/stores/useSectorStore";
import type { FilterOptions } from "~/types";
import { Filters } from "~/types";

const props = defineProps<{
  noAction?: boolean;
  selectedApplications?: Array<ApplicationI>;
  defaultFilters?: Map<Filters, any>;
  campaignType?: CampaignType;
  searchMode?: SearchMode;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
  useQueryParametersForPagination?: boolean;
}>();
const emit = defineEmits(["update:selectedApplications"]);

const { t } = useI18n();
const {
  isLoading,
  results: applications,
  filters,
  error,
  fetchData,
  setFilter,
  getHasActionStatusLabel,
  search,
  onSearch,
} = useApplication(props.searchMode, props.useQueryParametersForPagination);
const { selectedSectors } = storeToRefs(useSectorStore());

const tableRef = ref<InstanceType<typeof ElTable>>();
const currentPageSectors: Ref<UserSessionSector[]> = ref([]);

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => executeSearch(true));

const rowSelection: Ref<Array<ApplicationI>> = ref([]);

/**
 * Select applications
 */
const setSelection = () => {
  if (props.selectedApplications !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedApplications.some(
          (selectedApplication) => selectedApplication.id === row.id,
        ),
      );
    }

    rowSelection.value = props.selectedApplications || [];
  }
};

watch(() => props.selectedApplications, setSelection);

function executeSearch(replaceHistory?: boolean) {
  setFilter(
    Filters.SECTORS,
    selectedSectors.value.map((sector) => sector.code),
  );

  // call search in order to update the query parameters with filters
  search(replaceHistory);
}

/**
 * Retrieve applications and sectors
 * @param page
 * @param pageSize
 */
async function loadData(page?: number, pageSize?: number) {
  const params = new Map();
  params.set("campaignType", props.campaignType);
  await fetchData(page, pageSize, params);
  await fetchApplicationsSectors();

  setSelection();
}

/**
 * Retrieve all sectors matching applications
 */
async function fetchApplicationsSectors() {
  const sectorCodes: string[] = (applications.value?.data ?? []).map(
    (application: ApplicationI) => application.sectorCode!,
  );

  if (sectorCodes.length) {
    currentPageSectors.value =
      await SectorsService.getSectorsByCodes(sectorCodes);
  }
}

/**
 * Calcul and emit the selected applications
 */
const handleSelect = (handledSelected: ApplicationI[]) => {
  // Keep the selected applications which were selected on another page and not present in handled selected
  emit(
    "update:selectedApplications",
    props.selectedApplications
      ?.filter(
        (selectedApplication: ApplicationI) =>
          !tableRef.value ||
          !tableRef.value.data.find((row) => row.id === selectedApplication.id),
      )
      .filter(
        (selectedApplication: ApplicationI) =>
          !handledSelected.find((row) => row.id === selectedApplication.id),
      )
      .concat(handledSelected),
  );
};

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

const onSelectApplication = (app: ApplicationI) => {
  router.push(`${PATHS.APPLICATIONS}/${app.id}`);
};

const searchQuery = ref<string>((filters.get(Filters.NAME) ?? "") as string);
const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.NAME, searchQuery.value);
};

function reloadWithContext() {
  loadData(
    applications.value?.context?.page,
    applications.value?.context?.pageSize,
  );
}

onMounted(() => {
  onSearch(loadData, false);
  executeSearch(true);
});
</script>

<template>
  <lxc-search-bar
    :search-query="filters.get(Filters.NAME)"
    :search-placeholder="t('filters.searchByName')"
    @clear="
      (_) => {
        setFilter(Filters.NAME, '');
        search();
      }
    "
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-application-filters
    :filters="filters"
    :type-options="typeOptions"
    :model-options="modelOptions"
    :state-options="stateOptions"
    @change="setFilter"
    @enter="search"
  />

  <lxc-table
    ref="tableRef"
    :is-loading="isLoading"
    :data="applications?.data"
    :empty-text="t('application.empty')"
    :context="applications?.context"
    data-cy="application-table"
    :error="error?.toError()"
    :clickable="true"
    @change-page-and-page-size="loadData"
    @row-click="onSelectApplication"
    @select="handleSelect"
    @select-all="handleSelect"
  >
    <lxc-table-column
      v-if="selectedApplications !== undefined"
      type="selection"
      width="55"
    />
    <lxc-table-column
      class-name="no-no-wrap"
      prop="name"
      :label="t('application.name')"
      max-characters="22"
    />
    <lxc-table-column prop="model.type" :label="t('application.model.type')" />
    <lxc-table-column
      prop="model.declination"
      :label="t('application.model.declination')"
    />
    <lxc-table-column
      prop="serialNumber"
      :label="t('application.serialNumber')"
    />
    <lxc-table-column
      prop="connectivity.state"
      :label="t('application.connectivity')"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-connectivity-status
          :connectivity-state="scope.row.connectivity.state"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('application.certificate')"
      prop="certificateExpireBefore"
      sortable="custom"
      class="!py-0"
    >
      <template #default="scope">
        <lxc-certificate-status :certificate="scope.row.certificate" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="firmwareVersion"
      :label="t('application.firmwareVersion')"
    />
    <lxc-table-column prop="state" :label="t('application.state')">
      <template #default="scope">
        {{ scope.row.state ? t(`application.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="campaignType"
      :label="t('application.actions.scheduled.title')"
    >
      <template #default="scope">
        <span>{{ getHasActionStatusLabel(scope.row, campaignType) }}</span>
      </template>
    </lxc-table-column>
    <lxc-table-column v-if="!noAction" class="w-16 !py-0">
      <template #default="scoped">
        <lxc-application-actions
          :key="scoped.row.id"
          :is-activated="scoped.row.state"
          :application="scoped.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>

  <div class="mt-4">
    <el-select
      v-if="rowSelection?.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :teleported="false"
      @change="$emit('update:selectedApplications', rowSelection)"
    >
      <el-option
        v-for="device in rowSelection"
        :key="device.id"
        :label="device.name"
        :value="device"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
