<script setup lang="ts">
import { CampaignOMStatus } from "@lxc/app-device-types";
import { COLOR } from "~/constants/constants";

const props = defineProps<{
  status: string;
}>();

const { t } = useI18n();

const textWhite: Ref<boolean> = ref(false);

const badgeType = computed<string>(() => {
  let type: string;
  switch (props.status) {
    case CampaignOMStatus.SCHEDULED:
      type = COLOR.SECONDARY;
      break;
    case CampaignOMStatus.RUNNING:
      type = COLOR.PRIMARY;
      textWhite.value = true;
      break;
    case CampaignOMStatus.COMPLETE:
      type = COLOR.SUCCESS;
      break;
    case CampaignOMStatus.CANCELLING:
    case CampaignOMStatus.CANCELLED:
    case CampaignOMStatus.COMPLETE_WITH_ERRORS:
    case CampaignOMStatus.INCOMPLETE:
    default:
      type = COLOR.DANGER;
      break;
  }
  return type;
});
</script>

<template>
  <lxc-badge v-if="status != null" :type="badgeType" :text-white="textWhite">
    {{ t(`campaign.list.column.status.value.${props.status}`) }}
  </lxc-badge>
</template>
