import RestService from "./rest.service";
import type {
  DtwinCheckRunningOperation,
  DtwinCreateOperationI,
  DtwinOperationI,
  DtwinUpdateOperationI,
  OperationCreationMode,
  OperationModelI,
  PaginatedListI,
} from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

/**
 * Operation manager for the Dtwins
 */
export class OperationManagerService extends RestService {
  protected readonly BASE_URL: string = "/operationManager";
  protected readonly OPERATION_URL: string = `${this.BASE_URL}/operations`;
  protected readonly OPERATION_CHECK_URL: string = `${this.OPERATION_URL}/check`;
  protected readonly MODEL_URL: string = `${this.BASE_URL}/operationModels`;

  /**
   * Get the paginated operation list filtered and sorted
   * @param page
   * @param pageSize
   * @param filter
   * @param sort
   * @returns
   */
  getOperations(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<PaginatedListI<DtwinOperationI>> {
    const params: Record<string, string | number | FiltersSelection> = {
      page,
      pageSize,
    };

    if (filter) {
      params.filter = filter;
    }
    if (sort) {
      params.sort = sort;
    }

    return this.$api.get(this.OPERATION_URL, { params });
  }

  getOperation(uuid: string): Promise<DtwinOperationI> {
    return this.$api.get(`${this.OPERATION_URL}/${uuid}`);
  }

  /**
   * Check if there is operations in the devices list having the status:
   * - SCHEDULED
   * - QUEUED
   * - PENDING
   * - DELIVERED
   * - RUNNING
   * - RETRY
   * - CANCELLING
   * @param type possible values 'FIRM_UPDATE', 'OPTIONS'
   * @param deviceTwinUid
   * @param targetVersion
   * @param creationMode
   * @param filter RSQL filter, example launchPlannedAt>=2023-12-15T10:56:32.476Z;createdAt<2023-12-15T10:56:32.476Z
   */
  checkOperation(
    type: string,
    deviceTwinUid: Array<string>,
    targetVersion?: string,
    creationMode?: OperationCreationMode,
    filter?: string,
  ): Promise<DtwinCheckRunningOperation> {
    const params: Record<string, string | Array<string>> = {
      type,
      deviceTwinUid,
    };

    if (targetVersion) {
      params.targetVersion = targetVersion;
    }

    if (creationMode) {
      params.creationMode = creationMode;
    }

    if (filter) {
      params.filter = filter;
    }

    return this.$api.get(this.OPERATION_CHECK_URL, { params });
  }

  /**
   * Get the paginated operation model list filtered and sorted
   * @param page
   * @param pageSize
   * @param filter
   * @param sort
   * @returns
   */
  getModels(
    page?: number,
    pageSize?: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<PaginatedListI<OperationModelI>> {
    const params: Record<string, string | number | FiltersSelection> = {};

    if (page) {
      params.page = page;
    }
    if (pageSize) {
      params.pageSize = pageSize;
    }
    if (filter) {
      params.filter = filter;
    }
    if (sort) {
      params.sort = sort;
    }

    return this.$api.get(this.MODEL_URL, { params });
  }

  /**
   * Create a new device twin operation
   * @param operation
   * @returns
   */
  createOperation(operation: DtwinCreateOperationI): Promise<DtwinOperationI> {
    return this.$api.post(this.OPERATION_URL, { operation });
  }

  /**
   * Update device twin operation values
   * @param uuid
   * @param operation
   * @returns
   */
  updateOperation(
    uuid: string,
    operation: DtwinUpdateOperationI,
  ): Promise<void> {
    return this.$api.patch(`${this.OPERATION_URL}/${uuid}`, { operation });
  }

  /**
   * @param uuid
   * @returns
   */
  async cancel(uuid: string): Promise<void> {
    return this.$api.post(`${this.OPERATION_URL}/${uuid}/cancel`);
  }
}

export default new OperationManagerService();
