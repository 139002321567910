export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_FIRST_PAGE = 1;
export const FILTER_SEARCH_TXT_MIN_LENGTH = 3;

export const DEFAULT_SORT_OPERATION = "-modifiedAt";

export const DONNUT_TITLE_COLOR = "#002f5d";
export const DONNUT_FONT_FAMILY = "Montserrat";
export const BASE_URL_MAP = {
  ACTIONS: "/actions",
  USERS: "/users",
};
export const SERVICE_URL_MAP = {
  CURRENT_USER: "/me",
};

export const VALIDATION_NUMBER: Record<string, number> = {
  ALIAS_MAX_LENGTH: 50,
  MAIL_MAX_LENGTH: 255,
};
export const VALIDATION_REGEXP: Record<string, RegExp> = {
  // Match characters, number, underscores hyphens or spaces, but not only spaces
  CERTIFICATE_ALIAS: /^(?!\s*$)([a-z0-9\-_\s]*$)/g,

  // Email
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

  // Forbid uppercase
  NO_UPPERCASE: /^[^A-Z]*$/,

  // Must contain lowercase + uppercase letters, number and special characters, minimum size should be 12 characters
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{12,}$/,
};

export const APP_NAME = "Lx Connect";

export enum DTWIN_OPERATION_TYPE {
  FIRMWARE_UPDATE = "FIRM_UPDATE",
}

export enum DTWIN_DEVICE_TYPE {
  LPP4 = "LPP4",
}

export const DEFAULT_QR_CODE_WIDTH = 300;
export const DEFAULT_QR_CODE_HEIGHT = 300;

export enum COLOR {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  WARNING = "warning",
  DANGER = "danger",
  ERROR = "error",
  SUCCESS = "success",
}

export enum CAMPAIGN_STATS_COLOR_HEXA_CODES {
  SCHEDULED = "#E6EBF1",
  RUNNING = "#235A96",
  COMPLETED = "#027A48",
  ERROR = "#F04438",
}
