<script lang="ts" setup>
import webComponentsService from "./services/webComponents.service";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import en from "element-plus/es/locale/lang/en";
import es from "element-plus/es/locale/lang/es";
import fr from "element-plus/es/locale/lang/fr";
import it from "element-plus/es/locale/lang/it";
import { useConfigStore } from "~/stores/useConfigStore";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { useUserSession } from "~/stores/useUserSession";

const { getConfig } = useConfigStore();
const { getLicenseStatus } = useLicenseStatusStore();

const userSessionStore = useUserSession();
const { locale, t } = useI18n();

const elementPlusLangs: Record<string, Object> = {
  fr,
  en,
  it,
  es,
};

const updateLanguage = () => {
  const currentLocale = userSessionStore.userSession?.language ?? locale.value;
  locale.value = currentLocale;
  dayjs.locale(currentLocale);
};

/**
 * Load the Web Components scripts
 * Does not block if the loading failed
 */
const loadWebComponents = async () => {
  // Get Web Components configurations from AS
  const webComponents = await webComponentsService.getWebComponents();

  if (Array.isArray(webComponents)) {
    for (const webComponent of webComponents) {
      // Add Web Components script
      if (webComponent.url) {
        const script = document.createElement("script");
        script.src = webComponent.url;
        script.type = "module"; // This attribute is required to make Web Components working
        document.head.appendChild(script);
      }
    }
  }
};

/**
 * Start managing display confirm message on closing/reload window and on leaving page
 */
const confirmLeavePageStore = useConfirmLeavePageStore();

const messageConfirmUnsavedData = t(
  "message.confirm.leave.page.message.unsavedData",
);

// Display confirm message on refresh/close window
window.addEventListener("beforeunload", (event) => {
  if (confirmLeavePageStore.isActivated()) {
    event.returnValue = messageConfirmUnsavedData;
  }
});

// show confirm message component based on the confirmLeavePageStore "shown" state
const showConfirm = computed({
  get(): boolean {
    return confirmLeavePageStore.$state.shown;
  },
  set(showConfirm: boolean) {
    confirmLeavePageStore.$state.shown = showConfirm;
  },
});
/**
 * End managing display confirm message on closing/reload window and on leaving page
 */

// Init after user session has changed
watch(
  () => userSessionStore.userSession,
  () => {
    // Case when the user is authenticated
    if (userSessionStore.isAuthenticated) {
      getLicenseStatus();
      updateLanguage();
      loadWebComponents();
    }
  },
);

const onCancelLeavePageconfirm = () => {
  confirmLeavePageStore.cancel();
  showConfirm.value = false;
};

// Warning: the user can be not already authenticated here
onBeforeMount(updateLanguage);
onMounted(getConfig);
</script>

<template>
  <el-config-provider :locale="elementPlusLangs[locale] || elementPlusLangs.en">
    <router-view />
    <lxc-version />
  </el-config-provider>

  <!-- Leave page confirm component -->
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('message.confirm.leave.page.title')"
    :description="messageConfirmUnsavedData"
    :ok-label="t('message.confirm.okLabel')"
    :cancel-label="t('message.confirm.cancelLabel')"
    @confirm="confirmLeavePageStore.confirm"
    @cancel="onCancelLeavePageconfirm"
  />
</template>
