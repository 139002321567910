import { PATHS } from "~/constants/paths";
import type { TabItem } from "~/types";
import { ACL_ROLES } from "~/types";

export const userTabsItems: TabItem[] = [
  {
    label: "tab.users.label",
    to: PATHS.USER_MANAGEMENT_USERS,
    can: ACL_ROLES.NO_CONTROL,
  },
  {
    label: "tab.userGroups.label",
    to: PATHS.USER_MANAGEMENT_GROUPS,
    can: ACL_ROLES.NO_CONTROL,
  },
  {
    label: "tab.userProfiles.label",
    to: PATHS.USER_MANAGEMENT_PROFILES,
    can: ACL_ROLES.NO_CONTROL,
  },
];
