import RestService from "./rest.service";
import type { UserCertificateStatusI } from "@lxc/app-device-types";

export class UserCertificateService extends RestService {
  protected BASE_URL = "/user-certificate";

  requestUserCertificate(userId: string): Promise<void> {
    return this.$api.post(`${this.BASE_URL}/${userId}/generateUserCertificate`);
  }

  findUserCertificateStatus(userId: string): Promise<UserCertificateStatusI> {
    return this.$api.get(
      `${this.BASE_URL}/${userId}/findUserCertificateStatus`,
    );
  }

  downloadUserCertificate(userId: string): Promise<BlobPart | any> {
    return this.$api.get(
      `/proxy?serviceId=DOWNLOAD_CERTIFICATE&target=${userId}/certificates`,
      {
        headers: {
          Accept: "application/x-pkcs12",
        },
        responseType: "arraybuffer",
        timeout: Infinity,
      },
    );
  }
}

export default new UserCertificateService();
