<script lang="ts" setup>
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { UserProfileI } from "@lxc/app-device-types";
import type { LocationQuery } from "vue-router/dist/vue-router";
import { useProfiles } from "~/composables/useProfiles";
import { useRoles } from "~/composables/useRoles";
import { PATHS } from "~/constants/paths";

const props = defineProps<{
  interfaceFormShown: boolean;
}>();
const emits = defineEmits(["update:sideCanvasShown"]);

const { t } = useI18n();
const router = useRouter();

const {
  isLoading: isLoadingFetchUserProfiles,
  profiles,
  error: errorFetchUserProfiles,
  fetchUserProfiles,
} = useProfiles();

const {
  isLoading: isLoadingFetchRoles,
  roles,
  error: errorFetchRoles,
  fetchRoles,
} = useRoles();

const route = useRoute();

function openUserProfileForm(row?: UserProfileI) {
  if (row?.code) {
    const targetUrl: Record<string, string | string[] | LocationQuery> = {
      path: `${PATHS.USER_MANAGEMENT_PROFILES}/${row.code}`,
      query: route.query,
    };
    router.push(targetUrl);
  } else {
    formSideCanvasShown.value = true;
  }
}

function onCloseUserProfileForm() {
  const targetUrl: Record<string, string | string[] | LocationQuery> = {
    path: PATHS.USER_MANAGEMENT_PROFILES,
    query: route.query,
  };
  router.push(targetUrl);

  if (formSideCanvasShown.value) {
    formSideCanvasShown.value = false;
  }
}

const formSideCanvasShown = computed({
  get: () => {
    return props.interfaceFormShown;
  },
  set: (shown: boolean) => {
    emits("update:sideCanvasShown", shown);
  },
});

function reloadWithContext() {
  fetchUserProfiles(
    profiles.value?.context?.page,
    profiles.value?.context?.pageSize,
  );
}

onMounted(() => Promise.all([fetchRoles(), fetchUserProfiles()]));
</script>

<template>
  <lxc-container
    :px="0"
    :py="0"
    :is-loading="
      (isLoadingFetchRoles || isLoadingFetchUserProfiles) && !profiles
    "
    :error="errorFetchRoles || errorFetchUserProfiles"
  >
    <lxc-table
      :is-loading="isLoadingFetchRoles || isLoadingFetchUserProfiles"
      :data="profiles?.data"
      :context="profiles?.context"
      clickable
      @row-click="openUserProfileForm"
      @change-page-and-page-size="fetchUserProfiles"
    >
      <lxc-table-column prop="label" :label="t('profile.profile')" />
      <lxc-table-column
        align="left"
        prop="nbUsers"
        :label="t('profile.nbOfUsers')"
      />
      <lxc-table-column :width="50" class="!py-0">
        <template #default="scope">
          <div class="flex gap-2">
            <lxc-profile-actions
              v-if="!scope.row.isDefault"
              :user-profile="scope.row"
              @change="reloadWithContext"
            />
          </div>
        </template>
      </lxc-table-column>
    </lxc-table>

    <lxc-profile-form
      :new-profile="formSideCanvasShown"
      :roles-by-section-and-context="roles"
      @close="onCloseUserProfileForm"
      @update="reloadWithContext"
    />
  </lxc-container>
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
