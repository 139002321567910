import RestService from "./rest.service";
import type { WebComponentI } from "@lxc/app-device-types";

class WebComponentsService extends RestService {
  private BASE_URL = "/web-components";

  /**
   * Retrieve web components
   */
  getWebComponents(): Promise<WebComponentI[]> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new WebComponentsService();
