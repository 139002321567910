<script setup lang="ts">
import type { DtwinI, DtwinModelI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import {
  INPUT_NOT_DEFINED,
  NO_CONTENT,
} from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { LxcDtwinsDescriptionGeolocationI } from "~/components/dtwins/dtwinsForm/description/geolocation/LxcDtwinsDescriptionGeolocation.vue";
import { LxcDtwinsDescriptionIdCardI } from "~/components/dtwins/dtwinsForm/description/idCard/LxcDtwinsDescriptionIdCard.vue";
import { LxcDtwinsDescriptionStateI } from "~/components/dtwins/dtwinsForm/description/state/LxcDtwinsDescriptionState.vue";
import { useDtwin } from "~/composables/useDtwins";
import dtwinsService from "~/services/dtwins.service";
import { ACL_ROLES } from "~/types";
import LxcError from "~/utils/LxcError";
import { formatIsoDate } from "~/utils/date-tools";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

interface Props {
  dtwin?: DtwinI | null;
  models: DtwinModelI[];
}
const props = defineProps<Props>();
const emit = defineEmits(["update:dtwin"]);

const { t } = useI18n();
const acl = useAcl();
const { getDtwinTypeLabel } = useDtwin();
const isAllowedToEdit = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM));

const dateFormat: string = t("dateFormat.datetime");

/**
 * ID card representation used in the device ID UI card.
 */
const idCard = ref({
  friendlyName: props.dtwin?.attributes.friendlyName,
  type: getDtwinTypeLabel(props.models, props.dtwin),
  serialNumber: props.dtwin?.attributes.serialNumber,
  hardwareVersion: props.dtwin?.attributes.hardwareVersion ?? NO_CONTENT,
} as LxcDtwinsDescriptionIdCardI);

/**
 * Handle the device friendly name update required by the user.
 * @param friendlyName New device friendly name
 */
async function updateFriendlyName(friendlyName: string): Promise<void> {
  const doesUpdateSucceed = await onUpdate({
    data: { friendlyName },
  });
  if (doesUpdateSucceed) {
    (props.dtwin as DtwinI).attributes.friendlyName = friendlyName;
    emit("update:dtwin", props.dtwin);
  }

  idCard.value = {
    friendlyName: doesUpdateSucceed
      ? friendlyName
      : props.dtwin?.attributes.friendlyName,
    type: getDtwinTypeLabel(props.models, props.dtwin),
    serialNumber: props.dtwin?.attributes.serialNumber,
    hardwareVersion: props.dtwin?.attributes.hardwareVersion ?? NO_CONTENT,
    isAllowedToEdit: isAllowedToEdit.value,
    doesUpdateSucceed,
  } as LxcDtwinsDescriptionIdCardI;
}

/**
 * State representation used in the state UI card.
 */
const state: Ref<LxcDtwinsDescriptionStateI> = ref({
  lifeCycleState: props.dtwin?.lifeCycleState
    ? t(`dtwins.lifeCycleState.${props.dtwin?.lifeCycleState}`)
    : NO_CONTENT,
  firstConnection: props.dtwin?.firstConnectionDate
    ? formatIsoDate(props.dtwin.firstConnectionDate, dateFormat)
    : NO_CONTENT,
  lastConnection: props.dtwin?.lastConnectionDate
    ? formatIsoDate(props.dtwin.lastConnectionDate, dateFormat)
    : NO_CONTENT,
  batteryStatus: props.dtwin?.features.battery?.reported?.level ?? undefined,
  configurationApplicationDate: props.dtwin?.features.configuration?.reported
    ?.modificationTimestamp
    ? formatIsoDate(
        props.dtwin?.features.configuration.reported.modificationTimestamp,
        dateFormat,
      )
    : NO_CONTENT,
  firmwareVersion:
    props.dtwin?.features.firmware?.reported?.firmwareVersion ?? NO_CONTENT,
  certificateExpirationDate: props.dtwin?.features.certificate?.reported
    .notAfter
    ? formatIsoDate(
        props.dtwin?.features.certificate?.reported.notAfter,
        dateFormat,
      )
    : NO_CONTENT,
  lastCertificateUpdate: props.dtwin?.features.certificate?.reported?.$metadata
    ?.notAfter?.$lastValueUpdate
    ? formatIsoDate(
        props.dtwin?.features.certificate?.reported?.$metadata?.notAfter
          ?.$lastValueUpdate,
        dateFormat,
      )
    : NO_CONTENT,
  firstCommissioningDate: NO_CONTENT,
  lastChange: NO_CONTENT,
  lastRebootDate: NO_CONTENT,
} as LxcDtwinsDescriptionStateI);

/**
 * Connectivity representation used in the connectivity UI card.
 */
// TODO: uncomment this section when the connectivity attribute will be available
//const connectivity: Ref<LxcDtwinsDescriptionConnectivityI> = ref({});

/**
 * Geolocation representation used in the geolocation UI card.
 */
const geolocation: Ref<LxcDtwinsDescriptionGeolocationI> = ref({
  address: props.dtwin?.attributes?.address ?? INPUT_NOT_DEFINED,
  latitude: props.dtwin?.features?.location?.reported?.latitude ?? NO_CONTENT,
  longitude: props.dtwin?.features?.location?.reported?.longitude ?? NO_CONTENT,
  isAllowedToEdit: isAllowedToEdit.value ?? false,
} as LxcDtwinsDescriptionGeolocationI);

/**
 * Handle the geolocation address update required by the user.
 * @param address New device address
 */
async function updateGeolocationAddress(address: string): Promise<void> {
  const doesUpdateSucceed = await onUpdate({
    data: { address },
  });
  if (doesUpdateSucceed) {
    (props.dtwin as DtwinI).attributes.address = address;
    emit("update:dtwin", props.dtwin);
  }

  geolocation.value = {
    address: doesUpdateSucceed ? address : props.dtwin?.attributes.address,
    latitude: props.dtwin?.features?.location?.reported?.latitude,
    longitude: props.dtwin?.features?.location?.reported?.longitude,
    isAllowedToEdit: isAllowedToEdit.value,
    doesUpdateSucceed,
  } as LxcDtwinsDescriptionGeolocationI;
}

async function onUpdate({
  data,
}: {
  data: Record<string, unknown>;
}): Promise<boolean> {
  if (props.dtwin?.uid) {
    const response = await dtwinsService.patchDtwin(props.dtwin.uid, data);

    if (!LxcError.check(response)) {
      emit("update:dtwin", props.dtwin);
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      return true;
    }
    response.notify(NotificationKey.saveError);
  }
  return false;
}
</script>

<template>
  <LxcDtwinsDescriptionIdCard
    :model-value="idCard"
    :is-allowed-to-edit="isAllowedToEdit"
    @update:name="updateFriendlyName"
  />
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionState :model-value="state" />
  <!-- TODO: uncomment this section when the connectivity attribute will be available
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionConnectivity
    :model-value="connectivity"
  />
  -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <LxcDtwinsDescriptionGeolocation
    class="mb-4"
    :model-value="geolocation"
    @update:address="updateGeolocationAddress"
  />
</template>
