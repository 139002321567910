<script setup lang="ts">
import { InterfaceStatus } from "@lxc/app-device-types";

const props = defineProps<{
  status: string;
}>();

const { t } = useI18n();

const statusColor = computed<string>((): string => {
  let color: string;

  switch (props.status) {
    case InterfaceStatus.ACTIVE:
      color = "primary";
      break;
    case InterfaceStatus.INACTIVE:
    default:
      color = "danger";
      break;
  }
  return color;
});
</script>

<template>
  <lxc-badge v-if="status != null" :type="statusColor" text-white>
    {{
      t(
        `applicationMgt.tabs.thirdPartyApp.status.${props.status.toLowerCase()}`,
      )
    }}
  </lxc-badge>
</template>
