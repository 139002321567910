import RestService from "./rest.service";
import type { ApiListResult, UserProfileI } from "@lxc/app-device-types";

class UserProfilesService extends RestService {
  private BASE_URL = "/profiles";

  /**
   * Retrieve userProfiles
   * @param page
   * @param pageSize
   * @param userProfileLabel
   * @param search
   */
  getUserProfiles(
    page?: number,
    pageSize?: number,
    userProfileLabel?: string,
    search?: string,
  ): Promise<ApiListResult<UserProfileI>> {
    const params = {
      page,
      pageSize,
      userProfileLabel,
      search,
    };

    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Get a userProfile by code
   * @param profileCode
   */
  getUserProfileByCode(profileCode: string): Promise<UserProfileI> {
    return this.$api.get(`${this.BASE_URL}/${profileCode}`);
  }

  /**
   * Get multiple profiles
   * @param codes
   */
  getUserProfilesByCodes(codes: string[]): Promise<Array<UserProfileI>> {
    const data = {
      codes,
    };
    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };
    return this.$api.post(
      `${this.BASE_URL}/getUserProfilesByCodes`,
      data,
      config,
    );
  }

  /**
   * Create a userProfile
   * @param userProfile
   */
  createUserProfile(userProfile: UserProfileI) {
    return this.$api.post(`${this.BASE_URL}`, { userProfile });
  }

  /**
   * Update a userProfile
   * @param profileCode
   * @param userProfile
   */
  updateUserProfile(
    profileCode: string,
    userProfile: UserProfileI,
  ): Promise<any> {
    return this.$api.put(`${this.BASE_URL}/${profileCode}`, { userProfile });
  }

  /**
   * Remove a userProfile
   * @param profileCode
   */
  deleteUserProfile(profileCode: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${profileCode}`);
  }
}

export default new UserProfilesService();
