<script setup lang="ts">
import { CampaignOperationMgrI } from "@lxc/app-device-types";

defineProps<{
  campaign?: CampaignOperationMgrI;
}>();

const { t } = useI18n();

const operationsTotalCount = ref();
const onComputeTotalCount = (totalCount: number) => {
  operationsTotalCount.value = totalCount;
};
</script>
<template>
  <lxc-card>
    <h5 class="!mt-0 !mb-4">
      {{
        t(
          "campaign.details.characteristics.equipments.statistics.title",
          operationsTotalCount ?? 0,
        )
      }}
    </h5>
    <lxc-campaign-pie
      class="h-60"
      :campaign-operation-mgr="campaign"
      @total-count-computation="onComputeTotalCount"
    />
  </lxc-card>
</template>
