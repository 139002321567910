<script lang="ts" setup>
import type { KeystoreCertificateI } from "@lxc/app-device-types";
import { CACertificateItemType } from "@lxc/app-device-types";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  certificate: KeystoreCertificateI;
}>();

const { t } = useI18n();

const displayInfoSign: ComputedRef<boolean> = computed(
  () => props.certificate.type === CACertificateItemType.CSR,
);
</script>
<template>
  <div
    v-if="displayInfoSign"
    :title="t('certificates.keystore.csrRequiresUploadCertificate')"
    class="text-warning-700"
  >
    <ILxcInfo width="22" height="22" />
  </div>
</template>
