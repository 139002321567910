import RestService from "./rest.service";
import type {
  ApiListResult,
  ApplicationMgtI,
  CreateInterfaceRequestPayloadI,
  CreateInterfaceResponseI,
  InterfaceI,
  UpdateInterfaceRequestPayloadI,
} from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

export class ApplicationMgtService extends RestService {
  protected BASE_URL = "/applicationMgts";
  protected INTERFACE_PATH = "/interfaces";

  /**
   * Retrieve application management list
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   */
  getApplicationMgts(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<ApplicationMgtI>> {
    const params = { page, pageSize, sort, search };

    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Retrieve an application management
   * @param id
   */
  getApplicationMgtById(id: string): Promise<ApplicationMgtI> {
    return this.$api.get(`${this.BASE_URL}/${id}`);
  }

  /**
   * Generate an application management QR code
   * @param id
   */
  generateApplicationMgtQrCodeById(id: string): Promise<any> {
    return this.$api.get(`${this.BASE_URL}/${id}/qrCode`, {
      headers: { Accept: "image/png" },
      responseType: "arraybuffer",
    });
  }

  /**
   * Create an application management
   * @param applicationMgt
   * @returns
   */
  createApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.post(`${this.BASE_URL}`, { applicationMgt });
  }

  /**
   * update an application management
   * @param applicationMgt
   * @returns
   */
  updateApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.patch(`${this.BASE_URL}/${applicationMgt.id}`, {
      applicationMgt,
    });
  }

  /**
   * delete an application management
   * @param applicationMgt
   */
  deleteApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.delete(`${this.BASE_URL}/${applicationMgt.id}`);
  }

  /**
   * get App Interfaces
   * @param page
   * @param pageSize
   * @param sort
   * @param filter
   * @returns
   */
  getAppInterfaces(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<InterfaceI>> {
    const params = { page, pageSize, filter, sort };
    return this.$api.get(`${this.BASE_URL}${this.INTERFACE_PATH}`, { params });
  }

  /**
   * Create an application interface
   * @param appInterface the new interface payload
   * @returns
   */
  createAppInterface(
    appInterface: CreateInterfaceRequestPayloadI,
  ): Promise<CreateInterfaceResponseI | any> {
    return this.$api.post(`${this.BASE_URL}${this.INTERFACE_PATH}`, {
      appInterface,
    });
  }

  /**
   * Get an interface
   * @param clientId
   * @returns
   */
  getAppInterfaceByClientId(clientId: string): Promise<InterfaceI> {
    return this.$api.get(`${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`);
  }

  /**
   * Update an interface and return it updated
   * @param clientId
   * @param updateInterfacePayload
   * @returns
   */
  updateAppInterface(
    clientId: string,
    updateInterfacePayload: UpdateInterfaceRequestPayloadI,
  ) {
    return this.$api.put(`${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`, {
      updateInterfacePayload,
    });
  }

  /**
   * Delete an interface
   * @param clientId
   * @returns
   */
  deleteAppInterface(clientId: string) {
    return this.$api.delete(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`,
    );
  }

  /**
   * Deactivate an interface
   * @param clientId
   * @returns
   */
  deactivateAppInterface(clientId: string) {
    return this.$api.post(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}/deactivate`,
    );
  }

  /**
   * Activate an interface
   * @param clientId
   * @returns
   */
  activateAppInterface(clientId: string) {
    return this.$api.post(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}/activate`,
    );
  }
}

export default new ApplicationMgtService();
