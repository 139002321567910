<script lang="ts" setup>
import { useSerialize } from "@lxc/app-device-common";
import type {
  DtwinCreateOperationI,
  DtwinI,
  DtwinOperationI,
  DtwinOperationLPP4I,
  DtwinUpdateOperationI,
  FirmwareI,
  OperationModelI,
} from "@lxc/app-device-types";
import {
  FirmwareStatus,
  OperationCreationMode,
  OperationScheduler,
  OperationServiceOrigin,
} from "@lxc/app-device-types";
import type { Rules } from "async-validator";
import dayjs from "dayjs";
import { DisplayableColumns } from "~/components/parameters/firmwares/LxcFirmwaresList.type";
import { useDtwinFirmwareUpdateOperationModels } from "~/composables/useDtwinOperationManager";
import { useFirmware } from "~/composables/useFirmware";
import { SearchMode } from "~/composables/useSearch";
import { DTWIN_DEVICE_TYPE, DTWIN_OPERATION_TYPE } from "~/constants/constants";
import { typeOptions } from "~/constants/deviceFilters.config";
import firmwareService from "~/services/firmware.service";
import operationManagerService from "~/services/operationManager.service";
import type { DtwinOperationForm, FilterOptions } from "~/types";
import { Filters } from "~/types";
import LxcError from "~/utils/LxcError";
import filterUtils from "~/utils/filters.utils";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightCalendar from "~icons/lxc-light/calendar";
import ILxcLightRotateCw from "~icons/lxc-light/rotate-cw";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  dtwin?: DtwinI | null;
  operation?: DtwinOperationI | null;
  show: boolean;
}>();
const emit = defineEmits(["update:show", "change"]);

const { t } = useI18n();
const serialize = useSerialize();
const { isLoading, error } = useFirmware();

const {
  error: modelsError,
  fetchData: fetchModelsData,
  isLoading: isModelsLoading,
  onSearch: onSearchModels,
  results: modelsResults,
  search: searchModels,
  setFilter: setModelsFilter,
} = useDtwinFirmwareUpdateOperationModels(SearchMode.FILTER_SEARCH);

function initializeDefaultModelsFilters() {
  setModelsFilter(
    Filters.DTWIN_OPERATION_MODEL_TYPE,
    DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE,
  );
  setModelsFilter(Filters.DTWIN_OPERATION_MODEL_DEVICE_TYPE, [
    DTWIN_DEVICE_TYPE.LPP4,
  ]);
}

let searchModelsPending = false;
const operationFormRef = ref();
const isResultLoading: Ref<boolean> = ref(false);
const minDate: Ref<Date> = ref(new Date());
const operationModel: ComputedRef<OperationModelI | undefined> = computed(
  () => {
    const models = modelsResults.value?.results;
    let model: OperationModelI | undefined;

    if (!LxcError.check(modelsError.value)) {
      model = models?.length ? models[0] : undefined;
    }

    return model;
  },
);

const defaultOperationForm: DtwinOperationForm = {
  firmwareUid: undefined,
  deviceTwinUid: props.dtwin?.uid,
  launchPlannedAt: undefined,
  startsAtCreation: false,
};
const isOperationLoading: Ref<boolean> = ref(false);
const isFirmwareLoading: Ref<boolean> = ref(false);
const isCheckOperationLoading: Ref<boolean> = ref(false);
const isScheduledOrRunningOperations: Ref<boolean> = ref(false);
const loadingOperationError: Ref<LxcError | null | undefined> = ref();
const loadingFirmwareError: Ref<LxcError | undefined | null> = ref();
const checkOperationError: Ref<LxcError | undefined | null> = ref();
const initCertificateFormStringified: Ref<string> = ref(
  serialize(defaultOperationForm),
);
const hasInternalScheduler: ComputedRef<boolean> = computed(
  () => operationModel.value?.hasInternalScheduler ?? false,
);
const selectedFirmware: Ref<FirmwareI | undefined | null> = ref();
const form = reactive<DtwinOperationForm>({
  ...defaultOperationForm,
});
const initOperationForm: Ref<DtwinOperationForm> = ref({
  ...defaultOperationForm,
});
const edited: ComputedRef<boolean> = computed(() => {
  const strigifiedForm = serialize(form);
  return strigifiedForm !== initCertificateFormStringified.value;
});
const isEditionMode: ComputedRef<boolean> = computed(
  () => !!props.operation?.uid,
);

const isValidateDisabled: ComputedRef<boolean> = computed(
  () =>
    isModelsLoading.value ||
    isResultLoading.value ||
    isFirmwareLoading.value ||
    !edited.value,
);

// Certificate rules
const rules = computed(() => {
  const rulesConfig: Rules = {
    launchPlannedAt: [
      {
        required: true,
        message: t(
          "operation.firmware.update.dtwin.validation.launchPlannedAt",
        ),
        type: "date",
      },
    ],
    firmwareUid: [
      {
        required: true,
        message: t("operation.firmware.update.uuidRequired"),
        type: "string",
      },
    ],
  };

  return rulesConfig;
});

const statusOptions: FilterOptions = {
  label: t("filters.status"),
  options: Object.values(FirmwareStatus).map((value) => {
    return {
      value,
      label: `firmware.status.${value}`,
      disabled: true,
    };
  }),
};

const rangeOptions: Ref<FilterOptions | undefined> = ref(
  filterUtils.buildFilterOptions(typeOptions, true),
);

async function getFirmwareByUuid() {
  if (form.firmwareUid) {
    isFirmwareLoading.value = true;
    const response = await firmwareService.getFirmwareByUuid(form.firmwareUid);

    if (LxcError.check(response)) {
      loadingFirmwareError.value = response;
      selectedFirmware.value = null;
    } else {
      selectedFirmware.value = response;
      loadingFirmwareError.value = null;
    }
    isFirmwareLoading.value = false;
  } else {
    loadingFirmwareError.value = null;
    selectedFirmware.value = null;
  }
}

async function setOperationDetailForm(
  dtwinOperation?: DtwinOperationLPP4I | null,
) {
  Object.assign(
    form,
    dtwinOperation
      ? {
          firmwareUid: dtwinOperation.customInputParameters?.firmwareUid,
          deviceTwinUid: props.dtwin?.uid,
          launchPlannedAt: new Date(dtwinOperation.launchPlannedAt),
          startsAtCreation: dtwinOperation.startsAtCreation,
        }
      : { ...defaultOperationForm },
  );
  initOperationForm.value = { ...form };
  initCertificateFormStringified.value = serialize(form);
  await getFirmwareByUuid();
  // set timeout to be sure that the clearValidate is processed after updating the form inputs
  setTimeout(operationFormRef.value?.clearValidate, 0);
}

const onClose = () => {
  selectedFirmware.value = null;
  emit("update:show", false);
};

async function validate(): Promise<boolean> {
  return await operationFormRef.value?.validate().catch((_: any) => false);
}

async function getOperation(): Promise<void> {
  isOperationLoading.value = true;
  if (props.operation?.uid) {
    const response = await operationManagerService.getOperation(
      props.operation?.uid,
    );

    if (LxcError.check(response)) {
      loadingOperationError.value = response;
    } else {
      loadingOperationError.value = null;
      await setOperationDetailForm(response as DtwinOperationLPP4I);
    }
  } else {
    await setOperationDetailForm();
  }

  isOperationLoading.value = false;
}

async function checkOperation(): Promise<void> {
  isCheckOperationLoading.value = true;

  if (props.dtwin?.uid && !props.operation?.uid) {
    let filter: string | undefined;
    if (props.operation?.uid) {
      filter = `uid!=${props.operation?.uid}`;
    }
    const response = await operationManagerService.checkOperation(
      DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE,
      [props.dtwin?.uid],
      undefined,
      undefined,
      filter,
    );

    if (LxcError.check(response)) {
      checkOperationError.value = response;
    } else {
      checkOperationError.value = null;
      isScheduledOrRunningOperations.value = response[props.dtwin.uid] ?? false;
    }
  } else {
    isScheduledOrRunningOperations.value = false;
  }

  isCheckOperationLoading.value = false;
}

async function onConfirmCreateUpdateFirmware() {
  if (props.dtwin && operationModel.value && form.launchPlannedAt) {
    isResultLoading.value = true;
    const expiredAt = dayjs(form.launchPlannedAt).add(1, "month");

    const createOperation: DtwinCreateOperationI = {
      firmwareUid: form.firmwareUid,
      deviceTwinUid: props.dtwin.uid,
      modelUid: operationModel.value.uid,
      serviceOrigin: OperationServiceOrigin.UNITARY,
      creationMode: OperationCreationMode.MANUAL,
      launchPlannedAt: dayjs(form.launchPlannedAt).utc().toISOString(),
      expiredAt: expiredAt.utc().toISOString(),
      scheduler: OperationScheduler.SERVICE,
      startsAtCreation: form.startsAtCreation,
    };

    const operationResponse =
      await operationManagerService.createOperation(createOperation);
    isResultLoading.value = false;

    if (LxcError.check(operationResponse)) {
      showNotificationError(t(NotificationKey.error));
    } else {
      showNotificationSuccess(t(NotificationKey.success));
      emit("change");
      onClose();
    }
  }
}

async function onConfirmModifyUpdateFirmware() {
  if (
    props.dtwin &&
    props.operation?.uid &&
    operationModel.value &&
    form.launchPlannedAt
  ) {
    isResultLoading.value = true;
    const expiredAt = dayjs(form.launchPlannedAt).add(1, "month");

    const updateOperation: DtwinUpdateOperationI = {
      firmwareUid: form.firmwareUid,
      launchPlannedAt: dayjs(form.launchPlannedAt).utc().toISOString(),
      expiredAt: expiredAt.utc().toISOString(),
      startsAtCreation: form.startsAtCreation,
    };

    const operationResponse = await operationManagerService.updateOperation(
      props.operation?.uid,
      updateOperation,
    );
    isResultLoading.value = false;

    if (LxcError.check(operationResponse)) {
      showNotificationError(t(NotificationKey.error));
    } else {
      showNotificationSuccess(t(NotificationKey.success));
      emit("change");
      onClose();
    }
  }
}

const onSubmit = async (): Promise<boolean> => {
  const dataValid = await validate();

  if (dataValid) {
    if (isEditionMode.value) {
      await onConfirmModifyUpdateFirmware();
    } else {
      await onConfirmCreateUpdateFirmware();
    }
  }

  return dataValid;
};

initializeDefaultModelsFilters();
onSearchModels(fetchModelsData);

watch(
  () => props.dtwin,
  () => {
    searchModelsPending = true;
  },
);

watch(
  () => props.show,
  async (isShown) => {
    if (isShown) {
      minDate.value = new Date();

      if (searchModelsPending) {
        searchModelsPending = false;
        searchModels();
      }
      await getOperation();
      await checkOperation();
    }
  },
);

watch(
  () => selectedFirmware.value,
  (firmware?: FirmwareI | null) => {
    form.firmwareUid = firmware?.uuid;
  },
);
</script>

<template>
  <lxc-common-modal :show="show" class="!w-[50%] !max-w-4xl" @close="onClose">
    <template #header>
      <h3>{{ t("operation.firmware.update.label") }}</h3>
    </template>

    <template #body>
      <lxc-container
        :is-loading="isLoading || isFirmwareLoading || isOperationLoading"
        :error="error ?? modelsError ?? loadingFirmwareError"
      >
        <lxc-alert type="warning">
          <span class="break-normal">
            {{ t("operation.firmware.update.warningMessage") }}
          </span>
        </lxc-alert>

        <lxc-alert
          v-if="
            isScheduledOrRunningOperations &&
            !isCheckOperationLoading &&
            !checkOperationError
          "
          type="warning"
        >
          <span class="break-normal">
            {{ t("operation.firmware.update.scheduled.warningMessage") }}
          </span>
        </lxc-alert>

        <p class="text-base">
          {{ t("operation.firmware.update.dtwin.description") }}
        </p>

        <lxc-form
          ref="operationFormRef"
          :rules="rules"
          :model="form"
          @submit.prevent="onSubmit"
        >
          <lxc-form-item
            v-if="show"
            :label="t('operation.firmware.update.dtwin.launchPlannedAt')"
            prop="launchPlannedAt"
          >
            <lxc-input
              v-model="form.launchPlannedAt"
              type="datetime"
              :date-format="t('dateFormat.date')"
              :min-date="minDate"
              class="!w-44"
            >
              <template #prefix>
                <span class="text-gray-700">
                  <ILxcLightCalendar width="1.25rem" height="1.25rem" />
                </span>
              </template>
            </lxc-input>
          </lxc-form-item>

          <lxc-form-item
            v-if="hasInternalScheduler"
            :label="t('operation.firmware.update.dtwin.startsAtCreation')"
            prop="startsAtCreation"
          >
            <lxc-toggle
              v-model="form.launchPlannedAt"
              name="dtwinUpdateFirmwareModalLaunchPlannedAt"
              :value="true"
            />
          </lxc-form-item>
          <lxc-form-item prop="firmwareUid">
            <lxc-label>{{
              t("operation.firmware.update.firmwareLabel")
            }}</lxc-label>
            <lxc-information class="!mt-0 !mb-4 !pl-0">
              <template #component>
                <lxc-info-sign>
                  <ILxcInfo
                    width="1.25rem"
                    height="1.25rem"
                    view-box="0 0 1.25rem 1.25rem"
                  />
                </lxc-info-sign>
              </template>
              <template #body>
                {{ t("operation.firmware.update.dtwin.firmware") }}
              </template>
            </lxc-information>

            <lxc-firmwares-list
              v-model:selected-firmware="selectedFirmware"
              no-action
              selectable
              :columns="[
                DisplayableColumns.NAME,
                DisplayableColumns.VERSION,
                DisplayableColumns.RANGE,
                DisplayableColumns.DECLINATION,
              ]"
              :default-filters="
                new Map<Filters, any>([
                  [Filters.STATUS, FirmwareStatus.ACTIVATED],
                  [Filters.RANGE, [DTWIN_DEVICE_TYPE.LPP4]],
                  [
                    Filters.FIRMWARE_VERSIONS,
                    dtwin?.features.firmware?.reported?.firmwareVersion
                      ? `*${dtwin.features.firmware.reported.firmwareVersion}*`
                      : '',
                  ],
                  [
                    Filters.HARDWARE_VERSIONS,
                    dtwin?.attributes?.hardwareVersion
                      ? `*${dtwin.attributes.hardwareVersion}*`
                      : '',
                  ],
                ])
              "
              :range-options="rangeOptions"
              :search-mode="SearchMode.FILTER_SEARCH"
              :status-options="statusOptions"
            />
          </lxc-form-item>
        </lxc-form>
      </lxc-container>
    </template>

    <template #footer>
      <div class="flex grow shrink-0 justify-end gap-4">
        <lxc-button
          type="secondary"
          html-type="button"
          :title="t('button.cancel')"
          @click="onClose"
        >
          {{ t("button.cancel") }}
        </lxc-button>

        <lxc-button
          type="primary"
          html-type="button"
          :disabled="isValidateDisabled"
          :icon="isResultLoading ? ILxcLightRotateCw : undefined"
          :title="t('button.confirm')"
          @click="onSubmit"
        >
          {{ t("button.confirm") }}
        </lxc-button>
      </div>
    </template>
  </lxc-common-modal>
</template>
