<script setup lang="ts">
import type {
  NewUserDataI,
  UserDataI,
  UserProfileI,
} from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import userProfilesService from "~/services/userProfiles.service";
import usersService from "~/services/users.service";
import { useConfigStore } from "~/stores/useConfigStore";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  userId: string | null;
  user: UserDataI | NewUserDataI | null;
}>();
const emit = defineEmits(["previous", "submit"]);

const { t } = useI18n();
const { isModeAD } = storeToRefs(useConfigStore());

const formRef = ref();
const isLoading = ref(false);
const userProfiles = ref<UserProfileI[]>([]);
let initialUserProfileCode = props.user?.userProfiles?.[0]?.code;
const userProfileForm = reactive({
  code: initialUserProfileCode,
});
const formValidationRules = reactive({
  code: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
  ],
});

onMounted(async () => await fetchUserProfiles());

/**
 * Retrieve user profiles, filtered if needed
 * @param query
 */
async function fetchUserProfiles(query?: string) {
  isLoading.value = true;
  const response = await userProfilesService.getUserProfiles(
    undefined,
    undefined,
    undefined,
    query,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.error);
  } else {
    userProfiles.value = response.data;
  }

  isLoading.value = false;
}

/**
 * Update user profile
 */
async function onUpdateUserProfile() {
  const isFormValid = await formRef.value.validate().catch(() => false);
  const userProfile: UserProfileI | undefined = (userProfiles.value ?? []).find(
    (profile) => profile.code === userProfileForm.code,
  );

  if (isFormValid && userProfile) {
    const removeResult = initialUserProfileCode
      ? await usersService.removeUserProfiles(props.userId!, [
          initialUserProfileCode,
        ])
      : null;
    const addResult = await usersService.addUserProfiles(props.userId!, [
      userProfile.code!,
    ]);
    const hasError = [removeResult, addResult].some((result) =>
      LxcError.check(result),
    );

    if (hasError) {
      showNotificationError(t(NotificationKey.saveError));
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      initialUserProfileCode = userProfile.code!;
      emit("submit");
    }
  }
}
</script>

<template>
  <el-form
    ref="formRef"
    :model="userProfileForm"
    :rules="formValidationRules"
    label-position="left"
    label-width="200px"
  >
    <el-form-item
      :label="t(isModeAD ? 'input.profile' : 'input.selectUserProfile')"
      prop="code"
    >
      <el-select
        v-model="userProfileForm.code"
        class="profile-select"
        filterable
        :placeholder="t('input.selectUserProfile')"
        :remote-method="fetchUserProfiles"
        :loading="isLoading"
      >
        <el-option
          v-for="item in userProfiles"
          :key="item.id"
          :label="item.label"
          :value="item.code"
        />
      </el-select>
    </el-form-item>

    <el-row v-if="!isModeAD" justify="space-between" class="button-wrapper">
      <el-button plain @click="$emit('previous')">
        {{ t("button.previous") }}
      </el-button>
      <el-button type="primary" @click="onUpdateUserProfile">
        {{ t("button.next") }}
      </el-button>
    </el-row>
  </el-form>
</template>

<style lang="scss" scoped>
.profile-select {
  width: 100%;
}
</style>
