<script lang="ts" setup>
import type { UserProfileI } from "@lxc/app-device-types";
import { useToggle } from "~/composables/useToggle";
import userProfilesService from "~/services/userProfiles.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{ userProfile: UserProfileI }>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const [removeModalVisible, toggleRemoveModalVisible] = useToggle();

const showConfirm = computed(() => props.userProfile.nbUsers === 0);
const modalMessagesKey = computed(() =>
  showConfirm.value ? "confirmDelete" : "cannotDelete",
);
const title = computed(() => t(`profile.${modalMessagesKey.value}.title`));
const description = computed(() =>
  t(`profile.${modalMessagesKey.value}.description`, {
    userProfileName: props.userProfile.label,
    usersCount: props.userProfile.nbUsers,
  }),
);

/**
 * When removal confirmation of the user profile
 * @param profileCode
 */
async function onRemoveUserProfile(profileCode: string) {
  const removeResponse =
    await userProfilesService.deleteUserProfile(profileCode);

  if (LxcError.check(removeResponse)) {
    removeResponse.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    toggleRemoveModalVisible();
    emit("change");
  }
}

const onDeleteProfileClick = () => {
  toggleRemoveModalVisible(true);
};
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :icon="ILxcLightTrash2"
    :title="t('profile.button.delete')"
    class="invisible"
    @click.stop.prevent="onDeleteProfileClick"
  />

  <lxc-confirm-modal
    :show-confirm="showConfirm"
    :is-dialog-visible="removeModalVisible"
    :title="title"
    :description="description"
    @cancel="toggleRemoveModalVisible"
    @confirm="onRemoveUserProfile(userProfile?.code)"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
