import RestService from "./rest.service";
import type { PkiConnectorI, PkiUsage } from "@lxc/app-device-types";

class PkiConnectorService extends RestService {
  private BASE_URL = "/pki-connector";

  /**
   * @param usage
   */
  async fetchByUsage(usage: PkiUsage): Promise<PkiConnectorI> {
    return this.$api.get(`${this.BASE_URL}/${usage.toString()}`);
  }

  /**
   * @param usage
   * @param pkiConfiguration
   */
  async updateByUsage(
    usage: string,
    pkiConfiguration: PkiConnectorI,
  ): Promise<PkiConnectorI> {
    this.verifyPkiConfiguration(pkiConfiguration);
    return this.$api.patch(`${this.BASE_URL}/${usage.toString()}`, {
      pkiConfiguration,
    });
  }

  /**
   * @param pkiConfiguration
   * @private
   */
  private verifyPkiConfiguration(pkiConfiguration: PkiConnectorI) {
    const HTTPS_PREFIX = "https://";
    if (
      pkiConfiguration.url &&
      !pkiConfiguration.url.startsWith(HTTPS_PREFIX)
    ) {
      pkiConfiguration.url = HTTPS_PREFIX.concat(pkiConfiguration.url);
    }
    if (!pkiConfiguration.bootstrapTemplateName) {
      pkiConfiguration.bootstrapIntervalTime = undefined;
    }
  }
}

export default new PkiConnectorService();
