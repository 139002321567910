export function useElTable() {
  function getCellStyle(object: any) {
    return {
      minWidth: `${object.column.realWidth}px`,
      maxWidth: `${object.column.realWidth}px`,
    };
  }

  return {
    getCellStyle,
  };
}
