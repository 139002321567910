import type { UserSessionI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import type { RouteLocation } from "vue-router";
import UsersService from "~/services/users.service";
import LxcError from "~/utils/LxcError";
import { getSessionFromUrl, login, logout } from "~/utils/auth.utils";

export const useUserSession = defineStore("userSession", () => {
  const userSession = ref<UserSessionI | null>(null);
  const isAuthenticated = ref<boolean>(false);

  /**
   * Apply retrieve current user to store
   * @param route
   */
  async function setCurrentUser(route: RouteLocation): Promise<void> {
    userSession.value = await fetchUserInSession(route);
    isAuthenticated.value = !!userSession.value?.id;
  }

  /**
   * Retrieve user if in session, apply session from url if needed
   * @param route
   */
  async function fetchUserInSession(route: RouteLocation): Promise<any> {
    const response = await UsersService.getCurrentUser();

    if (LxcError.check(response)) {
      const sessionResponse = await getSessionFromUrl(route);

      if (sessionResponse === null) {
        return await login();
      } else if (LxcError.check(sessionResponse)) {
        return await logout();
      } else {
        return await fetchUserInSession(route);
      }
    } else {
      return response;
    }
  }

  /**
   * Check if user is authenticated
   */
  function isAffectedToSectorCode(sectorCode: string): boolean {
    return !!userSession.value?.sectors
      .map((sector) => sector.code)
      .includes(`${sectorCode}`);
  }

  return {
    userSession,
    setCurrentUser,
    isAuthenticated,
    isAffectedToSectorCode,
  };
});
