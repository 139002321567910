export enum displayColumns {
  FRIENDLY_NAME,
  TYPE,
  SERIAL_NUMBER,
  SECTOR,
  CONNECTIVITY,
  METASTATUS,
  STATE,
  CREATED_AT,
  ACTION,
}

export enum dtwinsListDisplayContext {
  DTWINS,
  FLEET,
}
