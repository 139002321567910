<script setup lang="ts">
import { ObjectCampaignState, ObjectCampaignType } from "@lxc/app-device-types";
import type { CampaignType, ObjectCampaignI } from "@lxc/app-device-types";
import dayjs from "dayjs";
import type { RouteLocationRaw } from "vue-router";
import { useCampaignProcessedObjects } from "~/composables/useCampaignProcessedObjects";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";

const props = defineProps<{
  campaignPlannedStartAt?: string | undefined;
  campaignId: number | undefined;
  defaultFilters?: Map<Filters, any>;
  currentCampaignType?: CampaignType | undefined;
}>();

const { t } = useI18n();
const router = useRouter();
const {
  isLoading,
  campaignProcessedObjects,
  error,
  filters,
  fetchData,
  onSearch,
  search,
  setFilter,
} = useCampaignProcessedObjects(props.campaignId?.toString() ?? "");

const onSearchInputChanged = (newValue: string) => {
  setFilter(Filters.NAME, newValue);
};

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

const formatObjectDate: any = (date: string, format: string) =>
  date ? dayjs(date).format(format) : "";

function getCellObjectClass(object: ObjectCampaignI): Array<string> {
  const cellObjectClass: Array<string> = [];

  if (
    object.state &&
    [
      ObjectCampaignState.WITHDRAWN,
      ObjectCampaignState.DEACTIVATED,
      ObjectCampaignState.DELETED,
    ].includes(object.state)
  ) {
    cellObjectClass.push("opacity-30");
  }

  return cellObjectClass;
}

function getObjectPath(object: ObjectCampaignI): RouteLocationRaw {
  switch (object.type) {
    case ObjectCampaignType.DVC:
      return `${PATHS.DEVICES_DVTM_ESOFT}/${object.id}`;
    case ObjectCampaignType.APP:
      return `${PATHS.APPLICATIONS}/${object.id}`;
    default:
      return "";
  }
}

const startDate = (object: ObjectCampaignI) =>
  formatObjectDate(
    object.operation?.startedAt ?? props.campaignPlannedStartAt,
    t("campaign.details.equipments.column.startDate.format"),
  );

const lastOperationDate = (object: ObjectCampaignI) =>
  formatObjectDate(
    object.operation?.stateDate
      ? object.operation?.stateDate
      : object.operation?.lastError?.timestamp
        ? object.operation?.lastError?.timestamp
        : undefined,
    t("campaign.details.equipments.column.lastOperationDate.format"),
  );

function clearSearchBar() {
  setFilter(Filters.NAME, "");
  search();
}

onSearch(fetchData);
</script>

<template>
  <div class="flex justify-between align-middle">
    <div class="self-center w-[25rem]">
      <lxc-search-bar
        :search-query="filters.get(Filters.NAME)"
        :search-placeholder="t('filters.searchDevice')"
        @clear="clearSearchBar"
        @input-change="onSearchInputChanged"
        @search="search"
      />
    </div>
    <div class="self-center">
      <lxc-campaign-operations-filters
        :filters="filters"
        :current-campaign-type="currentCampaignType"
        @change="setFilter"
        @enter="search"
      />
    </div>
  </div>
  <div id="appliedFilterTags" />
  <lxc-table
    :data="campaignProcessedObjects?.data"
    :empty-text="t('campaign.details.equipments.empty')"
    :is-loading="isLoading"
    :error="error?.toError()"
    :context="campaignProcessedObjects?.context"
    data-cy="campaign-objects-table"
    @change-page-and-page-size="fetchData"
  >
    <lxc-table-column
      prop="label"
      :label="t('campaign.details.equipments.column.label.label')"
    >
      <template #default="scope">
        <span :class="getCellObjectClass(scope.row)">
          <a href="" @click.prevent="router.push(getObjectPath(scope.row))">
            {{ scope.row.label }}
          </a>
          <span v-if="scope.row.state !== ObjectCampaignState.SCOPED">
            ({{ t(`campaign.details.equipments.states.${scope.row.state}`) }})
          </span>
        </span>
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="type"
      :label="t('campaign.details.equipments.column.deviceType.label')"
    >
      <template #default="scope">
        <span>
          {{ scope.row.deviceType }}
        </span>
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="message"
      :label="t('campaign.details.equipments.column.message.label')"
    >
      <template #default="scope">
        <span :class="getCellObjectClass(scope.row)">
          <lxc-operation-state :state="scope.row.operation.state" />
          {{ scope.row.operation.lastError?.debugMessage }}
        </span>
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="startDate"
      :label="t('campaign.details.equipments.column.startDate.label')"
    >
      <template #default="scope">
        <span :class="getCellObjectClass(scope.row)">
          {{ startDate(scope.row) }}
        </span>
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="lastOperationDate"
      :label="t('campaign.details.equipments.column.lastOperationDate.label')"
    >
      <template #default="scope">
        <span :class="getCellObjectClass(scope.row)">
          {{ lastOperationDate(scope.row) }}
        </span>
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
