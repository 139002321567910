<script setup lang="ts">
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { TruststoreCertificateI } from "@lxc/app-device-types";
import { watch } from "vue";
import type { LocationQuery } from "vue-router";
import LxcTagForList from "~/components/shared/LxcTagForList.vue";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import { SearchMode } from "~/composables/useSearch";
import { useTruststore } from "~/composables/useTruststore";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import type LxcError from "~/utils/LxcError";
import { formatIsoDate } from "~/utils/date-tools";

const props = defineProps<{
  triggerSearch?: boolean;
  isTagsLoading: boolean;
  selectedCertificate?: TruststoreCertificateI | null;
  tags?: Array<string> | null;
  tagsError?: LxcError | null;
}>();
const emit = defineEmits([
  "update:selectedCertificate",
  "openCertificateDetail",
]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {
  isLoading,
  results,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  onSearch,
  search,
} = useTruststore(SearchMode.URL_SEARCH);
const { canManageCertificates, canViewCertificates } =
  useCertificatesACLRoles();

const searchQuery = ref<string>(
  (filters.get(Filters.TRUSTSTORE_ALIAS) ?? "") as string,
);
const alias = ref<string | undefined | null>();
const tableRef = ref<InstanceType<typeof LxcTable>>();
const currentSelectedCertificate: Ref<
  TruststoreCertificateI | undefined | null
> = ref();
const certificateDetailShown = ref<boolean>(false);
const canEditTruststoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !props.isTagsLoading,
);

/**
 * Retrieve selected certificates
 */

const findCertificate = (
  list: TruststoreCertificateI[],
  paramCertificate?: TruststoreCertificateI | null,
) => {
  return list.find(
    (currentCertificate: TruststoreCertificateI) =>
      currentCertificate.alias === paramCertificate?.alias,
  );
};

const setSelection = () => {
  if (tableRef.value?.data) {
    tableRef.value.setCurrentRow(
      findCertificate(tableRef.value.data, props.selectedCertificate),
    );
  }
};

watch(() => props.selectedCertificate, setSelection);

/**
 * Update the selected certificate or redirect to certificate details page
 */
function onSelectCertificate(certificate: TruststoreCertificateI) {
  alias.value = certificate.alias;

  if (certificate?.alias && canViewCertificates()) {
    const targetUrl: Record<string, string | string[] | LocationQuery> = {
      path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}/${certificate?.alias}`,
      query: route.query,
    };
    router.push(targetUrl);

    certificateDetailShown.value = true;
  }
}

const onCloseCertificateDetail = () => {
  alias.value = null;
  certificateDetailShown.value = false;
  router.push({
    path: PATHS.PARAMETERS_CERTIFICATES,
    query: route.query,
  });
};

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE)) {
    alias.value = route?.params?.uuid as string | undefined;

    if (alias.value) {
      certificateDetailShown.value = true;
    }
  }
};

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue;
  setFilter(Filters.TRUSTSTORE_ALIAS, searchQuery.value);
};

const onClearSearch = () => {
  setFilter(Filters.TRUSTSTORE_ALIAS, "");
  search();
};

/**
 * Retrieve certificates and set selected certificate if necesary
 * @param page
 * @param pageSize
 */
async function loadData(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  await fetchData(page, pageSize);
  setTimeout(setSelection, 0);
}

function reloadWithContext() {
  loadData(results.value?.context?.page, results.value?.context?.pageSize);
}

watch(() => props.triggerSearch, search);
watch(
  () => currentSelectedCertificate.value,
  () => {
    emit("update:selectedCertificate", currentSelectedCertificate.value);
  },
);
watch(
  () => props.selectedCertificate,
  () => {
    currentSelectedCertificate.value = props.selectedCertificate;
  },
);

onMounted(async () => {
  onSearch(loadData);
  initDisplayFromRoute();
});
</script>

<template>
  <lxc-search-bar
    :search-query="searchQuery"
    :search-placeholder="t('certificates.keystore.filters.searchByAlias')"
    class="grow shrink basis-0"
    @clear="onClearSearch"
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-certificates-filters
    :filters="filters"
    :tags="tags"
    :is-tags-loading="isTagsLoading"
    :tag-filter-name="Filters.TRUSTSTORE_TAGS"
    :tags-error="tagsError"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    :data="results?.data"
    :context="results?.context"
    :is-loading="isLoading"
    :error="error?.toError()"
    clickable
    :empty-text="t('certificates.noCertificate')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectCertificate"
    @sort-change="onSortChange"
  >
    <lxc-table-column prop="alias" :label="t('certificates.alias')" />
    <lxc-table-column prop="tags" :label="t('certificates.tag')" class="!py-0">
      <template #default="scope">
        <LxcTagForList :tags="scope.row.tags" :max="3" />
      </template>
    </lxc-table-column>
    <lxc-table-column :label="t('certificates.expiration.timestamp.label')">
      <template #default="scope">
        {{ formatIsoDate(scope.row.expirationDate, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column :width="184" class="!py-0">
      <template #default="scope">
        <div class="flex gap-2">
          <lxc-truststore-list-download-action :certificate="scope.row" />
          <lxc-truststore-list-delete-action
            :certificate="scope.row"
            @change="reloadWithContext"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>

  <lxc-truststore-certificate-detail
    v-model:side-canvas-shown="certificateDetailShown"
    :alias="alias"
    :disabled="!canEditTruststoreCertificate"
    :is-tags-loading="isTagsLoading"
    :tags-error="tagsError"
    :tags="tags"
    @close="onCloseCertificateDetail"
    @save="loadData"
  />
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
